.variable-alias-select {
  width: 100%;
  height: auto;
}

.variable-alias-content {
  width: 20em;
  height: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;

  > .variable-alias-input-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 1em;

    > .label {
      text-align: center;
    }
  }

  > .variable-alias-trigger-wrapper {
    flex: initial;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 1em;

    > .label {
      text-align: center;
    }
  }
}
