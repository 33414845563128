$border-color: #727272;
$background-color: #454545;

$hover-background: rgba(0, 121, 255, 1);

div.shape-color-container {
  div.color-property {
    flex: 1;

    margin-top: 1em;

    > div.input-fields-container {
      display: flex;
      margin-bottom: 1em;

      > span:nth-child(1) {
        flex: 1;
        margin-right: 20%;
      }

      > span:nth-child(2) {
        display: inline-block;
        flex: initial;
        width: 4.5em;

        border-radius: 3px;
        background: white;
      }
    }

    > div.color-picker-container {
      position: relative;
      flex: 1;

      > div.sketch-picker {
        // those !important attributes are neccessary for both since those
        // fields are set as its inline style in react-color.
        width: 100% !important;
        box-sizing: border-box !important;
        background: #444444 !important;
        border: 1px solid $border-color;

        > div:nth-child(1) {
          border: 1px solid $border-color;
        }

        > div:nth-child(2) {
          > div:nth-child(1) {
            > div {
              border: 1px solid $border-color;
            }
            > div:nth-child(2) {
              background: white !important;
            }
          }

          > div:nth-child(2) {
            border: 1px solid $border-color;
          }
        }

        > div:nth-child(3) {
          > div {
            > div {
              > input {
                width: 100% !important;
                text-align: center;
                border-radius: 2px;
              }

              > span {
                color: white !important;
              }
            }
          }
        }
      }
    }

    > div.color-picker-container.hide {
      display: none;
    }
  }

  > div.opacity-property {
    margin-top: 2em;

    > .label {
      color: white;
      margin-left: 5px;
    }
  }
}
