$selectionColor: #32c5ff;
$border: 1px solid #d5cac9;
$selectedCardBorder: 3px solid $selectionColor;
$cardPadding: 1em;
$cardShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

.rectangle {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  font-weight: 500;
  padding-left: 3px;
}

.buttonSelected {
  border: 3px solid $selectionColor !important;
  color: $selectionColor !important;
}
