$border: 1px solid #d5cac9;
$border-hover: 2px solid #63cdf5;

$tag-background: #f47920;

// TO DO: template card css needes to be fixed to fit new virtual grid
div.template-card {
  &:hover {
    border: $border-hover;
    z-index: 2000;
  }

  width: 100%;
  border: $border;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;

  > div.setting-button-container {
    position: absolute;
    top: 1.75em;
    right: 3.25em;

    > .anticon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      font-size: 1.25em;
    }
  }

  > div.template-content-container {
    padding: 0.1em;
    position: relative;
    width: inherit;

    > div.image-container {
      width: 100%;
      height: 18em;
      position: relative;
      overflow: hidden;

      > div.draft-label {
        position: absolute;
        top: 0;
        right: 0;

        width: 4em;
        height: 4em;
        background: yellow;

        vertical-align: text-bottom;

        > span.text {
          font-size: 0.7rem;
          font-weight: 500;
          position: absolute;

          bottom: 0;
          left: 50%;

          transform: translate(-50%, 0);
        }

        transform: translate(50%, -50%) rotate(45deg) scale(0.85);
      }

      > img {
        width: 100%;
        height: 100%;
        padding: 2em;
        object-fit: contain;
      }

      > div.template-overlay {
        position: absolute;
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;

        background: rgba(220, 220, 220, 0.8);

        > div.button-container {
          display: flex;
          flex-direction: column;

          position: relative;
          width: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          > button {
            background: #212121;
            color: white;
            margin: 0.5em;
          }
        }
      }
    }

    > div.template-name {
      display: flex;
      flex-direction: row;

      align-items: center;
      padding: 0 3em 0.5em 3em;

      > .name {
        flex: 1;
        color: black;
        font-weight: 500;
      }

      > .stamp-counter-wrapper {
        flex: initial;
        margin-left: auto;

        > .stamp-counter {
          min-width: 4em;

          padding: 1px;

          border: 1px solid black;
          border-radius: 15px;

          display: flex;
          flex-direction: row;

          align-items: center;

          > i,
          span {
            color: black;
            flex: 1;
          }

          > span {
            display: inline-block;
            text-align: center;
          }
        }
      }
    }

    > div.tags-container {
      margin-top: 0.5em;
      > div.tag-select-with-label {
        > div.ant-select {
          width: 100%;
        }
      }

      > div.tags {
        margin-top: 0.5em;

        display: flex;
        flex-wrap: wrap;

        > span.oem-tag {
          background: #444444;
        }
        > span {
          background: $tag-background;
          color: white;

          > .anticon {
            color: white;
          }
        }
      }
    }
  }
}

ul.template-setting-menu-container {
  list-style: none;
  padding: 0;
}

.ant-select-dropdown {
  background: white;
  .ant-empty-description {
    color: rgba(0, 0, 0, 0.25);
  }
}

div.tags-container-templates {
  margin-top: 0.5em;
  padding: 0 1.5em 0.5em 1.5em;

  background-color: #ffffff;
  > div.tag-select-with-label {
    > div.ant-select {
      width: 100%;
    }
  }

  > div.tags {
    margin-top: 0.5em;

    display: flex;
    flex-wrap: wrap;

    > span.oem-tag {
      background: #444444;
    }
    > span {
      color: black;

      > .anticon {
        color: white;
      }
    }
  }
}

div.tags-container-templates:hover {
  cursor: pointer;
}
