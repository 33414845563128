.panel {
  background-color: #fafafa;

  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .delimiter {
    width: 20%;
  }
}
