.shareSessionModal :global {
  .ant-modal-header {
    background-color: #fff;

    .ant-modal-title {
      font-weight: bold;
      color: #000;
    }
  }

  .ant-modal-close-x {
    color: rgba(0, 0, 0, 0.45);
  }
}
.shareSessionModal {
  .input {
    margin-top: 1rem;
  }
}
