.container {
  padding-bottom: 16px;
  font-size: 12px;
}

.lineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  color: rgba(0, 0, 0, 0.45);
  margin: 0px;
}

.button {
  padding: 0;
  font-size: 12px;
}
