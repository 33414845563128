.bodyContainer {
  height: 100%;

  > * {
    height: 100%;
  }
}

.borderLine {
  border-right: 1px solid #f0f0f0;
}

.stepsContainer {
  padding-left: 20px;
  width: 100%;
  height: 100%;
}

.drawerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.batchNameTitle {
  width: 160px;
}

.batchName {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  border: none;
  width: 100%;
}

.drawerFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
