@font-face {
  font-family: "HelveticaNeueLTStd-BdCn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-BdCn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-BdCn.woff")
    format("woff");
}
@font-face {
  font-family: "HelveticaNeueLTStd-BlkCn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-BlkCn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-BlkCn.woff")
    format("woff");
}
@font-face {
  font-family: "HelveticaNeueLTStd-Cn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-Cn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-Cn.woff")
    format("woff");
}
@font-face {
  font-family: "HelveticaNeueLTStd-HvCn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-HvCn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-HvCn.woff")
    format("woff");
}
@font-face {
  font-family: "HelveticaNeueLTStd-MdCn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-MdCn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-MdCn.woff")
    format("woff");
}
@font-face {
  font-family: "HelveticaNeueLTStd-XBlkCn";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-XBlkCn.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/HelveticaNeue/HelveticaNeueLTStd-XBlkCn.woff")
    format("woff");
}
