.previewFrame {
  display: flex;
  margin-top: 1.5em;
  justify-content: center;

  iframe {
    background-image: url("../../statics/images/ad_loader.gif");
    background-repeat: no-repeat;
    background-position-x: center;
  }
}
