.formPagesContainer {
  border: 1px solid #d9d9d9;
  .header {
    background-color: #f5f5f5;
    padding: 8px 10px;
    font-weight: bold;
    text-transform: capitalize;
    border-bottom: 1px solid #d9d9d9;
  }

  .formComponentContainer {
    padding: 4px 8px 12px 10px;
    :global {
      .ant-form-item {
        margin-bottom: 0px;
      }
      .ant-form-item-label {
        padding-bottom: 0px;
      }
    }
  }
}
