$mainNav: #0092d0;
$primary: #1890ff;
$primaryHover: #40a9ff;
$secondaryLight: #ffffff;
$secondaryDark: #201e24;
$secondaryDarkHover: #201e2415;
$tertiary: #004466;
$tertiaryHover: #4a4949;
$accordianBackground: #e1ebf1;
$default-text-color: rgba(0, 0, 0, 0.65);

@mixin btn($placement, $secondaryShade...) {
  .action-button {
    @if $placement == "primary" {
      background-color: $primary !important;
      &:hover {
        background-color: $primaryHover !important;
      }
    }

    @if $placement == "secondary" {
      @if ($secondaryShade == "light") {
        background-color: $secondaryLight !important;
        &:hover {
          opacity: 0.75 !important;
        }
      }

      @if ($secondaryShade == "dark") {
        background-color: $secondaryDark !important;
        &:hover {
          background-color: $mainNav !important;
        }
      }
    }

    @if $placement == "tertiary" {
      background-color: $tertiary;
      &:hover {
        background-color: $tertiaryHover !important;
      }
    }
  }

  span {
    > button {
      @if $placement == "primary" {
        background-color: $primary !important;
        &:hover {
          background-color: $primaryHover !important;
        }
      }

      @if $placement == "secondary" {
        @if ($secondaryShade == "light") {
          background-color: $secondaryLight !important;
          &:hover {
            opacity: 0.75 !important;
          }
        }

        @if ($secondaryShade == "dark") {
          background-color: $secondaryDark !important;
          &:hover {
            background-color: $mainNav !important;
          }
        }
      }

      @if $placement == "tertiary" {
        background-color: $tertiary;
        &:hover {
          background-color: $tertiaryHover !important;
        }
      }
    }
  }

  .action-save-button {
    color: white;

    &:hover {
      color: $primary;
    }
  }
}
