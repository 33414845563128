.homepage {
  background-color: white;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 60px;
  width: 1150;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
}
