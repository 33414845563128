.infoIcon {
  color: rgba(9, 109, 217, 1);
}

.errorIcon {
  color: red;
}

.toolBar {
  display: flex;
  padding-bottom: 2%;
}

.tableContainer {
  overflow-x: auto;
}

.errorContainer {
  border-bottom: 1px solid lightgray;
  margin-bottom: 1%;
}

.generalError {
  display: flex;
  justify-content: space-between;
}
