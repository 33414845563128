.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.colName {
  width: 100%;
  text-align: left;
}

.disabledRow {
  td {
    background: #f5f5f5;
    cursor: not-allowed;
    color: #a3a3a3;
  }
}
