.host {
  display: flex;
  align-items: center;
  gap: 1em;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5em;
}
