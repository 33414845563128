@font-face {
  font-family: "AudiTypeV03-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-ExtendedBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedBold.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-ExtendedBoldIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedBoldIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedBoldIta.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-ExtendedItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedItalic.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-ExtendedNormal";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedNormal.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-ExtendedNormal.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "AudiTypeV03-Normal";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Normal.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Audi/AudiTypeV03-Normal.woff")
    format("woff");
}
