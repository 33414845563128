/* Hide scrollbar for Chrome, Safari and Opera */
.scrollableArea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollableArea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollableArea {
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
}

.livePreviewContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.backIcon {
  position: absolute;
  top: 6px;
  left: 6px;
  color: #d6d7da;
  font-size: 24px;
  z-index: 10;
}
