.adReviewContainer :global {
  .ant-checkbox-wrapper {
    padding: 0;
  }
}

.adTableColumnModal :global {
  .ant-modal-body {
    padding: 1em;
    .ant-checkbox-group {
      .ant-checkbox-wrapper {
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    .ant-divider {
      align-self: center;
      height: 20em;
    }
  }
}

.childRowSpan {
  display: block;
  padding: 1rem 3em;
}
