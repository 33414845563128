@font-face {
  font-family: "ArialCE";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCE.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCE.woff")
    format("woff");
}
@font-face {
  font-family: "ArialCEBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEBold.woff")
    format("woff");
}
@font-face {
  font-family: "ArialCEBoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEBoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "ArialCEItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Arial/ArialCEItalic.woff")
    format("woff");
}
