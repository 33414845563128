.host {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;

  > div {
    gap: 0.5em;

    &:first-child {
      display: flex;
    }

    &:last-child {
      flex: 1 1 50em;
      display: grid;
      grid-template-columns: 1fr auto auto;
    }
  }
}
