.container {
  padding-top: 1em;
  width: 100%;

  :global {
    span.ant-input-group-wrapper.ant-input-search.search-toolbar-input {
      margin-left: 8px;
    }
  }
}
