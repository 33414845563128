.placeholder {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    margin-bottom: 5px;
  }
  .plusIcon {
    font-size: 20px !important;
    color: black !important;
  }
}

.placementSizesContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 120px;
  .infoIcon {
    margin-left: 10px;
    color: #00000080;
  }
}
