.optionLinkWrapper {
  padding: 0;

  > div:not(:has(a)),
  a {
    padding: 5px 12px 5px 24px;
  }

  a {
    text-decoration: "none";
    color: rgba(0, 0, 0, 0.85);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  small {
    opacity: 0.5;
  }
}
