.htmlIframe {
  border: 0;
  height: 80vh;
}

.previewContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  overflow: auto;

  .templateNav {
    flex: initial;
  }
  .preview {
    flex: 1;
    height: 100%;

    padding: 0 1em 1em 1em;

    > .iframeContainer {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
