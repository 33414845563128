@font-face {
  font-family: "Futura-Condensed-Bold-Oblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura-Condensed-Bold-Oblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura-Condensed-Bold-Oblique.woff")
    format("woff");
}
@font-face {
  font-family: "Futura-Condensed-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura-Condensed-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura-Condensed-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Futura";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/Futura.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaBold.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaExtended";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaExtended.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaExtended.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaStd-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-Light.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaStd-LightOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-LightOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-LightOblique.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaStd-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FuturaStd-MediumOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-MediumOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Futura/FuturaStd-MediumOblique.woff")
    format("woff");
}
