.bodyRight {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 1em 1em 0em 1em;
  overflow-y: auto;
  height: 100%;
}

.bodyLeft {
  margin-left: -20px;
  padding-right: 20px;
  padding-left: 20px;
}

.asterisk {
  color: red;
  padding-right: 5px;
  vertical-align: sub;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  .reset {
    margin-right: 10px;
  }
}

.bullet {
  margin-right: 5px;
  margin-left: 5px;
}

.howToUse {
  margin-top: 10px;
  margin-bottom: 10px;
  color: grey;
}

.tips {
  margin-top: 10px;
  margin-bottom: 10px;
  color: grey;
}

.assetName {
  background-color: aqua;
}
