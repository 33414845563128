.carousel {
  position: relative;

  width: 100%;
  height: auto;

  > .imageWrapper {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .innerWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
