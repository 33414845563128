.collapseHeader {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  height: 2em;
  padding-bottom: 7px;

  > .ieLibraryLink {
    display: flex;
    align-items: center;

    svg {
      width: 1em;
      height: 1em;
      margin-right: 10px;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
