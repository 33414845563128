.FeedSelect {
  width: 200;
  min-height: 100;
  max-height: 500;
  overflow-x: hidden;
  overflow-y: auto;
}

.AddFeedButton {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
