.footerDiv {
  height: 100%;
  display: flex;
  align-items: center;
}

.leftDiv {
  float: left;
  width: 52%;
  & > span,
  input,
  button {
    margin-right: 8px;
    &.sessionNameInput {
      height: 24px;
    }
  }
}

.rightDiv {
  float: right;
  @media only screen and (max-width: 808px) {
    & {
      float: initial;
    }
  }
}

.selectStepRightDiv {
  @media only screen and (max-width: 808px) {
    & {
      width: 45%;
    }
  }
}
