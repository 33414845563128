.component {
  cursor: pointer;
  background-color: #262626;
  color: white;
  padding: 8px 15px 8px 8px;
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 320px;

  .icons {
    color: #595959;
  }
  .editIcon {
    color: transparent;
  }

  &:hover {
    color: white;
    background-color: #595959;
    .icons,
    .editIcon {
      color: white;
    }
  }

  .nameBase {
    margin: 0;
    width: 190px;
    padding: 4px 11px;
    height: 35px;
  }

  .name {
    @extend .nameBase;
    color: white;
    border: 1px solid transparent;
  }
  .editableName:hover {
    background-color: #454545;
  }
  .nameInput {
    @extend .nameBase;
    background-color: #454545;
    font-size: 16px;
    color: white;
    border: 1px solid #1890ff !important;
  }
  .editingName {
    padding: 0;
    left: 0;
    border: none;
  }
}

.selected {
  background-color: #1890ff;
  .icons {
    color: white;
  }
}
