.TextArea {
  position: relative;
  color: inherit;

  > * {
    color: inherit;
  }

  > .RevertButtonWrapper {
    position: absolute;

    // below is the antd padding within TextArea
    right: 11px;
    bottom: 4px;
  }
}
