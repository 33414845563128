.container {
    align-items: flex-start;

    &.settings {
      margin-top: 0px;
      margin-bottom: 24px;
    }

    &.components {
      margin-bottom: 16px;
    }

    svg {
      margin-top: 4px;
    }
  }