@font-face {
  font-family: "CadillacSansA-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Book.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-BookItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-BookItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-BookItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-ExtraBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ExtraBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ExtraBold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-ExtraBoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ExtraBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ExtraBoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Light.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-SemiBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-SemiBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-SemiBoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-SemiBoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSansA-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSansA-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-Heavy";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Heavy.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Heavy.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-HeavyItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-HeavyItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-HeavyItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-SemiBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacSerif-SemiBoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.woff")
    format("woff");
}

@font-face {
  font-family: "CadillacGothic-WideMedium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-WideMedium.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacGothic-WideBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-WideBold.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacGothic-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacGothic-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "CadillacGothic-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "CadillacGothic-WideRegular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacSerif-SemiBoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Cadillac/CadillacGothic-WideRegular.woff")
    format("woff");
}
