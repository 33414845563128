div.drop-zone-shape-background {
  width: 100%;
  height: 100%;

  margin: 0 auto;

  position: relative;

  div.top-left {
    width: 30%;
    height: 30%;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 5;
  }

  div.top-right {
    width: 30%;
    height: 30%;

    position: absolute;
    top: 0;
    right: 0;

    z-index: 5;
  }

  div.bottom-right {
    width: 30%;
    height: 30%;

    position: absolute;
    bottom: 0;
    right: 0;

    z-index: 5;
  }

  div.bottom-left {
    width: 30%;
    height: 30%;

    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 5;
  }

  div.center {
    width: 90%;
    height: 90%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: #6c6d6e;

    z-index: 10;
  }
}
