.layerName {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 2em;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;

  .field {
    width: 100%;
    padding: 15px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .condition {
      width: 100%;

      display: flex;
      gap: 0.5em;
      align-items: flex-start;

      .formItem {
        margin-bottom: 0;
      }

      .warningFormItem :global {
        .ant-select-disabled.ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          border-color: #faad14;
        }
      }

      > *:nth-child(1) {
        flex: initial;
        width: 4em;
      }

      > * {
        flex: 2;
      }

      > .op {
        flex: 1;
      }

      .actions {
        flex: initial;

        width: 4.5em;
        display: flex;
        justify-content: flex-start;
        > * {
          flex: initial;
          width: 2em;
        }
      }
    }

    .show {
      display: flex;
      gap: 0.5em;
      align-items: center;
      > *:nth-child(1) {
        flex: initial;
        width: 4em;
      }

      .thenInput {
        display: flex;
        flex: 1;
        align-items: center;
        margin-bottom: 0;
      }
    }
  }

  .else {
    > *:nth-child(1) {
      flex: initial;
      width: 3em;
    }
    display: flex;
    gap: 0.5em;
    align-items: center;
  }

  span svg {
    color: #00000073;
  }
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7rem;
  margin-top: 4px;
}

.addCondition {
  width: 11em;
  margin: 1em 0;

  span svg {
    color: var(--primary-color, #00a7e1);
  }
}
