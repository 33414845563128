.container {
  padding: 0 1em;
}

.pmaxStatus {
  display: flex;
  gap: 5px;

  > *:nth-child(2) {
    margin: 0 15px;
    color: #abb9bf;
  }
}

.notLoadable {
  background: #f4f4f4;

  > *:nth-child(2) {
    color: #b7b7b7;
  }
}
