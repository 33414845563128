.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin: 8px 0 24px 0;
}

.button {
  font-size: 14px;
  font-weight: 400;
}
