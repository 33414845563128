// This is commented on purpose.
// PLZ do not use global CSS, specially for hacking Ant Design.
// It's ok to hack the UI lib if you need to change minor stuff,
// but do it on a CSS Module, or inline to avoid styles leaking.

// .ant-checkbox-wrapper {
//   padding-bottom: 1em;
// }

// .ant-radio-wrapper {
//   padding-bottom: 1em;
// }

// // Required to restore original vertical alignment
// // of checkbox and radio button inside tables defined above.
// .ant-table-tbody {
//   .ant-checkbox-wrapper,
//   .ant-radio-wrapper {
//     padding-bottom: initial;
//   }
// }

.line {
  border-bottom: 1px solid#cccccc;
}

div.review-export-modal {
  .ant-col {
    border: 1px solid #cccccc;
    padding: 1em;
    margin-left: 1em;
  }
}
div.review-export-result-modal {
  .ant-col {
    border: 1px solid #cccccc;
    padding: 1em;
  }
}
