$hover-background: rgba(0, 121, 255, 1);

div.drop-zone-container {
  width: 100%;

  div.type-select-container {
    width: 100%;

    margin-bottom: 1em;

    div.ant-select {
      width: 100%;
    }
  }

  div.shape-container {
    width: 100%;
    height: 25em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div.shape {
      flex: 1;
      width: 100%;
    }
  }

  div.car-cut-button-container {
    width: 100%;
    text-align: center;
    margin: 1em 0;

    > button {
      width: 80%;
      background-color: #181818;
      color: white;
    }
  }
}

div.ant-select-dropdown {
  ul.ant-select-dropdown-menu {
    > li {
      color: white;
    }

    > li.ant-select-dropdown-menu-item-selected,
    > li:hover {
      background: $hover-background;
    }
  }
}
