@import "./variables/ladtech.scss";

/*
  ======================[ GENERAL ] ===========================
  - LADTech is missing some styling on this page because most of the styling is already implemented in separate styling files.
  - Most Theme changes are going to be happening here, because this is going to grab a board color palette across the application.
  ie: 
    Headers
    Header Buttons
    Panels
    Menu
    Pullout Tabs
*/

//HEADER MAIN BUTTON
.action-buttons-container {
  @include btn("primary");
}

//PANELS
.collapse-container {
  .ant-collapse-header {
    background-color: $tertiary;
  }
}

.drawer-cancel-button:hover {
  border-color: $mainNav !important;
  color: $mainNav !important;
  opacity: 0.75 !important;
}

/*
   ======================[ ADMIN ] ===========================
*/
/*
   ======================[ LEGAL LINGO ] ===========================
*/
/*
   ======================[ DESIGN STUDIO ] ===========================
*/
/*
   ======================[ ASSET BUILDER ] ===========================
*/
//ACCORDIAN HEADERS & CONTENTS
.offer-detail-list .ant-collapse-item .ant-collapse-header {
  background-color: $accordianBackground !important;
}

//REVIEW PANEL
.review-container {
  .ant-collapse-header {
    background-color: $tertiary !important;

    > label,
    > .anticon {
      color: $secondaryLight !important;
    }
  }
}

div.ad-load-modal-content {
  .ad-load-modal-collapse {
    .ant-collapse-header {
      color: rgba(0, 0, 0, 0.85) !important;

      .anticon.anticon-right.ant-collapse-arrow {
        color: rgba(0, 0, 0, 0.85) !important;
      }
    }
  }
}
