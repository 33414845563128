$header-background-color: #4d4c4c;
.previewIframe {
  width: 100%;
  height: 100%;
  border: none;
  margin: auto;
  padding: 3em;
}

.previewModal {
  height: 80%;
  display: flex;
  justify-content: center;
  .title {
    color: white;
  }
  :global {
    .ant-modal-content {
      height: 100%;

      .ant-modal-header {
        background: $header-background-color;
        border: none;
      }
    }
  }
}

.closeIcon {
  color: white;
}
