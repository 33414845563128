$border: 1px solid #d5cac9;
$tag-background: #f47920;
$border-hover: 2px solid #63cdf5;

div.stamp-card {
  &:hover {
    cursor: pointer;
    border: $border-hover;
    z-index: 2000;
  }
  width: 100%;
  border: $border;
  padding: 0 0.25em 0.5em 0.25em;
  margin-bottom: 0.5em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-x: auto;

  > div.setting-button-container {
    position: relative;
    height: 2em;

    > .anticon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }
  }

  > div.stamp-content-container {
    padding: 0 2em 2em 2em;

    > div.image-container {
      position: relative;

      width: 100%;
      min-height: 10em;

      display: flex;
      align-items: center;

      margin: 0.5em 0;

      > img {
        width: 100%;
        object-fit: contain;
        background-color: gray;
      }

      > div.edit-overlay {
        position: absolute;

        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 100;

        background: rgba(220, 220, 220, 0.8);

        > div.edit-button-container {
          position: relative;

          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > button {
            margin: 0.5em;
            background: #212121;
            color: white;
          }
        }
      }
    }

    > div.stamp-name {
      color: black;
    }

    > div.tags-container {
      margin-top: 0.5em;
      > div.tag-select-with-label {
        > div.ant-select {
          width: 100%;
        }
      }

      > div.tags {
        margin-top: 0.5em;

        display: flex;
        flex-wrap: wrap;

        > span {
          background: $tag-background;
          color: white;

          > .anticon {
            color: white;
          }
        }
      }
    }
  }
}
.ant-popover.offerTypeSelect {
  .ant-popover-inner-content {
    padding: 5px 5px 1px 5px;
  }
}

ul.stamp-setting-menu-container {
  list-style: none;
  padding: 0;
  text-align: center;
  > li:hover {
    background: #e7f3fc;
    font-weight: bold;
    cursor: pointer;
  }
}

.ant-select-dropdown {
  background: white;
  .ant-empty-description {
    color: rgba(0, 0, 0, 0.25);
  }
}

div.tags-container-stamps {
  margin-top: 0.5em;
  > div.tag-select-with-label {
    > div.ant-select {
      width: 100%;
    }
  }

  > div.tags {
    margin-top: 0.5em;

    display: flex;
    flex-wrap: wrap;

    > span {
      color: black;

      > .anticon {
        color: white;
      }
    }
  }
}

div.tags-container-stamps:hover {
  cursor: pointer;
}
