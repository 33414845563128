@font-face {
  font-family: "FordAntenna-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Black.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-ExtraLightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-ExtraLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Light.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-SemiboldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-SemiboldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntenna-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntenna-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Black.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-ExtraLightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-ExtraLightIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Light.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-SemiboldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-SemiboldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaComp-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaComp-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Black.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Black.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-BlackItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-BlackItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Bold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Bold.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-BoldItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-BoldItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-ExtLigIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtLigIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtLigIt.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-ExtLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtLight.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtLight.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-ExtraLightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ExtraLightIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Light.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Light.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-LightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-LightIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-LightIta.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Med";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Med.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Med.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-MedItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-MedItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-MedItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Reg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Reg.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Reg.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-RegItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-RegItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-RegItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Semibold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Semibold.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-SemiboldIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-SemiboldIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-SemiboldIta.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-SemiboldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-SemiboldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCompWGL-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Thin.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-Thin.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCompWGL-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ThinItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCompWGL-ThinItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCond-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Black.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-ExtraLightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-ExtraLightIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Light.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Med";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Med.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-MedItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-MedItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCond-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCond-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Black.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Black.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-BlackItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-BlackItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Bold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Bold.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-BoldItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-BoldItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-ExtLigIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtLigIt.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-ExtLightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtLightIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtLightIta.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtraLight.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtraLight.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-ExtraLightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ExtraLightIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Light.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Light.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-LightIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-LightIt.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-LightItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-LightItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-Med";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Med.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Med.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-MedItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-MedItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-MedItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-Reg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Reg.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Reg.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-RegItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-RegItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-RegItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaCondWGL-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Thin.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-Thin.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaCondWGL-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ThinItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaCondWGL-ThinItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCond-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Black.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-BlackIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-BlackIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-ExLtItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-ExLtItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Light.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-LightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-LightIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Med";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Med.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-MedIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-MedIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-MediumIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-MediumIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Reg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Reg.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-RegIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-RegIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-RegularIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-RegularIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-SemiboldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-SemiboldIt.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-ThinIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-ThinIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCond-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCond-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Black.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Black.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-BlackIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BlackIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BlackIt.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-BlackIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BlackIta.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Bold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Bold.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-BoldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BoldIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BoldIt.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-ExLtItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExLtItalic.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-ExtLig";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExtLig.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExtLig.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-ExtLigIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExtLigIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExtLigIt.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ExtraLight.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-LigIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-LigIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-LigIt.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Light.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Light.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-LightIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-LightIta.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Med";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Med.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Med.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-MedIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-MedIt.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-MedIt.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-MedIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-MedIta.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-MediumIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-MediumIta.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Reg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Reg.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Reg.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-RegIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-RegIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-RegIta.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Regular.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Semibold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Semibold.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-SemiboldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-SemiboldIt.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-SemiboldIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-SemiboldIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-SemiboldIta.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Thin.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-Thin.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaExtraCondWGL-ThinIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ThinIta.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ThinIta.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaExtraCondWGL-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaExtraCondWGL-ThinItalic.woff")
    format("woff");
}

@font-face {
  font-family: "FordAntennaWGL-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Black.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Black.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-BlackItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-BlackItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-BlackItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Bold.woff")
    format("woff");
  // src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Bold.otf")
  //   format("opentype");
}

@font-face {
  font-family: "FordAntennaWGL-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-BoldItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-BoldItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-ExtraLight";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ExtraLight.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ExtraLight.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-ExtraLightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ExtraLightItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ExtraLightItalic.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaWGL-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Light.otf")
    format("opentype");*/
}
@font-face {
  font-family: "FordAntennaWGL-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Light.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Light.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-LightItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-LightItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Medium.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Medium.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-MediumItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-MediumItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-MediumItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Regular.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Regular.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-RegularItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-RegularItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-RegularItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Semibold.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Semibold.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-SemiboldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-SemiboldItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-SemiboldItalic.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Thin.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-Thin.otf")
    format("opentype");*/
}

@font-face {
  font-family: "FordAntennaWGL-ThinItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ThinItalic.woff")
    format("woff");
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Ford/FordAntennaWGL-ThinItalic.otf")
    format("opentype");*/
}
