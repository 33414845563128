.form-item {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 8px;
  margin-bottom: 12px;


  .empty-state-description {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 14px;
      color: black;
    }
  }
}
