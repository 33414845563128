.list {
  padding: 0;
  margin: 0;

  ul,
  li {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  .title {
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  }
}
