.rule-form-footer {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: row;

  button {
    min-width: 7em;
    height: 3em;

    > span {
      font-size: 0.9rem;
    }
  }

  > .left {
    flex: 1;
    width: 15em;

    display: flex;

    > *:last-child {
      margin-right: auto;
    }
  }

  > .right {
    flex: 1;
    margin-left: auto;
  }
}
