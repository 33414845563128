.spin {
  &.spinning {
    svg {
      width: 1em;
      height: 1em;
      animation: spinning 1.6s linear infinite;
      transform-box: fill-box;
      transform-origin: 50% 50%;
    }
  }
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
