.carouselDots {
  li {
    width: 10px !important;
  }
  button {
    height: 10px !important;
    border-radius: 50% !important;
    background-color: #000000cc !important;
    border: 2px solid #ffffffcc !important;
    box-shadow: 0 0 0 2px #000000cc !important;
  }
}

.nextBtn {
  position: absolute;
  transform: translateY(-50%);
  right: 10px;
}

.animatedIcon {
  animation: hBounce 2s infinite ease-in-out;
  color: white;
  font-size: 30px;
}

@keyframes hBounce {
  0%,
  100% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-10px);
  }
}
