.divider {
  margin: 0;
  height: 20px;
  background: #d9d9d9 !important;
}

.host {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 12px;

  .itemCount {
    margin: 0 14px;
  }
  > * {
    margin-right: 0.5em;
  }
}
