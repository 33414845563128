.container {
  padding-top: 1em;
  width: 100%;
  min-height: 70vh;
}

.deleteIcon {
  margin-top: 5px;
}

.newAdButton {
  border-radius: 15px;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 4px;
  }
}
