.adTypeInputWrapper {
  padding-top: 4px;
}

.adTypeInput {
  width: 200px;
}

.fillAdInfoWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.fillAdInfoThumbnailWrapper {
  height: 23px;
}

.thumbnailWrapper {
  height: 57px;
}

.thumbnail {
  display: block;
  height: 100%;
  width: auto;
}

.mediaColumnTitleWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  vertical-align: center;
}

.mediaColumnTitle {
  margin-bottom: -2px; // align media column title with media column icon
}

.mediaColumnIcon {
  font-size: 12px;
}

.tablePagination {
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  margin: 0;
  padding: 10px 0;
}

.errorRow {
  background: #fff1f0;
}

.highlightRow {
  background: #fffbe6;
}

.spinWrapper {
  height: 100%;
}

.matchColumnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.alertContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fillAdInfoColumn {
  min-width: 60px;
  max-width: 325px;
}

.invalidCellContent {
  background-color: #ffccc7;
}

.feedColumnCellWrapper {
  display: flex;
  align-items: center;
}

.assetBatchNameTooltip {
  margin-left: 4px;
}

.assetBatchNameTag {
  margin-left: 18px;
}

.infoIcon {
  color: #faad14;
}

.checkIcon {
  color: #52c41a;
}

.errorIcon {
  color: #ff4d4f;
}

.columnTitleInput {
  width: 300px;
}
