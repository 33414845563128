.review-container {
  width: 100%;
  height: auto;

  padding: 1em;

  .ant-badge-count {
    top: -2px;
    left: 5px;
  }
  .asset-type-collapse-container {
    .asset-type-info {
      display: flex;
      .text-enabled {
        color: white;
        opacity: 1;
      }
      .text-disabled {
        color: white;
        opacity: 0.75;
      }
      .dimension-checkbox {
        padding-bottom: 0;
        margin-left: 20;
        margin-left: 1.2em;
        margin-right: 0.7em;
        color: white;
      }
      .ant-badge {
        padding-top: 0.3em;
      }
    }
  }

  .dimension-collapse-container {
    .ant-collapse-header {
      background-color: Transparent !important;
    }
    .number-counter {
      flex: initial;
      display: inline-block;
      background: rgba(0, 0, 0, 0.15);
      padding: 1px 15px;
      font-size: 0.7rem;
      border-radius: 15px;
      margin: 0 0.5em;
    }
    .inner-dimension-checkbox {
      padding-bottom: 0;
      margin-left: 0.5em;
      margin-right: 0.5em;
      color: white;
    }
  }
  .review-collapse-panel-previews {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;

    margin: 1em 0;

    > .dimension {
      flex: initial;
      width: 10em;
      height: auto;
    }

    > .template-previews {
      flex: 1;
      height: auto;

      display: flex;
      flex-direction: column;

      .template-previews-divider {
        align-items: flex-start;
      }
      > .preview {
        flex: 1;
      }
    }
  }
}
