.buttonWrapper {
  padding: 0;
  border: 0;
}

.tagActive {
  outline: 1px solid #1890ff;
  color: #1890ff;
  background-color: #e9f7fe;
  z-index: 1;
}

.tagIcon {
  font-size: 10px;
  margin-left: 3px;
  color: #00000073;
}
