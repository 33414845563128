.icon {
  display: inline-flex;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  vertical-align: middle;
}

.expanded {
  transform: rotate(90deg);
}
