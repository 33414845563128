.tag {
  max-width: 120px;
  user-select: none;
  vertical-align: middle;
}

.activeTag {
  border-color: #1890ff;
  color: #1890ff;
  background-color: white;
}

@keyframes changeColor {
  0% {
    border-color: #d9d9d9;
  }
  50% {
    background-color: skyblue;
    border-color: skyblue;
  }
  100% {
    border-color: #d9d9d9;
  }
}

.updatedTag {
  animation-name: changeColor;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.toolTipText {
  color: white;
}

.searchInput {
  margin-bottom: 8px;
}
