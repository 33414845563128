.OfferFieldList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .Collapse {
    width: 100%;
    height: auto;

    background: white;

    border: 0;

    > .CollapsePanel {
      > div {
        background: white;
      }
    }

    > div {
      border: 0;
      > div:nth-child(2) {
        border: 0;

        > div {
          padding-top: 0;
          padding-bottom: 0;
        }
        padding-left: 1.5em;
      }
    }
  }
}
