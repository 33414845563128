.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: calc(100vw - 48px);
  padding: 24px;
  gap: 10px;
}

.icon {
  margin: 0;
}
