.body {
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
}

.bodyLeft {
  height: 100%;
  background-color: #fff;
}

.bodyRight {
  height: 100%;
  padding: 0 18px;
}

.required {
  color: #ff0000;
  margin-left: 4px;
}

.input {
  width: 100%;
}

.fieldTitle {
  font-size: 14px;
  font-weight: 600;
  margin-top: 14px;
  color: #000;
  opacity: 0.45;
}

.field {
  margin-left: 8px;
}
