.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 48px;
  z-index: 9999;

  .message {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
  }

  >img {
    width: 100%;
    max-width: 250px;
    height: auto;
  }
}
