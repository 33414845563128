.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 20px;
  margin-top: 20px;
}

.loadMoreButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  margin-right: 1em;
  font-weight: bold;
}
