$cardWidth: 230px;

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, $cardWidth);
  grid-gap: 45px 30px;
  gap: 45px 30px;
}

.virtualGridContainer {
  padding-right: 0;
}
