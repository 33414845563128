.OfferCollapseWrapper {
  padding-right: 1em;
  min-height: 50em;

  overflow-x: auto;
}

.Spin {
  min-height: 50em;
}
