.host {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid transparent;

  &[data-selected="true"] {
    outline-color: #32c5ff;
  }
}
