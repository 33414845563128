.statistics {
  margin-top: 20px;
  margin-right: 10px;
}

.statisticsRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.statisticsBtn {
  padding-left: 0;
  padding-right: 0;
  margin: 10px 0 5px;
}

.importIcon {
  color: #1890ff;
  font-size: 24px;
}

.title {
  display: flex;
  align-items: center;
}

.notification {
  width: 384px;
}

.hiddenCloseIcon {
  display: none;
}
