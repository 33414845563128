.ButtonWrapper {
  position: relative;

  > .Button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: #eee;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    height: 20px;
    line-height: 1;
    width: 20px;

    &:hover {
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}
