.radioSelectionTitle {
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.sectionTopSpacing {
  padding-top: 5%;
}

.requiredField {
  color: red;
}

.ftpFormContainer {
  // removes red asterisk from required field (as per designs)
  :global {
    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
  }
}
