div.loader {
  width: 100%;
  height: 15em;

  position: relative;

  .spinner {
    position: absolute;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
}

span.collapse-container-element-count {
  border-radius: 20px;
  background-color: #f56a00;
  margin-left: 0.5em;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.stamp-list-collapse {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;

  > .title {
    flex: initial;
  }

  > .number-counter {
    flex: initial;

    display: inline-block;
    background: #f47920;
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 0.5em;
  }
}

.stamp-list-collapse-artboard {
  color: rgba(0, 0, 0, 0.75);
  > .number-counter {
    flex: initial;

    display: inline-block;
    background: rgba(0, 0, 0, 0.15);
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 1em;
  }
}

.stamp-list-artboard-collapse-container {
  .ant-collapse-header {
    background-color: Transparent !important;
  }
  svg {
    fill: rgba(0, 0, 0, 0.75);
  }
}
