.drawerBody {
  height: 100%;
}

.lineItems {
  background-color: #f5f5f5;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
