@font-face {
  font-family: "Everett-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Everett-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Everett-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Everett-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Everett-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/Everett-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "EverettMono-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/EverettMono-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Maserati/EverettMono-Regular.woff")
    format("woff");
}
