.previewContainer {
  color: #1c1e21;
  direction: ltr;
  line-height: 1.34;

  & * {
    font-family: Helvetica, Arial, sans-serif;
  }
  font-size: 12px;

  background-color: #ffffff;
  width: 320px;
}

.body {
  width: 320px;
  position: relative;
  margin-bottom: 8px;

  .media {
    position: relative;
    cursor: pointer;
  }

  .line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}

.ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
