.drawer {
  display: grid;
  grid-template-columns: 464px 1fr;
  height: 100%;
  gap: 10px;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
}
