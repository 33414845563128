.host {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.tag {
  max-width: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0;
}
