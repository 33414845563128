.container {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  .menuItem {
    &:hover {
      background: #f5f5f5;
      color: initial;
    }
  }
}
