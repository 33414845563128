$not-selected-background-color: lightgray;

.image-selection {
  .title.image-selection {
    > .image-selection-title {
      flex: 1;
    }
    > .image-uploader-button {
      flex: initial;
      width: 4em;

      text-align: center;

      font-size: 0.8rem;
      color: #32c5ff;

      &:hover {
        cursor: pointer;
      }
    }
  }
  > .image-type-selection-tabs {
    height: 3em;
    line-height: 3em;

    display: flex;
    flex-direction: row;

    > .image-type-tab {
      flex: 1;

      text-align: center;

      background: white;

      &:not(.selected) {
        background: $not-selected-background-color;
        opacity: 0.9;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
