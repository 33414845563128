.container {
  width: 100%;
  height: 100%;

  > * {
    height: 100%;
  }

  > .left {
    overflow-y: auto;
    padding-right: 10px;
  }

  > .right {
    background-color: #f5f5f5;
    padding-left: 10px;
  }
}
