.host {
  fieldset {
    > div {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
      gap: 1em;
    }
  }

  legend {
    color: initial;
    font-weight: bold;
    font-size: 14px;
    border: 0;
    margin: 0;
  }
}
