.screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .description {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
  
  .cards {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: row;
    gap: 24px;
  }
}
