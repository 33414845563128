@font-face {
  font-family: "Avenir 35 Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+35+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+35+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 35 LightOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+35+LightOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+35+LightOblique.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 45 Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+45+Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+45+Book.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 45 BookOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+45+BookOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+45+BookOblique.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 55 Roman";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+55+Roman.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+55+Roman.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 65 Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+65+Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+65+Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 65 MediumOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+65+MediumOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+65+MediumOblique.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 85 Heavy";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+85+Heavy.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+85+Heavy.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 85 HeavyOblique";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+85+HeavyOblique.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+85+HeavyOblique.woff")
    format("woff");
}
@font-face {
  font-family: "Avenir 95 Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+95+Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Avenir/Avenir+95+Black.woff")
    format("woff");
}
