@font-face {
  font-family: "Volvo Novum-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-SemiLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-SemiLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-SemiLight.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Novum-SemiLightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-SemiLightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Novum-SemiLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Volvo Sans Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Sans+Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/Volvo+Sans+Regular.woff")
    format("woff");
}
@font-face {
  font-family: "VolvoBroadPro";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoBroadPro.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoBroadPro.woff")
    format("woff");
}
@font-face {
  font-family: "VolvoSans Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoSans+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoSansBold.woff")
    format("woff");
}
@font-face {
  font-family: "VolvoSansPro-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoSansPro-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volvo/VolvoSansPro-Light.woff")
    format("woff");
}
