.content {
  > .template-tags-select {
    width: 100%;
    max-height: 100%;

    > .ant-select-selection--multiple {
      max-height: 100%;
    }
  }
}
