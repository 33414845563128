.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .icon {
    font-size: 55px;
    color: #b8b8b8;
  }

  .title {
    font-size: 20px;
    color: #b8b8b8;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .preview {
    width: 100%;
    height: 100%;
    padding: 24px;
    position: relative;
  }
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  background-color: black;
  position: absolute;
  .icon,
  .title {
    color: white;
    opacity: 1;
  }
}
