.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
}

.emptyList {
  width: 537px;
}
