div.ad-load-modal-content {
  padding-top: 1em;
  .ant-row {
    padding-left: 1em;
    padding-right: 1em;
  }

  .ad-load-modal-collapse {
    .ant-collapse-content-box {
      padding-right: 0;
    }

    .ant-collapse-header {
      padding-bottom: 3px;

      color: #ffffff;
      .anticon.anticon-right.ant-collapse-arrow {
        color: #ffffff;
      }
    }
  }
}

.start-ad-load-btn {
  color: #ffffff;
  background-color: #1890ff;
  border-color: #1890ff;

  &:focus,
  &:hover {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff;
  }
}

.start-ad-load-btn[disabled],
.start-ad-load-btn[disabled]:hover,
.start-ad-load-btn[disabled]:focus,
.start-ad-load-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
