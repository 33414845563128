.table {
  background-color: transparent;
  .simpleColumn {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid #0000000f;
    padding: 4px 12px;
    font-size: 14px;
  }

  .leftColumn {
    background: rgb(245, 245, 245);
    border-bottom: 1px solid #0000000f;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: bold;
  }

  .grandTotalRow .simpleColumn {
    font-size: 20px;
    border-bottom: 0px;
    font-weight: bold;
  }

  .grandTotalRow .leftColumn {
    font-size: 20px;
    border-bottom: 0px;
    font-weight: bold;
  }

  .materialName {
    font-size: 16px;
    font-weight: bold;
  }

  .materialPrintOption {
    color: rgba(0, 0, 0, 0.55);
    font-size: 14px;
  }

  .editIcon {
    margin-right: 4px;
  }
}

.helpText {
  color: #555555;
  text-align: right;
  display: block;
}
