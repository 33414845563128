.divider {
  height: 100vh;
  margin: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  :global {
    .ant-typography {
      margin-bottom: 0.5em;
    }
  }
}

.table {
  width: 100vw;
  margin-top: 1em;
}

.loadingIcon {
  color: #40a9ff;
}

.progressTitle {
  margin-top: 1em;
};

.child {
  margin-left: 1.5em;
}

.tagContainer {
  display: flex;
  flex-direction: column;
}

.successMessage {
  :global {
    .ant-message-notice-content {
      background-color: #f6ffed;
    }
  }
}
