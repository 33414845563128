.sizeCtn {
  max-height: calc(100vh - 94px);
  // overflow-y: auto;

  > div + div {
    margin-top: 40px;
  }
}

.addNewInstance {
  font-size: 1.5rem;
  margin: 1em 4px;
  padding: 14px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 4px 0 rgba(black, 0.1);

  &:hover {
    cursor: pointer;
    background: lightgray;
    font-weight: bold;
  }

  > span {
    border: 1px dashed #0094d0;
    display: grid;
    place-items: center;
    padding: 2em 0;
  }
}
