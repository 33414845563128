.host {
  position: absolute;
  top: 8px;
  left: 12px;
  opacity: 1;
  transition: opacity 0.2s;

  &[data-visible="false"] {
    opacity: 0;
    transition: opacity 0.2s;
  }
}
