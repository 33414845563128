.selection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;

  >*:nth-child(1) {
    flex: 1;
  }

  >*:nth-child(2) {
    flex: 2;
  }
}

.formItem {
  margin-bottom: 0 !important;

  .extraInfo {
    color: #000000;
    margin-top: 6px;
    margin-left: 28px;
    opacity: 0.45;
  }

}
