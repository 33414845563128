@font-face {
  font-family: "FocoBlack";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoBlack.woff")
    format("woff");
}
@font-face {
  font-family: "FocoBlack-Italic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoBlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Foco-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoBold.woff")
    format("woff");
}

@font-face {
  font-family: "Foco-BoldItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoBoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Foco-Italic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Foco-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoLight.woff")
    format("woff");
}
@font-face {
  font-family: "Foco-LightItalic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocoLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Foco-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Foco/FocorRegular.woff")
    format("woff");
}
