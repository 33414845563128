.drawer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 34px;

  .title {
    width: 50%;
    justify-content: flex-start;
    margin-top: 0;

    .h3 {
      margin-bottom: 0;
    }
  }
}

.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding-right: 34px;
  margin: 0;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  margin: 0;

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.historyRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 0.5rem;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.historyRowActive {
  background-color: #e6f7ff;
  &:hover {
    background-color: #e6f7ff;
  }
}

.historyColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}

.historyColumnTime {
  justify-content: flex-end;
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  gap: 10px;

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: bolder;
    margin: 5px;
  }

  .text {
    font-weight: normal;
  }

  .button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
