.collapseContainer {
  & :global {
    .ant-collapse-header {
      background-color: #fafafa;
      display: flex;
      align-items: center;
    }
    .ant-collapse-content-box {
      background-color: white;
      border-top: 1px solid #d9d9d9;
    }
  }
}
