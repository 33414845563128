@font-face {
  font-family: "JLREmeric-ExtraLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-ExtraLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-ExtraLight.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "JLREmeric-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "JLREmeric-SemiBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-SemiBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-SemiBold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar_LandRover_Dual Branding/JLREmeric-SemiBold.woff")
    format("woff");
}
