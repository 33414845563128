.bodyContainer {
  height: 100%;
}

.borderLine {
  border-right: 1px solid #f0f0f0;
}

.stepsContainer {
  padding-left: 20px;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.backButton {
  margin-right: 1em;
}
