.checkableTag {
  border-color: #d9d9d9;
  background-color: #fafafa;
}

.tag {
  background: #fafafa;
  border: 1px solid #1890ff;
  box-sizing: border-box;
  border-radius: 2px;
  color: #1890ff;
  cursor: pointer;
}
