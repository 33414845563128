.highlightRow {
  background: #fffbe6;
}

.mapDataWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.alertContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoIcon {
  color: #faad14;
}

.checkIcon {
  color: #52c41a;
}

.columnTitleInput {
  width: 300px;
}
