.labelVariable {
  font-size: 14px;
  font-weight: 600;
  margin-top: 14px;
  color: #a0a0a0;
}

.input {
  width: 100%;
}

.required {
  color: #ff0000;
  margin-left: 4px;
}

// Antd override
.error :global, .success :global {
  .ant-alert-message {
    font-size: 14px;
  }
}

.main :global {
  padding-right: 20px;

  .ant-alert-icon {
    font-size: 14px;
    padding-top: 3px;
    margin-right: 8px;
  }
}
