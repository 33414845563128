.adDetailsDrawer {
  display: flex;
  flex-direction: column;
}

.module {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  justify-content: center;

  .title {
    padding: 1rem;
    border-bottom: 1px solid #f0f0f0;
    height: 61px;
  }

  .alert {
    width: 405px;
    margin-bottom: 24px;
  }

  .moduleContent {
    border-right: 1px solid #f0f0f0;
    padding: 2rem 2rem 1rem;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.historyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  margin: 1rem;
  background: none;
  height: 2rem;
  width: 2rem;
}

.section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .label {
    margin-right: 4px;
  }

  .value {
    max-width: 416px;
    word-wrap: break-word;
    word-break: break-all;
    display: block;
    align-self: stretch;
  }
}

.section:first-child {
  margin-top: 0;
}

.history {
  margin-left: 20px;
}

.previewRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.previewColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.commentsFooter {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 6px;
}

.qcFooter {
  display: flex;
  position: absolute;
  bottom: 15px;
  left: 20px;
}

.qcStatus {
  margin-top: 5px;
}

.ctaFooter {
  margin-top: 6px;
}
