.container {
  display: flex;
  align-items: center;
  padding-left: 24px;

  > .filters {
    display: flex;
    gap: 1em;

    > span {
      align-items: center;
    }

    > *:nth-child(1) {
      font-weight: bold;

      flex: 1;
      display: flex;
    }
  }
}
