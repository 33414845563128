div.ToolbarContainer {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div.ButtonContainer {
    flex: initial;
    height: 100%;

    display: flex;
    align-items: center;

    > div,
    > button {
      margin-right: 1em;
    }

    .SwitchContainer {
      display: flex;
      align-items: center;

      > button {
        margin: 0 0.3em;
      }
    }
  }

  > button.PreviewButton {
    flex: initial;
    width: 10em;
    background-color: #373636;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.StampListModal {
  .ant-modal-body {
    padding: 0;
    padding-left: 1.5em;
  }

  .ReloadIcon {
    margin-left: 0.7em;
    font-size: 0.8rem;
    color: #0092d0;
  }

  .StampListEmpty {
    margin: 3em 0;
  }
}

.insertMenuPopover {
  > .ant-menu-item {
    > span {
      width: 10em;
    }
  }
}
