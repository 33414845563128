.input {
  width: 100%;
}

.radiusRow {
  padding: 16px;
  margin-bottom: 24px;
  background-color: #f5f5f5;
}

.fieldsContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 0;
  flex-direction: row;
  padding-bottom: 8px;
}

.formItem {
  margin-bottom: 0;
  flex: 1;
}

.nestedFormItem {
  flex: 1;
}

.formItemGroup {
  margin: 0;
}

.helperText {
  font-size: 12px;
}
