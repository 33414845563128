.clickable {
  color: #1890ff;
  margin-left: 5px !important;
}
.clickableWithIcon {
  display: flex;
  align-items: center;
  color: #1890ff;
  cursor: pointer;
  margin: 1em 1.25em;
}
.icon {
  color: rgba(0, 0, 0, 0.45);
  margin: 0 10px;
}

.inlineBox {
  display: flex;
  align-items: center;
}

.textArea {
  resize: none;
  height: auto;
  width: 93%;
}

.textAreaWithError {
  resize: none;
  height: auto;
  width: 93%;
}

.statusContainer {
  display: flex;
  align-items: center;
}

.deleteIcon {
  color: rgba(0, 0, 0, 0.45);
  margin: 0 0 0 auto;
}

.required {
  color: red;
}
