.addAccountButton {
  color: #1890ff;
  cursor: pointer;
  padding-left: 24px;
  padding-top: 12px;
}

.formColumn {
  width: 40%;
  padding: 24px;
}

.accountsColumn {
  width: 60%;
  padding: 24px;
  background-color: #f5f5f5;
}

.title {
  margin-bottom: 12px;
}

.form {
  height: 100%;
}

.formItem {
  display: flex;
  flex-direction: column;
  width: 100%;

  :global {
    .ant-col.ant-form-item-label
      label.ant-form-item-required.ant-form-item-no-colon {
      &::before {
        display: none;
      }

      &::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}

.integrationsContainer {
  margin-top: 22px;
}

.logosContainer {
  margin-top: 22px;
}

.logosTitle {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 500;
  line-height: 22px;
}
