.container {
  display: flex;
  align-items: center;
  justify-items: start;
  margin-top: .5em;
  margin-left: 2em;

  .children {
    margin-left: .5em;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-items: start;

    .input {
      width: 50px;
    }
  }
}
