$border: 1px solid #d5cac9;

div.list-container {
  width: 100%;
  min-width: 80em;
  padding: 0.7em;

  > div.stamp-group-container {
    width: 100%;

    border-bottom: $border;

    > div.title {
      padding: 1em 0;
    }
  }

  > div.card-list-container {
    margin: 1em 0;
    width: 100%;

    display: flex;
    grid-gap: 0.5em 0.5em;
    grid-template-columns: repeat(auto-fill, 23%);
    justify-content: center;
  }
}

.stamp-list-collapse-artboard {
  color: rgba(0, 0, 0, 0.75);
  > .number-counter {
    flex: initial;

    display: inline-block;
    background: rgba(0, 0, 0, 0.15);
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 1em;
  }
}
