@font-face {
  font-family: "Muli-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-ExtraLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-ExtraLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-ExtraLightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-ExtraLightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-ExtraLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-Semi-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Semi-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Semi-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-SemiBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-SemiBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "Muli";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli.woff")
    format("woff");
}
@font-face {
  font-family: "Muli-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Muli/Muli-Black.woff")
    format("woff");
}
