.agentSelect {
  display: block;
  margin: 0 0 16px 0 !important;
  width: 100%;
}

.agentInput {
  display: block;
  padding: 0 0 16px 16px !important;
  width: 100%;
}
