div.popOver {
  svg {
    fill: rgba(0, 0, 0, 0.65);
  }
}
div.close-pop-over {
  display: flex;
  justify-content: flex-end;
}

div.imageUploader {
  padding-top: 0.5em;
}

div.imageUploader > div > span > div {
  padding-left: 0em !important;
}

div.bottomRow {
  padding-top: 40px;
  padding-bottom: 12px;
  float: right;
}

.vehicleInfoContent {
  display: flex;
}

.inputField {
  width: 10em;
}

.uploadButton {
  padding-bottom: 12px;
  float: left;
  padding-left: 0.5em;
}

.clearButton {
  padding-bottom: 12px;
  float: left;
}

.closeButton {
  float: left;
}

div.dimensionsAndFileName {
  float: left;
  padding-top: 1em;
  padding-bottom: 1em;
}

div.search-wrapper {
  display: flex;
  float: "left";
}
div.search-jellybean {
  width: 10em;
  padding-bottom: 0.5em;
}

div.logo-popover {
  div.ant-popover-inner {
    background-color: #eeeeee;
  }
}

div.library-wrapper {
  max-height: 30em;
  overflow: scroll;

  div.ant-card {
    border: none;
    &:hover {
      background: #eeeeee;
    }
  }
  div.ant-card-body {
    padding: 0.3em;
    margin: 0.3em;
  }
}

div.image-library {
  display: flex;
  flex-direction: row;
  justify-content: flex-between;
  align-items: center;

  > img {
    width: 133px;
    object-fit: contain;
  }
  div.description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0em 1em;

    > p {
      color: black;
    }
  }
}

.offer-image-search-popover
  > div
  > div
  > div
  > div
  > div
  > table
  > .ant-table-thead {
  .ant-table-cell {
    font-weight: bold;
  }
}

.offer-image-popover > div > .ant-spin-container {
  overflow: hidden !important;
}

.offer-image-popover > div > div > .ant-tabs {
  float: left !important;
}

.offer-image-popover > div > div > .close-pop-over {
  float: right !important;
}
