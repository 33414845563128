@font-face {
  font-family: "Gotham-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Book.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-BookItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-BookItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-BookItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-ExtraLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ExtraLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-ExtraLightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ExtraLightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ExtraLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Bold-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Bold-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Bold-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Book-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Book-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Book-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Book.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Medium-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Medium-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Medium-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Rounded-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Rounded-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-Ultra";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Ultra.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-Ultra.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-UltraItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-UltraItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-UltraItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-bold.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-bold-italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-bold-italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-bold-italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-bold.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-book-italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-book-italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-book-italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-book.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-light-italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-light-italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-light-italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-light.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-medium-italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-medium-italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-medium-italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-extra-narrow-medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-extra-narrow-medium.woff")
    format("woff");
}
@font-face {
  font-family: "Gotham-rounded-light-italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-rounded-light-italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/Gotham-rounded-light-italic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Black.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Book.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-BookItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-BookItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-BookItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Light.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-ThinItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-Ultra";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Ultra.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-Ultra.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-UltraItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-UltraItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-UltraItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-XLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-XLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-XLight.woff")
    format("woff");
}
@font-face {
  font-family: "GothamNarrow-XLightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-XLightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gotham/GothamNarrow-XLightItalic.woff")
    format("woff");
}
