.totalDurationContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  padding-top: 12px;
}

.totalDurationText {
  color: rgba(0, 0, 0, 0.45);
  padding-right: 20px;
}

.totalDurationUnit {
  color: rgba(0, 0, 0, 0.45);
  padding-left: 4px;
}
