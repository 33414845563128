.adSelection {
  width: 35%;
  padding-bottom: 10px;
}

.statusDot {
  > * {
    width: 11px;
    height: 11px;
  }
}

.warningIcon {
  color: rgba(250, 173, 20, 1);
}

.tableRowWarning {
  &:hover > td {
    background: rgba(250, 173, 20, 0.3) !important;
  }
  background-color: rgba(250, 173, 20, 0.3);
}

.warningText {
  display: flex;
  justify-content: space-between;
}

.requiredField {
  color: red;
}

.hideTable {
  opacity: 0;
}
