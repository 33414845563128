.searchFilter {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  padding: 8px;

  > .buttons {
    display: flex;
    gap: 10px;
  }
}
