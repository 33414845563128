.container {
  width: 100%;
  height: 100%;

  padding: 0 1em;

  display: flex;

  > * {
    flex: 1;
  }

  .assetGroupListContainer,
  .pmaxSelectionContainer {
    margin: 1em 0;
  }

  .divider {
    flex: initial;
    height: 100%;
    width: 1px;
    background-color: lightgray;
    margin: 0 1em;
  }

  .itemCountToolbar {
    display: flex;
    align-items: center;

    margin: 10px 0;

    height: 25px;

    > * {
      height: 100%;
    }

    > .filterContainer {
      display: flex;
      gap: 10px;
    }
  }

  .destinationPreview {
    display: flex;
    margin-bottom: 1em;
  }

  .spin {
    top: 30px !important;
  }
}
