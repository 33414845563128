.layerName {
  display: flex;
  align-items: center;
  gap: 1em;
}

.description {
  color: gray;
  margin-bottom: 16px;
}

.optionList {
  display: flex;
  flex-direction: column;
  gap: 0.5em;

  >.listContainer {
    background-color: #f5f5f5;
    padding: 8px;

    >.formItem {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.option {
  display: grid;
  grid-template-columns: 1fr 2.2em;
  align-items: center;
  grid-gap: 0.5em;


  >*:first-child {
    flex: 1;
  }

  >button {
    flex: initial;
    width: 2em;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #878787;
  }
}

.editPen {
  margin-right: 5px;

  path {
    fill: #1890ff;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.gray {
  color: #7f888b;
}
