div.state-tabs-container {
  div.tab-container-content-section {
    flex: 3 1 80%;
    padding: 0 0 7em 0;
    margin-left: 1em;
    max-width: 100%;
    overflow: hidden;

    .tab-container-content-tabs {
      div.ant-tabs-nav .ant-tabs-nav-list {
        width: auto;
        position: relative;
      }
    }

    div.ant-tabs-nav .ant-tabs-nav-list div:first-child div:last-child:hover {
      cursor: default;
    }
  }

  div.ant-collapse-content-box {
    overflow: scroll;
  }
}
