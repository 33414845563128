.assets {
  width: auto;
  height: 100%;
  .assetsContent {
    width: auto;
    height: 100%;
    overflow-y: auto;

    div[class="ant-collapse-header"] {
      background-color: transparent !important;
    }
  }
}
