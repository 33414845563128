.contentAdmin {
  display: grid;
  height: 100%;
}

.contentAgent {
  height: 100%;

  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  & > button {
    margin: 0 10px;
  }
}

.tabs {
  :global .ant-tabs-content-holder {
    overflow: auto;
  }
  :global(.ant-tabs-content) {
    height: 100%;
  }
}
