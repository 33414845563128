.textColor {
  color: grey;
}

.textColorWithError {
  color: red;
}

.tooltip {
  margin-left: 0.5em;
  color: grey;
}

.title {
  margin-right: 0 !important;
}

.description {
  margin: 0 !important;
}
