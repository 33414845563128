.handle {
  > * {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    pointer-events: none;
  }
}
