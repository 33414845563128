.disabledRow {
  td {
    background-color: #f5f5f5;
    opacity: 0.5;
  }
  
  :global {
    .ant-table-cell-fix-left {
      background-color: #f5f5f5;
    }
  }
}
