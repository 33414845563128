.container {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
}

.toolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.datePicker {
  padding: 0 8px 12px 12px;
  flex-shrink: 0;
}

.archiveSearch {
  flex-grow: 1;
}
