.previewHandlerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.previewHandlerContainerWithoutChildren {
  width: 405px;
  height: 720px;
}
