.host {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0 0.5em;
  margin: 0;
  animation: fade-in 0.8s;

  &:after {
    position: absolute;
    background-color: #e8e8e8;
    content: "";
    height: 1px;
    top: 0;
    right: 0;
    width: 100%;
  }

  > li {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0.5em;
    animation: exit-border-animation 0.1s;
    color: rgba(0, 0, 0, 0.85);

    &:after {
      z-index: 1;
      content: "";
      background-color: #1890ff;
      height: 1px;
      width: 0;
      position: absolute;
      top: 0;
      right: 0;
      animation: exit-border-animation 0.1s;
    }

    &[data-clickeable="true"] {
      color: lightgray;
      pointer-events: none;
    }

    &:hover {
      transition: background-color 0.2s;
      background-color: #f5f5f5;

      &:after {
        animation: enter-border-animation 0.2s;
        width: 28px;
      }
    }
  }
}

@keyframes enter-border-animation {
  from {
    width: 0;
  }
  to {
    width: 28px;
  }
}

@keyframes exit-border-animation {
  from {
    width: 28px;
  }
  to {
    width: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
