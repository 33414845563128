@font-face {
  font-family: "Roboto-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Black.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-BlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-BlackItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "Roboto-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Roboto/Roboto-ThinItalic.woff")
    format("woff");
}
