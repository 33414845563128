.checkbox {
  margin: 5px 10px;
  display: flex;
  justify-content: center;
}

.label {
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
}
