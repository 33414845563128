.host {
  height: 100%;
  display: grid;
  place-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
}

.icon {
  width: 70px;
  height: 70px;
  background: white;
  border-radius: 50%;
  display: grid;
  place-items: center;

  > span {
    font-size: 36px;
    opacity: 0.3;
  }
}

.feedback {
  min-height: 200px;
  display: grid;
  place-items: center;
  text-align: center;
}
