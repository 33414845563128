.statusDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    padding-left: 0;
  }
}
.downloadBtn {
  color: #1890ff;
  cursor: pointer;
}
.errorDescription {
  text-align: right;
  .errorText {
    padding-right: 20px;
  }
}
.notificationIcon {
  :global {
    color: #1890ff;
  }
}

.adLibExportDescription {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
}
