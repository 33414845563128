@font-face {
  font-family: "Jaguar-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Jaguar-ExtraBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-ExtraBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-ExtraBold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-ExtraBold.woff")
    format("woff");
}
@font-face {
  font-family: "Jaguar-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/Jaguar-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "JaguarModern-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/JaguarModern-Bold.otf")
    format("opentype");*/
}
@font-face {
  font-family: "JaguarModern-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/JaguarModern-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Jaguar/JaguarModern-Regular.woff")
    format("woff");
}
