.editContainer {
  margin: 1em;
}

.recordContainerV2 {
  padding: 0;
}

.editSectionHeader {
  padding: 0 1em;
  font-weight: bold;
  height: 3em;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
}

.editSectionContent {
  padding: 1em;
  background-color: #fff;
}
