.account {
  display: flex;
  width: 265px;
  height: 51px;
  padding: 16px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.icon {
  display: flex;
  width: 34px;
  height: 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.folderIcon {
  font-size: 34px;
  color: var(--primary-color, #1890ff);
  flex: 1 0 0;
  align-self: stretch;
}

.fontSize {
  font-size: 14px;
}
