.card {
  > div {
    padding: 18px 12px 18px 12px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0px;
}

.youtubeText {
  margin-left: 10px;
}

.connectedButton {
  &:disabled {
    pointer-events: none;
    background-color: #F6FFED;
    color: #52C41A;
    border-color: #B7EB8F;
    margin-right: 5px;
  }
}

.divider {
  margin-bottom: 12px;
}

.detail {
    display: flex;
    flex-direction: column;
    > :first-child {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
    }
}
