.paginationContainer {
  width: 100%;
  display: inline-flex;
  height: 3em;
  justify-content: center;
  margin-top: 1em;
  > .displayingDescContainer {
    margin-right: 1em;
    margin-top: 0.3em;
    font-size: medium;
  }
}
