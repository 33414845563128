.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
  row-gap: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  position: sticky;
  top: 0;
  bottom: 10px;
  padding: 16px;
  z-index: 1;
  background-color: #f5f5f5;
  width: 100%;
}

.previewHeader {
  display: flex;
  align-items: center;
  column-gap: 10px;

  > span {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

.noSelection {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 240px;
  height: 240px;
  font-size: 14px;
  background: linear-gradient(to right, #000 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#000 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #000 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#000 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 16px 3px, 3px 16px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    user-select: none;
  }
}

.plusIcon {
  font-size: 32px;
  margin-bottom: 8px;
}

.changeTemplate {
  appearance: none;
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
  color: #1890ff;
}

.templateHeader {
  width: 100%;
  flex: initial;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.templateName {
  cursor: pointer;
  flex: auto;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.playButton {
  padding: 4px 15px 4px 15px;
}

.zoomContainer {
  overflow: auto; /* Enable scrolling */
  transform-origin: 0 0; /* Set the origin of the scale transformation */
}

.previewRow {
  overflow: auto;
}

.previewColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
