.lifestyle-image-thumbnail-container {
  display: grid;
  overflow: hidden;

  > img {
    width: 100%;
    object-fit: cover;
    padding: 5px;
  }

  > div {
    display: flex;
    overflow: hidden;

    > span {
      display: block;
      padding: 0.5em;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
