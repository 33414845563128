.flexCheckboxGroup {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  gap: 8px;
  min-width: 250px;
  margin-bottom: 8px;

  :global {
    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }
}

.resetButton {
  width: 90px;
  margin-right: 5px;
}

.confirmButton {
  width: 90px;
  margin-left: 5px;
  float: right;
}

.buttonSpace {
  width: 100%;
  display: flex;
}
