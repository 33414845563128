@font-face {
  font-family: "Louis-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Louis-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "Louis-CondensedDemi";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-CondensedDemi.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-CondensedDemi.woff")
    format("woff");
}
@font-face {
  font-family: "Louis-CondensedRegular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-CondensedRegular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-CondensedRegular.woff")
    format("woff");
}
@font-face {
  font-family: "Louis-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Louis-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Chevrolet/Louis-Regular.woff")
    format("woff");
}
