.volumeIcon {
  margin-right: 8px;
  width: 16px !important;
  height: 16px !important;
}

.rightArrow {
  margin-right: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: auto;
}

.playButton {
  border: none;
  background: transparent;
  margin-left: 24px;
  margin-right: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  > span {
    display: inline-flex;
  }
}

.videoTime {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #595959;
  font-family: "Roboto Medium", sans-serif;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
