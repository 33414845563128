.host {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  button {
    opacity: 0;

    // Make button visible when parent is hovered.
    :global([data-selected-tpl-card]:hover) & {
      opacity: 1;
    }
  }
}
