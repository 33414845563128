.uploadedFile {
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  border-radius: 2px;
  padding: 24px 16px;
  max-height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  a {
    margin-left: 10px;
  }
}
