.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closeContainer {
  cursor: pointer;
  height: 100%;
  display: flex;
  margin-left: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  :global {
    .anticon {
      font-size: 10px;
      opacity: 0.45;
      color: #000;
    }
  }
}
