.container {
  h3 {
    font-weight: bold;
    font-size: 12px;
    text-align: left;
  }
  :global {
    .ant-message-notice-content {
      padding: 0 !important;
    }
    .ant-alert {
      align-items: flex-start;
    }
    .ant-alert-close-icon .anticon {
      color: gray;
      font-size: 14px;
    }
  }
}

.messageContainer {
  display: flex;
  flex-direction: row;
}

.body {
  ul,
  ol {
    list-style: "- ";
    text-align: start;
    padding: 0 1em;
  }
}
