$hover-color: #1890ff;
$selected-background-color: #e6f7ff;

.menu {
  height: 40px;
  line-height: 40px;
  padding-left: 24px;
  margin: 4px 0;

  overflow: hidden;

  position: relative;

  .title {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  > .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
  }

  > span {
    color: rgba(0, 0, 0);
  }

  > a {
    color: rgba(0, 0, 0, 0.65);
  }

  &:hover {
    cursor: pointer;

    > .anticon {
      > svg {
        color: $hover-color;
      }
    }

    > a {
      color: $hover-color;
    }

    > span {
      color: $hover-color;
    }
  }

  &.selected {
    background: $selected-background-color;

    > .anticon {
      > svg {
        color: $hover-color;
      }
    }

    > span {
      color: $hover-color;
    }

    > a {
      color: $hover-color;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 3px solid #1890ff;
      -webkit-transform: scaleY(0.0001);
      -ms-transform: scaleY(0.0001);
      transform: scaleY(0.0001);
      opacity: 0;
      -webkit-transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      content: "";
    }
  }
}
