.templateHeader {
  width: 100%;
  flex: initial;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.templateName {
  cursor: pointer;
  flex: auto;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.changeTemplate {
  appearance: none;
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
  color: #1890ff;
}
