.footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  & > button {
      margin: 0 10px;
    }
}

.content {
  display: grid;
  grid-template-columns: 3fr 7fr;
  height: 100%;
}

.layers {
  padding: 1em 1em 1em 0;
  overflow: auto;
}

.tabs {
  :global .ant-tabs-content-holder {
    overflow: auto;
  }
}

.drawer {
  display: grid;
  grid-template-columns: 464px 3fr;
  height: 100%;
  gap: 10px;
}
