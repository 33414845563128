$border-color: #1890ff;

.card-toggle {
  pointer-events: none;
  > .box-inner-wrapper {
    > .card-label-title {
      position: absolute;
      width: auto;
      height: auto;
      transform: translateY(-120%);
      background: $border-color;
      color: white;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0 10px;
    }
    > .toggle-button-wrapper {
      pointer-events: fill; // This allows user to click on the toggle button while moving image within the boundary if allowed

      > .clickable-buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

.logo-popover {
  .library-wrapper {
    max-width: 150px;
    overflow-y: auto;
    overflow-x: hidden;

    .logo-preview-card {
      background: #eeeeee;

      img {
        background: inherit;
      }
    }
  }
}
