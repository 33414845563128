.host {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 10px;
}

.filterButton {
  font-size: 0.9em;
  height: 22px;
  padding: 0 7px;

  span svg {
    font-size: 10px;
    margin-left: 3px;
    color: #00000073;
  }
}

.select {
  width: 100%;
}
