.pharmaFilterContainer {
  border: 1px solid #d5cac9;
  padding: 1em 1em 0 1em;
  margin-bottom: 6%;
  .pharmaFilterTitle {
    margin-bottom: 3%;
  }
  .fielterField {
    margin-top: 2%;
    background-color: #fff;
    font-size: 14px;
    padding: 2% 5%;
  }
}
