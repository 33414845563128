.content {
  width: 400px;
  height: fit-content;

  .header {
    .title {
      font-weight: bold;
    }
  }

  .datePickerContainer {
    margin: 1em 0;

    display: flex;
    justify-content: space-between;
    gap: 1em;

    > * {
      flex: 1;

      > * {
        width: 100%;
      }
    }
  }

  .date-picker {
    width: 100%;

    &.error {
      border: 1px solid red;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}

.clickToSelect {
  cursor: pointer;
  color: gray;

  &.valid {
    color: black;
  }
}
.error {
  color: red;
}
