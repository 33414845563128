.copy {
  margin: 10px 5px 15px;
}

.grid {
  display: grid;
  grid-template-columns: 200px 200px;
  justify-content: center;
  gap: 5px;
}

.cardData {
  margin: 10px;
  > p {
    margin-bottom: 0;
    font-family: Helvetica, Arial, sans-serif;
  }
}

.cardPicture {
  background-color: #f3f3f3;
}

.cardPicture,
.cardImage {
  font-size: 60px;
  color: #818181;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
