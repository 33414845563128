.toolPicker {
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 100%;
  border-right: 1px solid #f0f0f0;

  .toolPickerTab {
    height: 104px;
    width: 110px;
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: unset;
    border-bottom: 1px solid #f0f0f0;
    font-size: 12px;
    font-weight: 400;
    gap: 8px;
  }

  .toolPickerTabActive {
    border-right: 2px solid #1890ff;
    color: #1890ff;
  }
}
