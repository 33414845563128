.SubMenu {
  border: 0;
}

.CamContainer {
  &:hover {
    padding-left: 0px;
    padding-right: 0px;
  }

  > span {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

.shapeMenu {
  display: flex;
  flex-direction: column;
  align-items: center;

  > li {
    padding: 0 0.8em;
    display: flex;
    align-items: center;
  }

  .shapeMenuItem {
    &:hover {
      background: #e8e8e8;
    }

    .shapeWrapper {
      width: 2em;
      height: 2em;

      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;

      > .shape.square {
        width: 1.5em;
        height: 1.5em;
        background: #d8d8d8;
        border: 1px solid #b9b9b9;
      }

      > .shape.circle {
        width: 1.5em;
        height: 1.5em;
        background: #d8d8d8;
        border: 1px solid #b9b9b9;
        border-radius: 7.25em;
      }

      > .shape.squircle {
        width: 1.7em;
        height: 1.4em;
        background: #d8d8d8;
        border: 1px solid #b9b9b9;
        border-radius: 0.4em;
      }

      > .triangleInnerWrapper {
        width: 105%; // added 5% more in order to fully display right edge of the triangle
        height: 100%;

        border-bottom: 1px solid #b9b9b9;

        position: absolute;
        top: -30%;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        > .shape.triangle {
          width: 1.5em;
          height: 1.5em;
          background: #d8d8d8;
          border: 1px solid #b9b9b9;

          transform: rotate(45deg);
          position: absolute;
          top: 65%;
        }
      }
    }
  }
}
