.container {
  padding-top: 1em;
}

.drawerBody {
  // Antd override
  :global {
    .ant-drawer-body {
      padding: 0;
    }
  }

  .drawerFooter {
    justify-content: flex-end;
    width: 100%;
  }

  .drawerContent {
    width: 100%;
    min-height: 100%;
    margin: 0 !important;

    .formSection {
      padding: 24px !important;
    }

    .assetGroupSection {
      padding: 24px !important;
      background-color: #f5f5f5;
    }

    .fullWidth {
      width: 100%;
    }

    .urlExclusionsSection {
      margin: 0;
    }

    .exclusionList {
      padding: 16px;
      background-color: #f5f5f5;
    }

    .exclusionListRow {
      margin-bottom: 8px;
    }

    .removeButton {
      padding: 0;
    }

    .infoList {
      margin-top: 16px;
    }
  }

  .assetGroupDrawerContent {
    height: 100%;
    overflow-y: hidden;
    background-color: #f5f5f5;
    padding-left: 10px;

    :global {
      .ant-table-body {
        height: 500px;
        margin-bottom: 1em;
      }
      .ant-checkbox-wrapper {
        padding-bottom: 0px;
      }
    }
  }
}

.storeSelect {
  width: 450px;
}

.loadDrawerContent {
  width: 100%;
}

.loadDrawerFooter {
  width: 100%;
  justify-content: space-between;
}

.successIcon {
  color: #52c41a;
}

.errorIcon {
  color: #f5222d;
}

.inputNumber {
  width: 100%;
}

.requiredMark {
  color: red;
}
