@font-face {
  font-family: "MMC-Regular";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/Mitsubishi/MMC-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "MMC-Medium";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/Mitsubishi/MMC-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "MMC-Bold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/Mitsubishi/MMC-Bold.woff")
    format("woff");
}
