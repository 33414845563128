.loading {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.record-container-v2 {
  background-color: #f7f7f7;
  padding: 1em;
}

.legal-lingo-edit-save {
  float: right;
  padding-right: 1em;
}

.legal-lingo-root-container {
  .ant-row {
    padding: 15px 15px 0;
  }
}
