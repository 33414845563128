.resizeContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .leftPanel {
    display: flex;
    flex-grow: 1;
    max-width: 564px;
    background-color: white;

    .inputSectionContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;

      .inputTextArea {
        min-height: 84px;
      }
    }
  }

  .resultsContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 976px;
    height: 100%;
    flex-grow: 1;
    background-color: #f5f5f5;
    gap: 28px;
    padding: 24px;

    .buttonsRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      width: 100%;
    }

    .emptyState {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}
