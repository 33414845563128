.thumbnailCell {
  display: flex;
  padding-left: 8px;
  flex-direction: column;
  align-items: flex-start;
}

.thumbnailWrapper {
  display: flex;
  padding: 9px 0px;
  align-items: center;
  gap: 8px;
}

.thumbnail {
  width: 68px;
  height: 68px;
}

.preview {
  font-size: 24px;
}
