.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
}

.icon {
  flex: initial;
  display: inline-block;
}

.message {
  margin-left: 0.5em;
}
