.resultTable {
  padding: 8px 0;
  margin: 8px 0;
}

.loading {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.4;
  svg {
    font-size: 30px;
  }
  h3 {
    margin-top: 1em;
  }
}

.successMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}
