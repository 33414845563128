.tag {
  max-width: 120px;
  user-select: none;
  vertical-align: middle;
}

.activeTag {
  border-color: #1890ff;
  color: #1890ff;
  background-color: white;
}

.innerTag {
  margin: 4px 2px;
}

.tagsGroupPopover :global {
  .ant-popover-arrow {
    display: none;
  }
}

.tagsContainer {
  overflow: auto;
  max-height: 303px;
}
