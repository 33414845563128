.host {
  > section {
    display: flex;
    gap: 1em;
    margin-bottom: 1rem;

    > :first-child {
      flex: 1;
    }
  }
}
