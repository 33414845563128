.main {
  position: fixed;
  bottom: 80px;
  right: 40px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  width: 42px;
  gap: 8px;
}

.container {
  border: 1px solid #ababab;
  border-radius: 4px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 42px;
}
