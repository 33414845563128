$border: 1px solid #d5cac9;

.clear-link {
  width: 100%;
  text-align: right;
  padding: 0.5em;

  > a {
    padding-right: 0.5em;
    font-size: 0.7em;
  }
}

.filter-section-option-container {
  padding-top: 20px;
  div.by-offer-type {
    border: $border;
    margin-bottom: 1em;
  }

  div.sort-by {
    border: $border;
  }

  .label {
    padding: 1em;
    border-bottom: $border;
  }

  .filter-options,
  .sort-options {
    padding: 1em;
  }
  .sort-option-box {
    padding: 0.5em 0 0 0.5em;
    > .ant-tag {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .filter-options li,
  .sort-options li {
    padding: 0.5em;
  }
}
.filter-field-container {
  border: 1px solid #d5cac9;
  padding: 1em;
  margin-bottom: 6%;
  .known-filter-field {
    margin-top: 2%;
    background-color: #fff;
    font-size: 14px;
    padding: 2% 5%;
  }
  .unknown-filter-field {
    .ant-input-group-addon {
      display: none;
    }
    display: flex;
    .unknown-field {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-right: 0;
    }
  }
}

.vehicle-condition-filter {
  border: 1px solid #d5cac9;
  padding: 1em;
  .active-btn {
    border-color: #40a9ff;
    color: #40a9ff;
  }
}

.filter-offer-container {
  .ant-collapse-content {
    background-color: #fff !important;
  }
}
.filter-vehicle-condition-button-group {
  flex: 1;
  padding-top: 10px;
  display: flex;

  > button {
    flex: 1;
  }
}
