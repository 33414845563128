div.primary-message-container {
  color: white;
  padding: 1.3em;

  min-height: 5em;

  .message-text-container {
    width: 100%;
    min-height: 2em;
    margin-bottom: 1em;

    > .label {
      font-weight: bold;
      font-size: 0.8rem;
      text-decoration: underline;
    }
  }

  .copy-icon {
    margin-left: 0.5em;
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }

  .copy-tooltip {
    position: relative;

    .copy-textarea {
      position: absolute;

      width: 0.1px;
      height: 0.1px;

      z-index: -100;
    }
  }
}
