.container {
  padding-top: 1rem;

  .message {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      color: #ff4d4f;
      margin-right: 0.5rem;
    }

    .text {
      color: #000;
    }
  }
}
