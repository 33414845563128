.artboard-list-collapse {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;

  > .title {
    flex: initial;
  }

  > .number-counter {
    flex: initial;

    display: inline-block;
    background: #f47920;
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 0.5em;
  }
}
