.alert {
  align-items: flex-start;

  svg {
    margin-top: 4px;
  }

  .alert-cols {
    display: flex;
    flex-direction: column;
  }
}
