@font-face {
  font-family: "Frutiger LT 45 Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+45+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+45+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Frutiger LT 47 Light Condensed";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+47+Light+Condensed.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+47+Light+Condensed.woff")
    format("woff");
}
@font-face {
  font-family: "Frutiger LT 55 Roman";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+55+Roman.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+55+Roman.woff")
    format("woff");
}
@font-face {
  font-family: "Frutiger LT 65 Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+65+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+65+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Frutiger LT 67 Bold Condensed";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+67+Bold+Condensed.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+67+Bold+Condensed.woff")
    format("woff");
}
@font-face {
  font-family: "Frutiger LT Std 46 Light Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+Std+46+Light+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Frutiger/Frutiger+LT+Std+46+Light+Italic.woff")
    format("woff");
}
