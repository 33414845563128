.LayerItem {
  box-sizing: border-box;

  &:hover,
  &.Highlight {
    background-color: rgba(220, 220, 220, 0.2);
  }

  .Button {
    &:hover {
      cursor: pointer;
    }
  }

  width: 100%;
  height: 2.5em;
  padding: 0.5em;
  margin: 0.5em 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 1rem;
  color: white;

  border: 2px dashed transparent;

  > * {
    height: 100%;
  }

  > .VisibilityToggleButton {
    flex: initial;
    margin-right: 1em;
  }

  > .Title {
    flex: 5;

    user-select: none;
  }

  > .ButtonWrapper {
    flex: initial;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .Button {
      margin: 0 0.2em;
    }
  }
}
