.adTableThumbnailModal :global {
  .ant-modal-close {
    color: white;
  }
  .ant-modal-content {
    background-color: black;

    .ant-modal-body {
      padding: 0;
      .slick-dots li {
        width: 12px !important;
        > button {
          background: #cccccc;
          width: 12px;
          height: 12px;
          border-radius: 5px;
        }
      }
    }
  }
}
