.StrokeControl {
  width: 100%;
  height: auto;
  background: #4a4949;
  padding: 15px 15px 15px 25px; // antd collapse header padding
  display: flex;
  flex-direction: column;
  gap: 10px;

  > .FlexRow {
    width: 100%;
    height: calc(4em - 15px - 15px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: white;

    > * {
      flex: 1;
    }

    > :last-child {
      flex: initial;
    }
  }

  .Top {
    > .DeleteIcon {
      color: white;
    }
  }

  .Bottom {
    > .WidthControl {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      > .Square {
        width: 2em;
        height: 100%;
        border: 1px solid white;
      }
    }

    > .RadiusControl {
      display: flex;
      align-items: center;
      gap: 10px;

      > :first-child {
        font-size: 28px;
      }
    }
  }
}
