.qcStatusDropdown :global {
  .ant-select-selection-item {
    text-overflow: initial;
  }
}

.qcStatusDropdownInvisible :global {
  .ant-select-selector {
    border-color: transparent !important;
    background-color: transparent !important;
  }
  .ant-select-arrow {
    display: none;
  }
}

.qcStatusDropdownAlignRight :global {
  .ant-select-selector {
    text-align: right;
    padding-right: 0 !important;
  }
  .ant-select-selection-item {
    padding-right: 0 !important;
  }
}
