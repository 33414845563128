.card {
  width: 100%;
  height: auto;
  color: #818181;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .picture {
    flex: initial;
    color: gray;
    width: auto;
    height: 100px;

    font-size: 30px;

    margin-top: 25px;
    padding-top: 10px;
  }

  .footer {
    flex: 1;
    width: 100%;

    background-color: white;
    padding: 5px;
  }
}
