.flowButton {
  background-color: rgb(255, 255, 255);
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  width: 100%;

  .buttonPreview {
    width: 90%;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    * {
      font-family: inherit;
      margin: 0;
      padding: 0;
    }
  }
}
