$selectionColor: #32c5ff;
$borderColor: #d5cac9;
$border: 1px solid $borderColor;
$selectedCardBorder: 3px solid $selectionColor;
$cardPadding: 1em;
$cardShadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.75);
$borderRadius: 0px 0px 12px 12px;

.card {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 240px;
  max-height: 300px;
  border-radius: $borderRadius;
  box-shadow: $cardShadow;
}

.cardSelected {
  border: $selectedCardBorder;
}

.flexRow {
  display: flex;
  align-items: center;
  text-align: left;
  height: 52px;
  padding: 0.1em 1em;
  background-color: #ffffff;
}

.card img.noPadding {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $borderRadius;
  overflow: hidden;

  display: block;
}

.card img.withPadding {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  border-radius: 5px;
  height: auto !important;
  background: rgba(0, 0, 0, 0.1);
}

.imageContainer {
  width: 100%;
  height: 18em;
  margin: 1em 0;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.textBold {
  font-weight: 600;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0 15px;
  border-radius: 2px;
  border: solid 0.5px #979797;
}

.buttonsInvisible {
  display: none;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: -15px;

  > .cardButton {
    margin: 0 3px;
    border: 2px solid #9fabb1;
    box-shadow: $cardShadow;
  }

  > .disabledButton {
    box-shadow: none;
  }

  > *:focus,
  > *:active {
    color: #9fabb1;
    border-color: #9fabb1;
  }
}

.card .cardItemsOverlayDiv {
  position: absolute;
  left: 0px;
  top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 4px 0px 0px;
}

.cardItemsOverlayDiv {
  .itemsDiv {
    flex: none;
    flex-grow: 0;
  }
  .tagItems {
    margin-top: 6px;
  }
}

.cardSpin {
  text-align: center;
  max-height: 240px;
  border-radius: 0px 0px 12px 12px;
}

.cardSpinWithScaleDown {
  border-top: $border;
}

.noPaddingImageWrapper {
  width: 100%;
}
