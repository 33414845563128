@font-face {
  font-family: "DesignKB";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKB.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKB.woff")
    format("woff");
}
@font-face {
  font-family: "DesignKL";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKL.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKL.woff")
    format("woff");
}
@font-face {
  font-family: "DesignKM";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKM.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/DesignKM.woff")
    format("woff");
}
@font-face {
  font-family: "KiaSignatureOTFBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFBold.woff")
    format("woff");
}
@font-face {
  font-family: "KiaSignatureOTFLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFLight.woff")
    format("woff");
}
@font-face {
  font-family: "KiaSignatureOTFRegular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFRegular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Kia/KiaSignatureOTFRegular.woff")
    format("woff");
}
