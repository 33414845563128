@font-face {
  font-family: "Acura Bespoke-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Acura Bespoke-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Book.woff")
    format("woff");
}
@font-face {
  font-family: "Acura Bespoke-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Acura Bespoke-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura+Bespoke-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Acura-Performance";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura-Performance.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/Acura-Performance.woff")
    format("woff");
}
@font-face {
  font-family: "AcuraPerformance-Web";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/AcuraPerformance-Web.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Acura/AcuraPerformance-Web.woff")
    format("woff");
}

@font-face {
  font-family: "Acura Performance-V2";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/Acura/Acura-Performance-v2.woff")
    format("woff");
}
