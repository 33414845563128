@font-face {
  font-family: "Genesis Sans Head Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Genesis Sans Head Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Genesis Sans Head Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Genesis Sans Head Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/Genesis+Sans+Head+Regular.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "GenesisSansText-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Genesis/GenesisSansText-Regular.woff")
    format("woff");
}
