$background-color: #fefcfc;
$border: 1px solid #e3d4d4;
$text-color: #080707 !important;

.isRequiredIndicator:before {
  content: " \25CF";
  font-size: 15px;
  color: #ffdd00;
  margin-right: 0.5em;
}

.notRequiredIndicator:before {
  content: " \25CF";
  font-size: 15px;
  color: #91ce73;
  margin-right: 0.5em;
}

.variableCollapseHeader {
  background-color: #fefcfc;
  color: #080707;
  padding-left: 1em;
}

.collapseContainer {
  .anticon.anticon-right.ant-collapse-arrow {
    color: $text-color;
  }
  
  :global {
    .ant-collapse-header {
      background-color: $background-color !important;
      color: $text-color !important;
    }

    div.ant-collapse-content {
      > div.ant-collapse-content-box {
        padding: 1.5em 5em;
        white-space: nowrap;
        font-size: 10px;
        overflow: auto;
      }
    }
  }
}

ul.reqVariableUl {
  padding-inline-start: 20px !important;
  list-style: none;
  li {
    list-style: none;
    .notRequiredIndicator:before {
      font-size: 8px;
    }
    .isRequiredIndicator:before {
      font-size: 8px;
    }
  }
}
