.layerName {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 2em;
}

.label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.edit {
  width: 5em;
}

.editPen {
  margin-right: 5px;
  path {
    fill: #1890ff;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.gray {
  color: #7f888b;
}
