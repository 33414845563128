@font-face {
  font-family: "CircularXX-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Black.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-BlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Book.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-BookItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-BookItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-ExtraBlack";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-ExtraBlack.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-ExtraBlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-ExtraBlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Light.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
      format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "CircularXX-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
        format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/CircularXX/CircularXX-ThinItalic.woff")
    format("woff");
}
