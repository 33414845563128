.drawer {
  // Marketing Material's drawer has z-index equal to 1001
  z-index: 1002;
  display: flex;

  .container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
  }
  .preview {
    display: flex;
    flex: 2;
    height: 100%;
  }

  .tabs {
    display: flex;
    flex: 1;
    overflow: scroll;
  }

  :global .ant-tabs-content-holder {
    overflow: auto;
  }
  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.formContainer {
  display: flex;
  flex: 1;
  height: 100%;
}
