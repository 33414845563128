.header {
  display: flex;
  padding: 10px 10px 0 10px;
  margin-bottom: 12px;
  .logo {
    display: flex;
    padding-right: 8px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      vertical-align: top;
    }
  }
  .page {
    display: flex;
    align-self: center;

    .pageName {
      display: flex;
      flex-direction: column;
      width: 100%;
      .name {
        align-self: center;
        min-width: 195px;
        font-size: 14px;
        font-weight: 700;
      }
      .sponsored {
        font-size: 12px;
        color: #90949c;
        font-weight: 400;
      }
    }
    .pageButtons {
      display: flex;
      justify-content: flex-end;

      .dots {
        width: 24px;
        height: 24px;
        background-position: -73px -49px;
        background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yp/r/JBLEjF5SSbU.png);
        background-size: 500px 122px;
        background-repeat: no-repeat;
        display: inline-block;
        align-self: center;
      }

      .close {
        width: 20px;
        height: 20px;
        background-position: -161px -49px;
        background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yp/r/JBLEjF5SSbU.png);
        background-size: 500px 122px;
        background-repeat: no-repeat;
        display: inline-block;
        align-self: center;
        margin-left: 16px;
      }
    }
  }
}
