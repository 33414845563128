.featureCard {
  display: flex;
  padding: 2em min(3.25em, 2.5vw);
  align-items: center;
  align-content: center;
  border-radius: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f9ff;
  width: 100%;
  height: 100%;

  .ant-card-body {
    width: 100%;
  }
}

.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color, #1890ff);
  border-radius: 50%;
  color: white;
  font-size: 2em;
  padding: 0.5em;
}

.featureCardContent {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 1em;
  width: 100%;
}

.featureCardText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
