@font-face {
  font-family: "HyundaiSansHead-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansHead-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Light.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansHead-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansHead-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansHead-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HyundaiSansText-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Hyundai/HyundaiSansText-Regular.woff")
    format("woff");
}
