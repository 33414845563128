.host {
  border: 1px dashed gray;
  height: 80px;
  background: lightgray;

  img {
    display: block;
    max-width: 30%;
    max-height: 80px;
  }
}
