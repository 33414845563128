@import "../styles/themes/variables/ladtech.scss";

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 48px;
  padding: 0 1em;

  background-color: transparent;

  .ant-steps-item-content {
    display: flex;
  }

  .ant-steps-item-title {
    color: $mainNav !important;

    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &::after {
      background-color: #000 !important;
    }
  }

  .active .ant-steps-item-title {
    font-weight: bold;
  }

  /* steps container */
  div.header-steps-container {
    flex: 6;

    div.ant-steps-item {
      /* this centering the Step dom element in the center */
      margin: 0 auto;
    }

    .middle-steps div.ant-steps-item-title {
      font-size: 0.7rem;
    }
    .active div.ant-steps-item-title {
      font-weight: bold;
    }

    .enabled .ant-steps-item-title:hover {
      cursor: pointer;
      font-weight: bold;
    }

    .disabled div.ant-steps-item-title {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  div.action-buttons-container {
    height: 100%;
    display: flex;
    align-items: center;

    .action-save-button {
      flex: initial;
      height: 60%;
      margin: 0 1em;
      background-color: transparent;
      border: none;

      cursor: pointer;

      > .anticon {
        font-size: 32px;
        color: $mainNav;
      }
    }

    .action-button {
      flex: initial;
      min-width: 6em;
      height: 60%;
      text-transform: uppercase;
      color: white;
      border: none;
      margin-left: 4px;
    }

    .new-instante-experience-button {
      background-color: #1890ff !important;
    }

    span {
      > button {
        flex: initial;
        width: 6em;
        height: 60%;
        margin: 0 1em;

        right: 1em;
        text-transform: uppercase;
        color: white;
        border: none;
      }
    }

    .action-button-popover {
      height: 60%;
      button {
        height: 100%;
      }
    }
  }
}
