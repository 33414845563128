@font-face {
  font-family: "ProximaNova";
  src: url("../../statics/fonts/ProximaNova-Regular.otf") format("opentype");
}

@import "./oemFonts/acura.scss";
@import "./oemFonts/apexnew.scss";
@import "./oemFonts/arial.scss";
@import "./oemFonts/audi.scss";
@import "./oemFonts/avenir.scss";
@import "./oemFonts/bmw.scss";
@import "./oemFonts/cadillac.scss";
@import "./oemFonts/chevrolet.scss";
@import "./oemFonts/biohaven.scss";
@import "./oemFonts/din_ot.scss";
@import "./oemFonts/frutiger.scss";
@import "./oemFonts/ford.scss";
@import "./oemFonts/futura.scss";
@import "./oemFonts/genesis.scss";
@import "./oemFonts/gill sans.scss";
@import "./oemFonts/gotham.scss";
@import "./oemFonts/helvetica.scss";
@import "./oemFonts/helveticaneue.scss";
@import "./oemFonts/honda.scss";
@import "./oemFonts/hyundai.scss";
@import "./oemFonts/infiniti.scss";
@import "./oemFonts/jaguar.scss";
@import "./oemFonts/jaguar_landrover_dual_branding.scss";
@import "./oemFonts/kia.scss";
@import "./oemFonts/land_rover.scss";
@import "./oemFonts/liberator.scss";
@import "./oemFonts/lincoln.scss";
@import "./oemFonts/maserati.scss";
@import "./oemFonts/mazda.scss";
@import "./oemFonts/mercedes-benz.scss";
@import "./oemFonts/mini.scss";
@import "./oemFonts/muli.scss";
@import "./oemFonts/nissan.scss";
@import "./oemFonts/nobel.scss";
@import "./oemFonts/novecentosanswide.scss";
@import "./oemFonts/porsche.scss";
@import "./oemFonts/roboto.scss";
@import "./oemFonts/stratum.scss";
@import "./oemFonts/tradegothicltstd.scss";
@import "./oemFonts/univers.scss";
@import "./oemFonts/toyota.scss";
@import "./oemFonts/volkswagen.scss";
@import "./oemFonts/volvo.scss";
@import "./oemFonts/foco.scss";
@import "./oemFonts/misc.scss";
@import "./oemFonts/circularxx.scss";
@import "./oemFonts/mitsubishi.scss";
