@font-face {
  font-family: "Univers_47_CondensedLight_Bold_Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_47_CondensedLight_Bold_Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_47_CondensedLight_Bold_Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Univers_55";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55.woff")
    format("woff");
}
@font-face {
  font-family: "Univers_55_Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55_Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55_Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Univers_55_Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55_Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Univers/Univers_55_Italic.woff")
    format("woff");
}
