.clickableCell {
  cursor: pointer;
  transition: 0.2 opacity;

  &:hover {
    opacity: 0.7;
  }
}

.input {
  width: 100%;
}
