.tabsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.tabs :global {
  width: 100%;

  .ant-tabs-tab {
    padding: 12px 0;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
}
