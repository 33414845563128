// modify padding and remove scrollbar for drawer content when on Load Step
.adLoadDrawer.loadStep > div > div > div > div:nth-child(2) {
  padding-top: 0px;
  padding-right: 3px;
  padding-left: 0px;
}

.adLoadDrawerContent {
  height: 100%;
  display: flex;
  align-items: flex-start;
}
