.FillControl {
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #4a4949;
  padding: 15px 15px 15px 25px; // antd collapse header padding

  > .DeleteIcon {
    color: white;
  }
}
