.container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.icon {
  font-size: 24px;
  color: #1f1f1f;
  background-color: #ffffff;
  opacity: 0.8;
  border-radius: 50%;
}
