.drawerContent {
  width: 100%;
  padding: 24px;
  padding-top: 16px;
}

.pmaxError {
  background-color: #ffccc7 !important;
}

.errorInfoCell {
  min-width: 40px !important;

  & > div {
    display: flex;
    justify-content: center;
    color: #ff4d4f;
  }
}

.alertMessage {
  padding: 5px 0;
}

.fullWidth {
  width: 100%;
}
