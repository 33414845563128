.emailContainer {
  display: flex;
  flex-direction: column;
}

.destinationEmail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.fileTitle {
  margin-bottom: 12px;
  margin-top: 12px;
}

.fileItem {
  margin-bottom: 8px;
}

.zipFile,
.pdfFile,
.csvFile {
  display: flex;
  align-items: center;
}

.pdfFile,
.csvFile {
  margin-left: 16px;
}
