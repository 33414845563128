.tableFormItem {
  margin-bottom: 0;

  :global {
    .ant-form-item-explain,
    .ant-form-item-extra {
      min-height: 0;
    }
  }
}
