@font-face {
  font-family: "Nissan Brand Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Nissan Brand Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nissan Brand Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Nissan Brand Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nissan/Nissan+Brand+Regular.woff")
    format("woff");
}
