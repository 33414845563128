.fromTemplate {
  display: flex;
}

.fromTemplateText {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
  font-size: 14px;
  white-space: nowrap;
}
