.container {
  display: flex;
  flex-direction: column;
  min-height: 60px;
}

.draggerWrapper {
  display: flex;
  flex-direction: column;

  .draggerLabel {

    span:first-of-type {
      margin-right: 4px;
      font-weight: bold;
    }
  }

  .draggerError {
    border: 1px solid #FF4D4F;
  }

  .draggerErrorMessage {
    margin-top: 4px;
    color: #FF4D4F;
  }
}
