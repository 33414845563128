.OfferTypeTags {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  .OfferTypeTag {
    padding: 0.3em 0.7em;
    font-size: 0.95em;
    margin: 0;
    color: #fff;
    background-color: #1890ff;

    &.Disabled {
      color: #000;
      background-color: #fff;
    }
  }
}

.Hide {
  > sup {
    display: none;
  }
}

.GoodScore {
  > sup {
    background: #52c31b;
  }
}
