.ImageWrapper {
  width: auto;
  height: 100%;

  position: relative;

  display: flex;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }
}
