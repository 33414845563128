.form,
.rowContainer {
  height: 100%;
  width: 928px;
}

.formItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  :global {
    .ant-col.ant-form-item-label
      label.ant-form-item-required.ant-form-item-no-colon {
      &::before {
        display: none;
      }

      &::after {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
      }
    }
  }
}

.leftColContainer {
  border-right: 1px solid #f0f0f0;
  padding: 24px;
}

.rightColContainer {
  padding: 24px;
  :global {
    div.ant-form-item {
      margin-bottom: 0;
    }
  }
}

.title {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: "Roboto Medium", sans-serif;
  line-height: 22px;
  padding-bottom: 8px;
}

.paragraph {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  padding-left: 16px;
}

.paddingContainer {
  padding-left: 16px;
}

.codeSnippetDescription {
  margin-bottom: 0 !important;
}

.textAreaContainer {
  width: 100%;
}
