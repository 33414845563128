.mediaPlaceholder {
  background: #f5f7f8;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.swipeIndicator {
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 8px;
  text-align: center;
}

.swipeToLoad {
  color: #91949c;
  font-size: 12px;
  line-height: 24px;
  margin-top: 4px;
}
