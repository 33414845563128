.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.searchInput {
  margin-bottom: 10px;
}

.container {
  background: #f5f5f5;
}

.searchInputContainer {
  padding: 1em;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 10px;
  padding: 10px;
  .cardContainer {
    cursor: pointer;
  }
}
