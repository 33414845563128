@import "./shared/styles/fonts.scss";

body {
  margin: 0;
  font-family: "ProximaNova", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  > iframe {
    display: none;
  }
}

* {
  font-family: "ProximaNova", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.alexia-border {
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.alexia-sidebar-avatar {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;

  display: block;

  transition: margin-left 3s;
}
.alexia-sidebar-avatar.profile {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  display: block;
  transition: margin-left 3s;
}

.alexia-sidebar-avatar.expanded {
  margin-left: 0;
  display: inline-block;
  transition: margin-left 3s;
  vertical-align: middle;
}

.alexia-sidebar-avata-text {
  color: white;
  line-height: 3em;
  margin-left: 0.5em;
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-block;
  text-transform: uppercase;
  vertical-align: middle;
  opacity: 0;
}

/* ***************************** */
/* Asset Builder */

/* OEM/Store Management */
.data-management-table .ant-table-tbody {
  background-color: #ffffff;
}

/* User Management */

.user-management-top-header-column {
  height: 100%;
  display: flex;
  align-items: center;
}

.user-management-profile-buttons {
  font-size: 12px;
  color: #ffffff;
  background-color: #000000;
}

/* Image Uploader Component */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 100px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
.ant-upload-list-item-actions .anticon-eye-o {
  display: none;
}

.ad-carousel-card-upload {
  margin: 10px 0;
  > .ant-upload.ant-upload-btn {
    padding: 0;
  }
}

/* antd v4 global adjustments */
.ant-spin-nested-loading {
  width: 100%;
}

.ant-tabs-tab {
  padding: 12px 16px;
}

.ant-table-column-sorters {
  width: max-content;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom-color: rgb(232, 232, 232);
}

// Below css is added in this global style sheet because css module cannot apply below styles for ant desing Spin component.
.canvas-spinner-wrapper {
  .ant-spin-blur {
    opacity: 0 !important;
  }
}

.ant-form-item-has-error
  .ant-col
  .ant-select.noErrorStyles
  .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.ant-form-item .customField {
  transition: all 0.2s;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}

.ant-form-item.ant-form-item-has-error .customField {
  border: 1px solid #ff4d4f;
}

.ant-form-item .customFieldHasError > .ant-upload {
  transition: all 0.2s;
  border: 1px dashed #ff4d4f;
}

.text-dimmed {
  color: rgba(0, 0, 0, 0.5);
}

// Required for Polotno's Editor to work nicely with Antd
// Antd drawer's z-index is 1000
.bp5-popover-transition-container {
  z-index: 1001 !important;
}
