$background-color: #ffffff;
$default-text-color: rgba(0, 0, 0, 0.65);
$border-color: #727272;

div.ant-select-dropdown {
  ul.ant-select-dropdown-menu {
    > li {
      color: $default-text-color !important;
    }

    > li.ant-select-dropdown-menu-item-selected,
    > li:hover {
      background: #e6f7ff !important;
    }
  }
}

.ant-select-dropdown li.ant-select-dropdown-menu-item-active {
  background: #e6f7ff !important;
}

div.form-drawer {
  .form-drawer-header-text {
    margin: 0;
    letter-spacing: -0.06px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
  }
  div.ant-select-selection {
    background: $background-color;
    border: 1px solid $border-color;
    color: $default-text-color;

    .ant-select-arrow {
      i {
        color: $default-text-color;
      }
    }
  }

  .ant-select-dropdown {
    background: $background-color;
    .ant-empty-description {
      color: white;
    }
  }

  .ant-drawer-body {
    height: 78%;
  }
}

.form-drawer-button-ctn {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;

  button {
    margin: 0 0.5rem;
  }

  .drawer-delete-button {
    span {
      font-weight: 900;
    }
  }
  .drawer-deactivate-button {
    span {
      font-weight: 900;
    }
  }

  .drawer-cancel-button {
    span {
      font-weight: 900;
    }
  }

  .drawer-submit-button {
    border: none;
    color: white !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    width: 30%;
    span {
      font-weight: 500;
    }
  }
}
