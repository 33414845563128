.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: bolder;
    margin: 5px;
  }

  .button {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
