.container {
  background-color: #f5f5f5;
  min-width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  position: relative;

  .preview {
    height: 100%;
    width: 850px;
    background-color: white;
  }
}
