.infoContainer {
  display: flex;
  width: 100%;
  padding: 1em 0;
  .title {
    font-weight: bold;
    width: 30%;
  }
  .value {
    width: 70%;
  }
}

.footer {
  display: flex;
  justify-content: space-around;
}
