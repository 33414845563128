$header-background-color: #4d4c4c;
$modal-body-background-color: #2d2f30;
$hover-background: rgba(0, 121, 255, 1);

div.preview-popover-content {
  max-height: 30em;
  overflow: scroll;

  div.ant-card {
    border: none;
    &:hover {
      background: #eeeeee;
    }
  }
  div.ant-card-body {
    padding: 0.3em;
    margin: 0.3em;
  }
}

div.ant-modal.template-preview {
  width: 80% !important;
  height: 90%;

  > div.ant-modal-content {
    height: 100%;

    display: flex;
    flex-direction: column;

    > button.ant-modal-close {
      color: white;
    }

    > div.ant-modal-header {
      flex: initial;
      width: 100%;
      height: 4em;

      background: $header-background-color;
      border: 0;

      > div.ant-modal-title {
        color: white;
      }
    }

    > div.ant-modal-body {
      flex: initial;
      height: 100%;
      display: flex;
      flex-direction: column;

      background: $modal-body-background-color;
      padding: 0;

      > div.preview-nav-bar {
        width: 100%;
        flex: initial;
        min-height: 6em;
        max-height: 8em;

        padding: 0.5em 0.5em;
        color: white;

        > div.preview-field {
          flex: 1;
        }

        > div.offer-types-container {
          flex: 1;

          > div.field {
            > .ant-select {
              min-width: 15em;
            }
          }
        }

        > div.asset-placement-container {
          align-self: center;

          > div.field {
            text-align: center;
          }
        }
      }

      > div.preview-template-container {
        width: 100%;
        flex: 1;
        overflow: scroll;
        background: black;

        padding: 2em;

        position: relative;
        > .ant-spin-nested-loading {
          height: 100%;

          > .ant-spin-container {
            height: 100%;

            > div.canvas-wrapper {
              width: 100%;
              height: 100%;

              box-sizing: border-box;

              position: relative;

              > .canvas-container {
                border: 1px solid gray;
                border-radius: 3px;

                position: absolute !important;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }

    > div.ant-modal-footer {
      display: none;
    }
  }

  .light {
    background: white;
  }
  .dark {
    background: black;
  }
}

// This part overwrite the ant-select-dropdown defined in PropertyRow.scss
.ant-select-dropdown {
  li.ant-select-dropdown-menu-item-active {
    background: $hover-background;
  }
}
