.host {
  padding-top: 2px;

  &[data-open="true"] {
    display: grid;
    grid-template-columns: 300px auto;
    gap: 20px;

    > div {
      &:first-child {
        display: initial;
      }
    }
  }

  > div {
    &:first-child {
      display: none;
    }
  }
}
