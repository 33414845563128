@font-face {
  font-family: "Stratum1-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Black.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum1-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum1-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum1-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum1-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum1-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum1-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Black.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Stratum2-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Stratum/Stratum2-Thin.woff")
    format("woff");
}
