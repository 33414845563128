$backgroundColor: #ffffff;
$cardShadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.75);

.cardItem {
  justify-content: center;
  align-items: center;
  background-color: $backgroundColor;
  border: 1px solid #d9d9d9;
  box-shadow: $cardShadow;
}

.cardItemTextOnly {
  text-align: center;
}

.cardBadge {
  border-radius: 0px 4px 4px 0px;
  padding: 0.05em 8px;
  margin-top: 6px;
  border-left: none;
}

.cardTag {
  border-radius: 4px 4px 4px 4px;
  margin: 0 4px 4px 4px;
  padding: 0px 8px;
  font-size: 12px;
  width: fit-content;
}
