.drawer {
  :global {
    .ant-drawer-body {
      padding: 0;
    }
    .ant-typography {
      margin: 10px 16px;
    }
    .ant-row {
      .ant-form-item-label {
        padding: 0;
      }
    }
    .ant-form-item {
      margin: 1.5em 1em;
      .ant-typography {
        margin: 0;
      }
    }
    .ant-form {
      display: flex;
      justify-content: space-between;

      .ant-divider {
        height: 100vh;
        margin: 0;
      }
    }
  }
}

.formColumn {
  width: 33.3%;
}

.divider {
  margin: 0;
}

.path {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.pathInput {
  width: 100%;
}

.error {
  border: 1px solid #ff0000;
}

.sectionError {
  margin: 0 1.25em 2.5em;
  color: #ff4d4f;
}

.section {
  margin: 1.5em 0;

  :global {
    .ant-form-item {
      margin: 1.5em 1em 0;
    }
  }
}

.videoInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: #f5f5f5;

  .videoFieldLabel {
    margin: 0;
  }

  .videoFormItem {
    margin: 0 0 1em 0;
  }
  .inputButtonContainer {
    display: flex;
  }
}

.videoInfoSection {
  margin: 1.5em 0;
  padding: 0 1em;
}
