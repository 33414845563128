div.offer-list-container {
  min-height: 30em;
  position: relative;

  .empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .offer-list {
    min-height: 20em;
  }
  .ant-select-arrow {
    display: none;
  }
}
