.collapseContainer {
  background-color: transparent !important;
  width: 100%;
  & :global {
    .ant-collapse-header {
      display: flex;
      align-items: center;
      background-color: white;
      border: 1px solid #f0f0f0;
      padding: 9px 16px !important;
      width: fit-content;
    }
    .ant-collapse-content-box {
      background-color: white;
      padding: 24px 24px 8px 24px !important;
    }
  }
}
