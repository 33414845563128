@font-face {
  font-family: "ApexNew-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-BoldItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Book.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-BookItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-BookItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-BookItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Heavy";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Heavy.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Heavy.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-HeavyItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-HeavyItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-HeavyItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Light.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-LightItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Medium.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-MediumItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Thin.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-ThinItalic.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-Ultra";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Ultra.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-Ultra.woff")
    format("woff");
}

@font-face {
  font-family: "ApexNew-UltraItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-UltraItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/ApexNew/ApexNew-UltraItalic.woff")
    format("woff");
}
