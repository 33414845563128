@font-face {
  font-family: "CorpABol";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpABol.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpABol.woff")
    format("woff");
}
@font-face {
  font-family: "CorpABolIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpABolIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpABolIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpADem";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpADem.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpADem.woff")
    format("woff");
}
@font-face {
  font-family: "CorpADemIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpADemIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpADemIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpALig";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpALig.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpALig.woff")
    format("woff");
}
@font-face {
  font-family: "CorpALigIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpALigIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpALigIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpAReg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpAReg.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpAReg.woff")
    format("woff");
}
@font-face {
  font-family: "CorpARegIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpARegIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpARegIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSBol";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSBol.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSBol.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSBolIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSBolIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSBolIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSDem";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSDem.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSDem.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSDemIta";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSDemIta.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSDemIta.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSExtBol";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSExtBol.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSExtBol.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSLig";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSLig.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSLig.woff")
    format("woff");
}
@font-face {
  font-family: "CorpSReg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSReg.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mercedes-Benz/CorpSReg.woff")
    format("woff");
}
