.inlineForm {
  display: flex;
  align-items: baseline;
  gap: 10px;
  padding: 12px 0;

  .expValInput {
    width: 60px;
  }

  .expIntInput {
    width: 100px;
  }

  .passwordInput {
    width: 250px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
