.Control {
  width: 100%;
  height: auto;

  > .Header {
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    background: white;

    padding: 15px 15px 15px 25px; // antd collapse header padding
  }
}
