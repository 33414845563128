.sourceFeedContainer {
  padding-top: 1em;
  width: 100%;
}

.sourceFeedClickable {
  color: #1890ff;
  font-weight: 500;
  cursor: pointer;
}

.disabled {
  color: #bfbfbf;
}
