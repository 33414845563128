.searchTreeValue {
  color: #f50;
}
[data-theme="dark"] .searchTreeValue {
  color: #d84a1b;
}

.adLevelTreeLeaf:hover {
  cursor: default;
}
