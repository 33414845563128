.image-selection-uploader {
  width: 45em;
  height: auto;

  > .title {
    font-size: 1.1rem;
    font-weight: bold;
  }

  > .info-fields {
    display: flex;
    flex-direction: row;

    margin: 1em 0;

    > .field {
      flex: 1;
      font-weight: bold;

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-left: 0.5em;
      }

      &:not(:first-child):not(:last-child) {
        margin: 0 0.5em;
      }
    }
  }

  .uploaded-image-container {
    min-width: 10em;
    min-height: 10em;

    max-width: 15em;

    box-sizing: border-box;
    padding: 1em 0.5em;
    margin: 1em auto;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .button-container {
    display: flex;

    > * {
      flex: 1;
    }

    > button:nth-child(1) {
      margin-right: 0.5em;
    }
    > button:nth-child(2) {
      margin-left: 0.5em;
    }
  }
}
