@font-face {
  font-family: "IBMPlexSans-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Bold.otf")
        format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/IBMPlexSans-Light.woff")
    format("woff");
}
@font-face {
  font-family: "EBGaramond-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-BoldItalic.otf")
        format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/EBGaramond-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "OpenSans-Regular";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/OpenSans/OpenSans-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/OpenSans/OpenSans-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/OpenSans/OpenSans-ExtraBold.woff")
    format("woff");
}

@font-face {
  font-family: "NotoSans-Black";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Black.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-BlackItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-Bold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-BoldItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-ExtraBold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-ExtraBold.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-ExtraBoldItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-ExtraBoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-ExtraLight";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-ExtraLight.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-ExtraLightItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-ExtraLightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-Italic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-Light";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Light.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-LightItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-Medium";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-MediumItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-Regular";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-SemiBold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-SemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "NotoSans-SemiBoldItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-SemiBoldItalic.woff")
    format("woff");
}

@font-face {
  font-family: "NotoSans-Thin";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-Thin.woff")
    format("woff");
}

@font-face {
  font-family: "NotoSans-ThinItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/NotoSans/NotoSans-ThinItalic.woff")
    format("woff");
}

@font-face {
  font-family: "HarmoniaSansPro-LightItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-Light";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Light.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-Italic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-Cond";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Cond.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-BoldItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-BoldCond";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-BoldCond.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-Bold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-BlackItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-SemiBdCond";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-SemiBdCond.woff")
    format("woff");
}

@font-face {
  font-family: "HarmoniaSansPro-Black";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Black.woff")
    format("woff");
}

@font-face {
  font-family: "HarmoniaSansMonoStd-Regular";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansMonoStd-Regular.woff")
    format("woff");
}

@font-face {
  font-family: "HarmoniaSansMonoStd-Italic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansMonoStd-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansMonoStd-BoldIt";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansMonoStd-BoldIt.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansMonoStd-Bold";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansMonoStd-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "HarmoniaSansPro-SemiBd";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-SemiBd.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-SemiBdItalic";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-SemiBdItalic.woff")
    format("woff");
}
@font-face {
  font-family: "HarmoniaSansPro-Regular";
  src: url("https://alexia-assets-prd.s3.amazonaws.com/fonts/HarmoniaSans/HarmoniaSansPro-Regular.woff")
    format("woff");
}
