.drawerContent {
  width: 100%;
  padding: 24px;
  padding-top: 16px;
}

.titleRow {
  width: 100%;
  justify-content: space-between;
}

.failedIcon {
  color: rgba(255, 77, 79, 1);
}

.loadedIcon {
  color: #52c41a;
}

// Antd override
.collapseContent {
  :global(.ant-collapse-content-box) {
    padding: 0;
  }
}

.innerRow {
  background-color: #fafafa;
}

.loadDrawerActionsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.activeFilter {
  border-color: #40a9ff;
}
