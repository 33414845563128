.popoverItem {
  margin-top: 10px;
}

.appVersion {
  font-size: 75%;
  font-weight: 100;
  margin-top: 2.75em;
  color: rgba(0, 0, 0, 0.25);
}

.popoverContainer {
  min-width: 250px;

  .menuList {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .menuItem {
      width: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }
}

.popoverTitle {
  h4 {
    margin: 0;
  }
}
