.hamburger {
  background: none;
  border: none;
  width: 52px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.menuIcon {
  top: 50%;
  left: 50%;
  color: #313d44;
  font-size: 1.4rem;
  position: absolute;
  transform: translate(-50%, -50%);
}
