.image-selection-list {
  > li {
    margin-top: 10px;
    border: 2px solid transparent;

    &:hover,
    &.selected {
      cursor: pointer;
      border-color: #40a9ff;
    }
  }

  > .image-selection-row {
    > .image-selection-thumbnail-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
