.bodyRow {
  height: 100%;
}

.bodyLeft {
  height: 100%;
}

.bodyRight {
  height: 100%;
  background-color: #f5f5f5;
}

.nameContainer {
  padding-right: 18px;
}
