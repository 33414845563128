.headerContainer {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.mediaDurationInput {
  width: 60px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.1em;
  margin-left: auto;
}

.message {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}

.durationText {
  margin-left: 1.5em;
}

.durationTextSec {
  margin-left: 0.3em;
  color: rgba(0, 0, 0, 0.45);
}
