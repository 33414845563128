.item {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;

  border: 1px solid transparent;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;

  &:hover {
    border: 1px solid black;
  }
  &.selected {
    background-color: #e8e8e8;
  }

  > .title {
    flex: 1;
  }
}
