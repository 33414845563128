.list {
  > div {
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  span {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
