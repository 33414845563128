.overlay {
  position: fixed;
  z-index: 1000;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.45);

  display: flex;
  justify-content: center;
  align-items: center;

  > .content {
    > img {
      max-width: calc(100vw - 48px);
      max-height: calc(100vh - 48px);
      background-size: contain;
    }
  }
}
