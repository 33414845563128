$legal-messaging-color: #e1ebf1;
$border: 1px solid #d5cac9;

.collapse-container {
  min-width: 20em;

  .ant-collapse-header {
    color: white !important;
  }

  div.ant-collapse-content {
    > div.ant-collapse-content-box {
      padding: 0.7em;
      position: relative;
    }
  }

  .light-panel .ant-collapse-header {
    background-color: white !important;
    color: black !important;
  }

  &.no-spacing {
    div.ant-collapse-content {
      > div.ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.collapse-container.design-studio {
  .ant-collapse-header {
    background-color: #4a4949;
    color: white;
  }

  div.ant-collapse-content {
    background-color: #2d2f30;
  }
}

.collapse-container.disclosure-collapse-container-v2 {
  .ant-collapse-header {
    background-color: white;
    color: black !important; // only affects the legal lingo v2 disclosure - only one with the classname
  }

  div.ant-collapse-content {
    background-color: white;
  }
}

.collapse-container {
  div.filter-by {
    width: 100%;
    border: $border;
    margin-bottom: 0.5em;

    > div.title {
      font-weight: bold;
      padding: 1em;
      border-bottom: $border;
    }

    > div.options {
      padding: 1em;
    }
  }
}
.collapse-container.offer-form {
  .ant-collapse-header {
    background-color: #4a4949;
    color: white;
  }

  div.ant-collapse-content {
    .ant-collapse-header {
      background-color: #e1ebf1;
      color: black;
    }
  }
}

.collapse-container {
  div.filter-by {
    width: 100%;
    border: $border;
    margin-bottom: 0.5em;

    > div.title {
      font-weight: bold;
      padding: 1em;
      border-bottom: $border;
    }

    > div.options {
      padding: 1em;
    }
  }
}

.offer-form-collapse {
  background-color: #e1ebf1;
  .ant-collapse-header {
    background-color: #004566;
    color: white;
    .anticon.anticon-right.ant-collapse-arrow {
      color: white;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
    }
  }
  div.ant-collapse-content {
    .ant-collapse-header {
      padding: 0;
      margin: 0;
      background-color: #e1ebf1;
      color: black;
    }
  }
}

.legal-messaging-pm-sm-collapse {
  .ant-collapse-header {
    background-color: $legal-messaging-color;
    color: black !important;
    height: 75px;
    display: flex;
    align-items: center;
    .ant-btn.ant-collapse-arrow.ant-btn-icon-only.ant-btn-background-ghost {
      color: black;
    }
  }

  div.ant-collapse-content {
    .ant-collapse-header {
      padding: 0;
      margin: 0;
      background-color: #e1ebf1;
      color: black;
    }
  }
}

.collapse-container.no-padding {
  div.ant-collapse-content {
    > div.ant-collapse-content-box {
      padding: 0;
    }
  }
}
