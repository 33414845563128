@font-face {
  font-family: "Nobel Black Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Black+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Black+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Black.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Bold Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Bold+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Bold+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Book Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Book+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Book+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Book";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Book.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Book.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Light Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Light+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Light+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Regular Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Regular+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Regular+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Nobel Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Nobel/Nobel+Regular.woff")
    format("woff");
}
