.container {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}

.playButton {
  font-size: 24px;
}

.playbackControls {
  background-color: #D9D9D9;
  height: 10px;
  width: 100%;
  position: relative;
  margin-top: 4px;
}

.stopButton {
  margin: 4px 0 0 3px;
  width: 24px;
  height: 24px;
  border: 2px solid #1890ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stopButtonBox {
  width: 50%;
  height: 50%;
  border: 2px solid #1890ff;
}
