.Opacity {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}
