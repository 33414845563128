@mixin imgBackground {
  object-fit: cover;
  background-color: hsl(0, 0%, 90%);
  background-image: linear-gradient(
      45deg,
      #efefef 25%,
      transparent 25%,
      transparent 75%,
      #efefef 75%,
      #efefef
    ),
    linear-gradient(
      45deg,
      #efefef 25%,
      transparent 25%,
      transparent 75%,
      #efefef 75%,
      #efefef
    );
  background-size: 21px 21px;
  background-position: 0 0, 10px 10px;
}
