.row {
  padding: 0.7em;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  gap: 8px;

  > * {
    flex: 1 !important;
  }

  &:hover {
    background-color: #e6f7ff;
  }

  .input {
    width: 100%;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
  }

  .inputError {
    width: 100%;
    border: 1px solid #ff0000;
    background-color: #ffffff;

    & :global {
      .ant-select-selector {
        border: none;
        border-right-width: 1px !important;
      }
    }
  }

  .errorDesc {
    color: #ff0000;
  }
}

.logoSelectName {
  padding-left: 10px;
}

.containerInput {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 12px;
}

.checkbox {
  margin-top: 6px;
  margin-left: 8px;
}

.playerTest {
  & > div {
    background-image: none !important;
    background-color: white;
    opacity: 1 !important;
  }

  div[style*="background-color: rgba(255, 255, 255, 0.25);"],
  div[style*="background-color: white;"],
  div[style*="background-color: rgb(255, 255, 255);"] {
    background-color: rgba(89, 89, 89, 1) !important;
  }

  button[aria-label*="sound"] svg path,
  button[aria-label*="video"] svg path {
    fill: rgba(0, 0, 0, 0.45);
  }

  input[aria-label="Change volume"] {
    background-color: rgba(0, 0, 0, 0.45) !important;
    background-image: none !important;
  }

  div[style*="color: white;"] {
    color: rgba(0, 0, 0, 0.45) !important;
  }
}

.audioRow {
  @extend .row;

  &:hover {
    background-color: inherit;
  }
}
