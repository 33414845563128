.Color {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;

  > .SelectedColor {
    width: 2em;
    height: 100%;
    background: purple;
    border-radius: 5px;
    border: 1px solid white;

    &:hover {
      cursor: pointer;
    }
  }
}
