$focused-button-background: #37aadc;

.collapse-container {
  &.design-studio-filter-section {
    div.filter-section-fields-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .publish-status-button-group {
        flex: 1;

        display: flex;

        > button {
          flex: 1;
        }

        > button:focus,
        > button.focused {
          background: $focused-button-background;
          color: white;
        }
      }

      div.clear-button-container {
        width: 100%;
        flex: initial;
        height: 2em;
        margin-top: 0.5em;
        position: relative;

        > a {
          position: absolute;

          right: 0.5em;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .filter-by {
    .ant-input {
      background-color: white;
    }

    div.options {
      > ul {
        list-style: none;
        > li {
          margin-top: 0.7em;
          margin-bottom: 0.7em;
        }
      }
    }

    div.ant-select {
      width: 100%;
    }

    input {
      color: black;
    }

    div > button {
      width: 100%;
    }
  }
}
