@font-face {
  font-family: "VWHead-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "VWHead-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "VWHead-ExtraBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-ExtraBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-ExtraBold.woff")
    format("woff");
}
@font-face {
  font-family: "VWHead-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Light.woff")
    format("woff");
}
@font-face {
  font-family: "VWHead-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "VWHead-RegularItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-RegularItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWHead-RegularItalic.woff")
    format("woff");
}
@font-face {
  font-family: "VWText-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "VWText-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "VWText-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Light.woff")
    format("woff");
}
@font-face {
  font-family: "VWText-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "VWText-RegularItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-RegularItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Volkswagen/VWText-RegularItalic.woff")
    format("woff");
}
