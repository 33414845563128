@font-face {
  font-family: "Helvetica-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Helvetica-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Helvetica-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Helvetica/Helvetica-Regular.woff")
    format("woff");
}
