.buttonPreview {
  width: 90%;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  * {
    font-family: inherit;
    margin: 0;
    padding: 0;
  }
}
