.asset {
  min-height: 200px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  width: 100%;
}

.playIcon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
