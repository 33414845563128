$border: 1px solid #727272;
$background-color: #454545;

.FontProperty {
  background: inherit;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 0.5em;

  > .FontSelect {
    flex: 1;

    > * {
      width: 100%;
    }
  }

  > .FontWeightStyleSize {
    flex: 1;
    display: flex;

    gap: 0.5em;

    > * {
      flex: 1;

      > * {
        width: 100%;
      }
    }

    > *:last-child {
      flex: initial;
      width: 4.5em;

      > * {
        width: 100%;
      }
    }

    > .FontSizeProperty {
      > .FontSizeInput {
        position: relative;

        > * {
          flex: 1;
        }

        > .Arrows {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1.2em;

          > .Wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            > * {
              flex: 1;
            }

            > .Up,
            .Down {
              display: flex;
              align-items: center;
              justify-content: center;

              user-select: none;
              cursor: pointer;

              &:hover {
                transform: scale(1.5);
              }
            }
          }
        }
      }
    }
  }

  > .FontColor {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1em;

    > * {
      width: 100%;
    }

    > .FontColorInputContainer {
      display: flex;
      flex-direction: row;

      gap: 0.5em;

      > *:nth-child(1) {
        flex: 1;
        width: 100%;

        background: $background-color;
        border: $border;
        color: white;
      }

      > *:nth-child(2) {
        display: inline-block;
        flex: initial;
        width: 4.5em;

        border-radius: 3px;
        box-sizing: border-box;
      }
    }

    > .ColorPicker {
      width: 100%;
      display: flex;

      &.Hide {
        display: none;
      }

      > * {
        flex: 1;
      }
    }
  }

  > .FontLineProperties {
    flex: 1;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      flex: initial;
      width: 30%;
      text-align: center;
      color: white;
      font-size: 0.7rem;
      font-weight: bold;

      > *:not(.Label) {
        color: white;
        background: $background-color;
        border: $border;
      }

      > *[disabled] {
        opacity: 0.5;
      }

      > .Label {
        font-size: 0.9rem;
      }
    }
  }
}
