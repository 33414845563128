.generateUrlForm {
  display: flex;
  flex: 1;
  width: 100%;
  column-gap: 10px;
  align-items: flex-end;

}

.generateUrlFormItem {
  flex: 1;
}

.generateUrlFormSlugItem {
  flex: 2;
}

.generateUrlFormButton {
  min-width: 120px;
}
