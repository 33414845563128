.tabContainer {
  height: 100%;
  :global {
    div.ant-tabs-content {
      height: 100%;
    }
    div.ant-tabs-nav {
      width: 200px;
    }
  }
}

.tabPane {
  height: 100%;
}
