.video-control {
  position: absolute;
  pointer-events: none;
  background: black;
  opacity: 0.4;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 0;
  > svg {
    transform: scale(3.5);
    z-index: 50;
    pointer-events: all;

    &:hover {
      cursor: pointer;
      transform: scale(4);
    }
  }
}
