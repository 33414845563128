.log {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
}
