.loader {
  width: 100%;
  height: 15em;

  position: relative;

  .spinner {
    position: absolute;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
}

.templateListCollapse {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
}

.container {
  background-color: #eeeeee;
  padding-top: 1em;
}
