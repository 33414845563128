.show-hide-highlight-toolbar {
  position: sticky;
  top: -16px;
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 0.2rem 1rem;
  min-height: 4rem;
  align-items: center;
  font-size: 14px;
  box-shadow: 0px 5px 3px -2px rgba(black, 0.1);
  margin-top: -0.5rem;
  z-index: 10;

  .ctn {
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 2rem;
    }

    .show-hide-highlight {
      margin-right: 0;
    }

    span {
      margin-right: 0.5rem;

      .anticon-exclamation-circle {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }

    .logo-items {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .ctn:nth-child(n + 2) {
    padding-left: 1rem;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
  }

  //Title & ToolTip
  .title-tooltip {
    display: flex;
    align-items: center;
    font-weight: 600;

    > span {
      margin-left: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
