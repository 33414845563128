.new-destination {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.loading-spinner {
  width: 100%;
  margin-top: 2em;
}
