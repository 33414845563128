.description {
  .list {
    margin-left: 48px;
    list-style: none;
    padding: 0;

    .item {
      display: flex;
      justify-content: space-between;
    }

    .errorItem {
      margin-top: 0.5rem;
    }

    .errorText {
      color: #ff4d4f;
    }
  }
}

.description :global {
  .ant-notification-notice-description {
    margin-left: 0;
  }
}
