.container {
  width: 190px;
  margin: auto;
  margin-bottom: 12px;
  .label {
    text-align: right;
    span {
      margin-right: 8px;
    }
  }
  .switch {
    text-align: left;
  }
  .divider {
    text-align: center;
  }
}
