.leftPanelTabs {
  width: 100%;
  height: 100%;

  // Below is needed for child components to have proper height value.
  > *:nth-child(2) {
    height: 100%;

    > *:nth-child(1) {
      height: 100%;
    }
  }
}
