@font-face {
  font-family: "Gill Sans Bold Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Bold+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Bold+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Heavy Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Heavy+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Heavy+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Heavy";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Heavy.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Heavy.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Light Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Light+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Light+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Light.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Medium Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Medium+Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Medium+Italic.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans+Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Gill Sans";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/Gill+Sans.woff")
    format("woff");
}
@font-face {
  font-family: "GillSans Condensed Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/GillSans+Condensed+Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/GillSans+Condensed+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "GillSans Condensed";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/GillSans+Condensed.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Gill Sans/GillSans+Condensed.woff")
    format("woff");
}
