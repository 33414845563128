.form,
.rowContainer {
  height: 100%;
  width: 928px;
}

.formItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.leftColContainer {
  padding: 24px;
}

.title {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: "Roboto Medium", sans-serif;
  line-height: 22px;
}

.paddingContainer {
  padding-left: 16px;
  padding-top: 8px;
}
