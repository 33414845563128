@font-face {
  font-family: "InfinitiBrand-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Bold.woff")
    format("woff");
}

@font-face {
  font-family: "InfinitiBrand-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Light.woff")
    format("woff");
}

@font-face {
  font-family: "InfinitiBrand-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrand-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandCY-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandCY-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Light.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandCY-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandCY-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandL1-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandL1-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Light.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandL1-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandL1-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandLP-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandLP-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Light.woff")
    format("woff");
}
@font-face {
  font-family: "InfinitiBrandLP-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Infiniti/InfinitiBrandLP-Regular.woff")
    format("woff");
}
