.template-selection {
  width: 100%;
  height: 100%;

  > input.template-search-by-input {
    background: transparent;
    border: inherit;
  }

  > .template-type-selection-tabs {
    height: 3em;
    line-height: 3em;
    display: flex;
    flex-direction: row;

    > .template-type-tab {
      flex: 1;
      text-align: center;

      &.selected {
        cursor: default;
        background: white;
      }

      &:not(.selected) {
        cursor: pointer;
        background: lightgray;

        &:hover {
          filter: brightness(0.95);
        }
      }
    }
  }

  > .content {
    > ul.template-list {
      width: 100%;
      padding: 0;

      list-style: none;

      > li.template-list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;

        .template-thumbnail-container {
          position: relative;
          width: 100%;
          height: 250px;
          padding: 5px;
          box-sizing: border-box;
          border: 2px solid transparent;

          &:hover,
          &.selected {
            cursor: pointer;
            border-color: #40a9ff;
          }

          > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          > button {
            position: absolute;
            bottom: 0;
            right: 0;
          }

          > .template-name {
            margin: 0.7em 0;
          }
        }
      }
    }
  }
}
