.cta {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #f7f8fa;
  min-height: 40px;

  .ctaValue {
    pointer-events: none;
    width: 100%;
    max-height: 60px;
    overflow: hidden;

    .ctaLinkCaption {
      line-height: 16px;
      width: 100%;
      text-transform: lowercase;
      font-size: 13px;
      color: #464950;

      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: nowrap;
    }

    .ctaTitle {
      color: #1c2028;
      word-wrap: break-word;
      pointer-events: none;
      line-height: 20px;
      max-height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      font-size: 15px;
      font-weight: 700;
    }

    .ctaDescription {
      color: #464950;

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 20px;
      font-size: 15px;
    }
  }

  .ctaBtn {
    margin-left: 12px;
    padding: 0 12px;
    background-color: #e4e6eb;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont,
      ".SFNSText-Regular", sans-serif;
    border-radius: 6px;
    color: rgb(5, 5, 5);
    font-weight: 600;
    font-size: 15px;
    white-space: nowrap;
  }
}
