.container {
  padding-top: 24px;
  padding-right: 24px;

  display: flex;
  flex-direction: column;
  gap: 1em;

  .uploadDraggerContainer {
    flex: initial;
  }

  .fieldsContainer {
    flex: 1;
  }
  .expirationDateContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 1em;
  }
  .datePicker {
    width: 100%;
  }
}
