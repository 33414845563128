.emptyPreview {
  position: relative;
  top: 33%;
}

.cardHeaderDiv {
  padding: 8px;
  display: flex;
  align-items: center;
}

.rightFlexSpace {
  order: 2;
  margin-left: auto;
}

.titleContainer {
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 0 1em 1em 1em;

  .title {
    font-size: 1rem;
    width: 100%;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .adFormat {
      margin-bottom: 0px;
    }
  }
}

.titleContainerWithoutHeader {
  padding-top: 39px;
}

.status {
  padding-right: 40px;
  right: 0;
  position: absolute;
}

.comments {
  padding-right: 8px;
  right: 0;
  position: absolute;

  .commentsButton {
    height: fit-content;
    padding: 0.5rem;
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
  }
}
