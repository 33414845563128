div.tab-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  // filter section
  div.filter-section {
    position: relative;
    .shrink-tab-btn {
      margin-right: 1.5em;
      color: rgba(0, 0, 0, 0.65);
      &:hover {
        color: #40a9ff;
      }
    }
    .ant-tabs-tab {
      margin: 0;
    }
    .collapse-container {
      .ant-collapse-header {
        padding-left: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
    > div.filter-section-tabs {
      .order-details-tab {
        padding-left: 2em;
        color: #000;
      }
      .ant-tabs-nav-operations {
        display: none;
      }
      // below styles needed for side panel sticky
      height: 100%;
      overflow: visible !important;

      > .ant-tabs-content-holder div.ant-tabs-content {
        height: 100%;

        div.ant-tabs-tabpane.ant-tabs-tabpane-active {
          max-height: calc(100vh - 94px);
          position: sticky;
          top: 1em;
        }
      }

      .anticon-filter {
        margin-right: 0px;
      }
    }

    div.ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
      position: relative;
    }

    div.ant-tabs-nav .ant-tabs-nav-list div:nth-child(2) {
      position: absolute;
      right: 0;
    }

    .ant-tabs-content {
      padding: 0 1em 0 0;

      > .tabpanel {
        width: auto;
      }
    }
  }

  div.tab-container-search-button-container {
    display: flex;

    > button {
      flex: initial;
      margin: 0 0.5em;
      border: 1px solid #d9d9d9;
      color: #676b6c;
      border-radius: 0;
      height: 2.4em;
      line-height: 2.4em;
      padding-top: 0;
      padding-bottom: 0;
    }

    span.search-container {
      height: 2.4em;
      flex: initial;
      display: flex;

      > span:nth-child(1) {
        display: flex;
        justify-content: flex-end;
        height: 2.4em;
        line-height: 2.4em;

        > span.ant-input-suffix {
          > .anticon {
            margin: 0;
          }
        }

        > input {
          border-radius: 0;
          background-color: transparent;
          color: #676b6c;
        }
      }

      span.search-plus-button {
        flex: initial;

        > button {
          height: 100%;
          background: transparent;
          border-radius: 0;

          > .anticon {
            margin: 0;
            color: #7e8589;
          }
        }
      }
    }
  }

  // content section
  div.tab-container-content-section {
    flex: 3 1 80%;
    padding: 0 0 7em 0;
    margin-left: 0em;
    max-width: 100%;
    overflow: hidden;

    .tab-container-content-tabs {
      div.ant-collapse-content-box {
        overflow: auto;
      }

      div.ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
        position: relative;

        .ant-tabs-tab:nth-last-of-type(2) {
          div.tab-container-search-button-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: fixed;
            right: 0;
            transform: translateY(-50%);
            top: 50%;

            > button {
              flex: initial;
              margin: 0 0.5em;
              border: 1px solid #d9d9d9;
              color: #676b6c;
              border-radius: 0;
              height: 2.4em;
              line-height: 2.4em;
              padding-top: 0;
              padding-bottom: 0;
            }

            span.search-container {
              height: 2.4em;
              flex: initial;
              display: flex;

              > span:nth-child(1) {
                display: flex;
                justify-content: flex-end;
                height: 2.4em;
                line-height: 2.4em;

                > span.ant-input-suffix {
                  > .anticon {
                    margin: 0;
                  }
                }

                > input {
                  border-radius: 0;
                  background-color: transparent;
                  color: #676b6c;
                }
              }

              span.search-plus-button {
                flex: initial;

                > button {
                  height: 100%;
                  background: transparent;
                  border-radius: 0;

                  > .anticon {
                    margin: 0;
                    color: #7e8589;
                  }
                }
              }
            }
          }
        }
      }
    }
    div.ant-tabs-nav .ant-tabs-nav-list div:first-child div:last-child:hover {
      cursor: default;
    }
  }
}

.tab-container-overflow-inherit div.tab-container {
  div.ant-collapse-content-box {
    overflow: inherit;
  }
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
}
