.modal {
  max-width: 800px;
  width: auto;
}

.container {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.checkboxGroup {
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.selectAll {
  margin-left: 5px;
}

.disclaimer {
  margin: 0 10px 10px;
}

.divider {
  height: 2em;
}
