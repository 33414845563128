.summary {
  padding-top: 1em;
  .config-name {
    font-weight: bold;
    padding-left: 1em;
    .feed-select-btn {
      margin-right: 5px;
    }
  }
  .right {
    padding-right: 1em;
  }
  .processButton {
    background-color: #003a8c;
    color: #fff;
    margin-right: 5px;
  }
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  color: #fff;
  .left {
    display: flex;
    align-items: center;
    .configName {
      font-weight: bold;
      padding-left: 1em;
      .feedSelectButton {
        margin-right: 5px;
      }
    }
    .right {
      padding-right: 1em;
    }
  }

  .search {
    flex-grow: 1;
    display: flex;
  }

  .searchSpin {
    max-height: 80%;

    .ant-spin-dot {
      width: 10px;
      height: 10px;
      background-color: cyan;
    }
  }

  .right {
    display: flex;
    align-items: center;
    .processButton {
      background-color: #003a8c;
      color: #fff;
    }
  }
}
