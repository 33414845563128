$hover-background: rgba(0, 121, 255, 1);

div.logo-drop-zone-container {
  width: 90%;
  height: 100%;

  margin: 0 auto;

  display: flex;
  flex-direction: column;

  > div.shape-inner-container {
    flex: 1;
    position: relative;
  }

  > div.title {
    flex: initial;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: white;
  }
}

div.logo-drop-zone-container:hover {
  cursor: pointer;
  background: $hover-background;
}
