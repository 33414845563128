.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
