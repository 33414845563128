.formItem {
  .childVariablesContainer {
    padding-top: 5px;
    padding-left: 15px;
    :global {
      .ant-form-item {
        padding: 0;
        margin-bottom: 5px;
      }
    }
  }
}
