.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  .section {
    display: flex;
    gap: 8px;
    flex-direction: column;
    height: 100%;
  }

  .form {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
}
