.searchContainer {
  padding: 1em;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  gap: 10px;
  justify-content: space-between;
  padding: 1em;
}
