.host {
  text-align: center;
  position: fixed;
  z-index: 99999;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  &:hover {
    cursor: pointer;
  }
}
