.container {
  width: 97%;
  background-color: #f5f5f5;
  padding-top: 0.7em;
  padding-left: 0.7em;
  padding-bottom: 0.7em;
  margin-bottom: 0.5em;
}

.starContainer {
  color: red;
  margin-left: 0.2em;
}

.row {
  width: 100%;
  margin: auto;

  > .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0.2em;

    > input {
      width: 100%;
    }
  }

  > .colHeader {
    padding-right: 0.2em;
  }
}
