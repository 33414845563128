.welcomeSection {
  display: grid;
  max-width: 1150px;
  width: 100%;

  grid-template-columns: 1.5fr 1fr;
  align-items: center;
  grid-gap: 10px;
  border-radius: 8px;
  border: 1px solid #eee;
  background: linear-gradient(180deg, #f5f9fc 0%, #f0f7fd 100%);
  padding: 1.5em 3.5em;
  margin: 1.25em 0;
}

.homepageTitle {
  font-size: min(3em, 4vw);
  // font-size: 3em;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.42px;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1em;
}

.browseTemplatesButton {
  display: flex;
  width: 172px;
  height: 41px;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--primary-color, #1890ff);
  margin-top: 10px;
}

.roleText {
  color: var(--primary-color, #1890ff);
}

.aflacDuck {
  max-width: 416px;
  width: 100%;
  height: auto;
}

.fontSize {
  font-size: 1em;
  color: black;
}
