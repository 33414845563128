.StampSizeMenuContainer {
  min-width: 10em;
  min-height: 10em;
  position: relative;

  .StampList {
    margin-top: 1em;
    max-height: 45em;
    overflow-y: auto;

    > ul.StampListMenu {
      border: 0;

      > li.StampListMenuItem {
        height: auto;
        line-height: initial;

        div.StampListMenuItemWrapper {
          padding: 0.3em 0;

          > * {
            margin: 0.5em 0;
          }
        }
      }
    }
  }

  .StampListMenuLoader {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
