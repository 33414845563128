$border: 1px solid #d5cac9;

div.list-container {
  width: 100%;
  min-width: 80em;
  padding: 0.7em;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  > div.artboard-container {
    width: 100%;

    border-bottom: $border;

    > div.title {
      padding: 1em 0;
    }
  }

  > div.card-list-container {
    margin: 1em 0;
    width: 100%;

    display: flex;
    grid-gap: 0.5em 0.5em;
    grid-template-columns: repeat(auto-fill, 23%);
    justify-content: center;
  }
}

div.template-list-container {
  overflow: auto;
  position: relative;

  > div.template-card-list-container {
    width: auto;
    div.template-grid,
    div.virtual-grid {
      width: auto !important;
    }
  }
}
