.container {
  max-width: 1150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  gap: 2em;
  margin-bottom: 3.75em;
}

.cardContainer {
  width: calc(50% - 2.3em);
}
