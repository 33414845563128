.filterPopover > div > div > div > div > div {
  padding-left: 0;
}

.flexCheckboxGroup {
  display: flex;
  flex-direction: column;
}

.Button {
  // gets the first item in the Button, which is the columns ico
  & > div {
    fill: inherit;
    transition: fill 0.5s;
  }
}

.Button:hover,
.Button:focus {
  & > div {
    fill: #1890ff;
  }
}
