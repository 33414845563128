@use "./mixins";

.host {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin: 10px;

  > div {
    aspect-ratio: 1/1;
    background: white;
    overflow: hidden;
  }

  img {
    @include mixins.imgBackground;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
