.clickableCell {
  cursor: pointer;
  transition: 0.2 opacity;

  &:hover {
    opacity: 0.7;
  }
}

.input {
  width: 100%;
}

td.cellWrapper:has(button) {
  display: flex;
}

.error {
  & :global {
    .ant-select-selector {
      border: 1px solid #ff0000 !important;
      border-radius: 2px;
    }
  }
}

input.error {
  border: 1px solid #ff0000 !important;
  border-radius: 2px;
}
