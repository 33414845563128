.select {
  width: 100%;
  min-width: 350px;
}

.search {
  display: block;
}

.submenu {
  color: rgba(0, 0, 0, 0.85);
}

.submenuSelect {
  width: 100%
}

.submenuSelect{ :global { .ant-select-selection-item { 
  background: none;
  border: none;
  margin: 0px;
  padding: 0px;
} } }

.submenuSelect{ :global { .ant-select-selector { 
  padding: 0 24px;
  margin: 4px 0 8px 0;
} } }

.submenuSelect{ :global { .ant-select-selection-placeholder { 
  color: unset;
  left: 24px;
} } }
