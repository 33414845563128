@font-face {
  font-family: "ToyotaType-Black";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Black.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Black.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-BlackIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BlackIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BlackIt.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-BoldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BoldIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BoldIt.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-Book";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Book.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Book.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-BookIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BookIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-BookIt.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Light.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-LightIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-LightIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-LightIt.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-RegularIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-RegularIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-RegularIt.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-Semibold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Semibold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "ToyotaType-SemiboldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-SemiboldIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Toyota/ToyotaType-SemiboldIt.woff")
    format("woff");
}
