$background-color: #fafafa;
.OfferCollapse {
  min-width: 75em;
  .CollapsePanel {
    background: $background-color;

    > div:nth-child(1) {
      background: $background-color;
    }
  }
}
