.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.select {
  width: 100%;
  max-width: 300px;
}

.columnSelectWrapper {
  margin-left: 32px;
}

.checkbox {
  padding: 4px 10px;
}

.checkboxLabel {
  padding-left: 20px;
}

.table {
  max-width: 50%;
}

.portalConfigurationWrapper {
  position: relative;
}

.portalConfiguration {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  gap: 7px;
}

.leftPanelWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  padding-right: 7px;
  z-index: 0;

  &.canScrollMore::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 48px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
  }
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightPanel {
  background-color: #f5f5f5;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.navigationContainer {
  padding: 12px 24px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.previewTitle {
  font-size: 16px;
}

.previewHeader {
  padding: 24px;
}

.rightPanelContent {
  height: 100%;
  padding: 24px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: safe center;
  overflow-y: auto;
}

.spinWrapper {
  height: 100%;
}

.previewCard {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: 260px;
}

.previewImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 260px;
  min-height: 260px;
}

.metadataContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
}

.tag {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: fit-content;
  padding: 3px 10px;
  font-size: 13px;
  line-height: 18px;
  max-width: 100%;
  word-wrap: break-word;
}

.rowNameTitle {
  display: block;
  line-height: 22px;
}

.dimensionsTitle {
  font-size: 12px;
  display: block;
  line-height: 12px;
}

.moreOutlinedIcon {
  margin-left: auto;
}
