.footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  & > div {
    display: flex;
    align-items: center;

    button {
      margin: 0 10px;
    }
  }
}
