.tabs {
  :global {
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--primary-color, #00a7e1);
          text-shadow: 0 0 0.25px currentColor;
        }
      }
    }
  }
}
