div.loader {
  width: 100%;
  height: 15em;

  position: relative;

  .spinner {
    position: absolute;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
  }
}

.empty {
  min-width: 60em;
  margin-top: 10em;
}

.template-list-collapse {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;

  > .title {
    flex: initial;
  }

  > .number-counter {
    flex: initial;

    display: inline-block;
    background: #f47920;
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 0.5em;
  }
}

.template-list-collapse-artboard {
  color: rgba(0, 0, 0, 0.75);
  > .number-counter {
    flex: initial;

    display: inline-block;
    background: rgba(0, 0, 0, 0.15);
    padding: 1px 15px;
    font-size: 0.7rem;
    border-radius: 15px;
    margin: 0 1em;
  }
}

.template-list-artboard-collapse-container {
  .ant-collapse-header {
    background-color: Transparent !important;
  }
  svg {
    fill: rgba(0, 0, 0, 0.75);
  }
}
