$tag-background: #c05a0d;

div.manage-artboard-container {
  display: flex;
  flex-direction: column;

  .ant-input[disabled] {
    color: white !important;
    background: #454545 !important;
    border: 1px solid #727272 !important;
  }

  .ant-select-arrow-loading {
    color: white;
  }

  div.name-property {
    flex: 1;
    margin-bottom: 1em;
  }

  div.asset-type-property {
    flex: 1;
    margin-bottom: 1em;

    > div.ant-select {
      width: 100%;
    }
  }

  div.stamp-exception-property {
    flex: 1;
    margin-bottom: 1em;

    > div.ant-select {
      width: 100%;
    }

    > div.exception-revert-button-container {
      display: flex;
      flex-direction: row;

      > button {
        flex: 1;
        margin-top: 5px;
      }
    }
  }

  div.size-property {
    > div.size-fields-container {
      display: flex;

      > span {
        flex: 1;

        display: flex;
        align-items: center;

        color: white;

        > input,
        > span {
          flex: 1;
        }

        > input {
          margin-right: 0.5em;
        }
      }
    }
  }

  div.tag-property {
    display: flex;
    flex-direction: column;

    > div.tag-input-container {
      flex: 1;

      margin-bottom: 1em;

      > div.ant-select {
        width: 100%;
      }
    }
    > div.tags-container {
      flex: 1;

      width: 100%;

      display: flex;
      flex-wrap: wrap;

      > span {
        margin-bottom: 0.5em;

        background: $tag-background;
        color: white;

        > .anticon {
          color: white;
        }
      }
    }
  }
  .title {
    color: white;
  }
}

.stamp-exception-select-row {
  display: flex;
  flex-direction: row;

  > .title {
    flex: 1;
  }

  > .check-mark {
    flex: initial;

    &.saved > .anticon {
      color: #32cd32;
    }
  }
}
