.host {
  position: relative;

  > :nth-child(1) > :nth-child(2) {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    padding: 0 1em;
    align-items: center;
    height: 3em;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
  }

  > :last-child {
    display: flex;
    position: relative;
    background-color: white;
    transition: height 0.2s ease-in-out, bottom 0.2s, opacity 0.2s;
    bottom: 24px;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &[data-visible="true"] {
    > :last-child {
      transition: height 0.2s ease-in-out, bottom 0.22s ease-in-out, opacity 0.2s;
      bottom: 0;
      height: 2.5em;
      opacity: 1;
    }
  }
}


.icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  font-size: 70px;
  border-radius: 10px 10px 0 0;
  background: #f5f5f5;
  color: #1890ff;
}
