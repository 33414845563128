div.artboard-drawer-theme-img {
  padding: 0 2em 2em 2em;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  > div.image-container {
    width: 100%;
    height: 18em;
    position: relative;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }

    > icon {
      position: absolute;
      z-index: 100;
    }
  }
}
.theme-images-upload-container {
  height: 45vh;
  overflow-y: auto;
  width: 680px;
  position: relative;
  .theme-images-upload > div.ant-upload-list {
    width: 655px;
  }
}

.artboard-drawer-add-search {
  display: flex;
  align-items: center;
  width: 655px;
  height: 40px;
  .artboard-drawer-add-icon {
    margin-top: 8px;
    font-size: 32px;
    cursor: pointer;
    width: 20px;
  }
}
.artboard-drawer-add-input {
  top: 5px;
  left: 15px;
  width: 640px;
  height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
}
