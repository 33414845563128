.col {
  padding-right: 0px;
  padding-left: 24px;
  padding-top: 24px;

  .title {
    margin-bottom: 28px;
  }

  .noMarginField {
    margin-bottom: 0px;
  }

  .helpText {
    color: #555555;
  }
}
