.flowItem {
  width: 100%;
  height: auto;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: lightgray;

  &.CAROUSEL {
    display: block;
  }

  > .flowPlaceholderWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.sourceHandle {
  width: 10px;
  height: 10px;
  right: -5px;
}
