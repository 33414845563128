.rule-form {
  width: 100%;
  height: auto;

  .rule-form-if {
    display: flex;
    flex-direction: row;

    > * {
      flex: 1;

      &:first-child {
        margin-right: 1em;
      }

      &:last-child {
        margin-left: 1em;
      }

      &:not(:first-child):not(:last-child) {
        margin: 0 1em;
      }
    }

    > .delete-and-if {
      flex: initial;
      width: 2.5em;
    }
  }

  .rule-form-then {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .rule-form-then-type {
      flex: 2;
    }
    > .rule-form-then-input {
      flex: 5;
      margin: 0 1em;
    }

    > .rule-form-regex-resource {
      flex: 3;
    }
  }
}

.rule-form-if-else-select {
  border: 0;

  > li.ant-menu-item {
    min-width: 5em;
    text-align: center;
    padding: 0 10px !important;
    text-transform: uppercase;
  }
}

.rule-form-error {
  margin-left: 5px;
  color: red;
}
