@font-face {
  font-family: "BMWTypeNextLatin-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatin-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Light.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatin-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatin-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatin-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatinTT-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatinTT-Light";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Light.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Light.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatinTT-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "BMWTypeNextLatinTT-Thin";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Thin.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/BMW/BMWTypeNextLatinTT-Thin.woff")
    format("woff");
}
