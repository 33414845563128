.drawer {

  .spin {
    height: 100%;
    display: flex;
  }

  .tabs {
    width: 100%;
    height: 100%;

    >*:last-of-type {
      >*:first-of-type {
        height: 100%;
        flex-direction: column;
      }
    }

    .tabPane {
      height: 100%;
    }
  }
}
