.buttons {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .divider {
    height: 1px;
    background-color: #c9ccd1;
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .actions {
    padding: 8px 10px 2px 10px;
    display: flex;
    justify-content: space-around;
    font-weight: 600;
    color: #616770;

    .actionButtonContainer {
      display: flex;
      align-items: flex-start;
    }

    .iconWrapper {
      padding: 0 8px 1px 0;
    }

    .thumb {
      background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/wdjMXy7QpPG.png);
      background-size: 28px 338px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 18px;
      width: 18px;

      background-position: 0 -136px;
    }
    .comment {
      background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/wdjMXy7QpPG.png);
      background-size: 28px 338px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 18px;
      width: 18px;
      padding: 0 8px 1px 0;

      background-position: 0 -117px;
    }
    .share {
      background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yC/r/wdjMXy7QpPG.png);
      background-size: 28px 338px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 18px;
      width: 18px;
      padding: 0 8px 1px 0;

      background-position: 0 -174px;
    }
  }
}
