.container {
  background: rgb(243, 244, 245);
  text-align: center;
  padding: 8px;
  color: #5f6368;

  .selectAllButton {
    color: #1890ff;
    font-weight: bold;
    &:hover {
      background: rgb(24, 144, 255, 0.1);
    }
  }
}
