div.add-tag-dropdown-container {
  padding: 0.5em;
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    background: #e6f7ff;
    cursor: pointer;
  }
}

span.add-template-tag-container-span {
  > span {
    font-weight: bold;
    font-size: 1.1rem;

    display: inline-block;
    padding: 0 0.3em;
  }
}
