.shippingRow {
  background-color: #f5f5f5;
  padding: 16px;

  .radio {
    width: 100%;
  }
}

.infoIcon {
  padding-top: 4px;
  align-self: flex-start;
  justify-self: flex-start;
  svg {
    color: #1890ff;
  }
}
