.item {
  background-color: #fff;
  height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  position: relative;

  a {
    width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.selectedItem {
  border-right: 2px solid rgb(68, 142, 247);
}

.buttonContainer {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
  padding: 2px 0px 2px 30px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 25%
  );
  margin-right: 5px;
}

.buttonContainer button {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer button svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedButtonContainer {
  background: linear-gradient(
    to right,
    rgba(225, 243, 254, 0.7) 0%,
    rgba(225, 243, 254, 1) 15%
  );
}
