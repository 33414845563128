.formContainer {
  padding: 0px 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: auto;

  .title {
    margin-bottom: 16px;
  }

  .divContainer {
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    background-color: #fafafa;
    padding: 16px;
  }

  .totalAmountContainer {
    display: flex;
    align-items: center;
  }

  .totalAmountText {
    font-size: 24px;
    color: #f89627;
  }

  .totalAmountPages {
    margin-left: 4px;
  }

  .collapsibleTable {
    :global {
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }

  .confirmationContainer {
    :global {
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
