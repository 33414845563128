@font-face {
  font-family: "Liberator-Heavy";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Heavy.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Heavy.woff")
    format("woff");
}
@font-face {
  font-family: "Liberator-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Liberator-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Liberator/Liberator-Medium.woff")
    format("woff");
}
