// below part adds styles to the border of collapse panels
div.design-studio-editor-container {
  .ant-collapse-content {
    border: 0;
    border-radius: 0;
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
  }

  .ant-collapse {
    background-color: #454545;
    border: 0;

    > .ant-collapse-item {
      border: 0;
    }

    > .ant-collapse-item:last-child {
      > .ant-collapse-header {
        border-radius: 0;
        border-bottom: solid 0.5px rgba(255, 255, 255, 0.25);
      }
    }
  }
}
