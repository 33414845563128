.title {
  font-size: 16px;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.button {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 14px;
  width: 14px;
}
