.card {
  background-color: #fafafa;

  .alert {
    margin-top: 12px;
  }

  :global {
    .ant-card-body {
      padding: 12px 16px;
    }
  }
}
