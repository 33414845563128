$background-color: #454545;
$divider-color: #48494a;
$border-color: #727272;

div.collapse-container.design-studio.MANAGE_LAYERS {
  div.ant-collapse-content-box {
    padding: 0;
  }
}

// ant design selection  styles
div.design-studio-editor-container {
  div.filter-section {
    div.ant-select-selector {
      background: $background-color !important;
      border: 1px solid #727272;
      color: white !important;

      .ant-select-selection-item-content {
        color: rgba(0, 0, 0, 0.85);
      }
      .ant-select-arrow {
        .anticon {
          color: #b4b4b4;
        }
      }
    }
  }
}

// added 'test' dropdown class to test difference between local and dev AV2-3426
.ant-select-dropdown.property-row-select {
  background: #454545;
  .ant-empty-description {
    color: white;
  }
}

.design-studio-root-container div.ant-divider {
  margin: 1em 0;
  background: $divider-color;
}

span.arrange-icon-container {
  .anticon.anticon-swap {
    font-size: 1rem;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
