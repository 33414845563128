.inlineActions {
  position: fixed;
  right: 0;
  z-index: 2;
  max-width: 300px;
  pointer-events: none;
}

.contextActions {
  position: absolute;
  left: -100%;
  z-index: 2;
  min-width: 200px;
  min-height: 50px;
}
