.container {
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
  gap: 5px;

  .children {
    margin-left: 2em;
  }
}
