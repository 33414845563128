.table {
  overflow: hidden;
  padding: 0;
  padding-left: 32px;
}

.row {
  overflow: hidden;
  background-color: #fbfbfb;
  td {
    padding: 0;
  }
}
