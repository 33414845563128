.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;

  .form {
    display: block;
    width: 100%;

    .fields {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 10px;

      .formItem {
        display: block;
        width: 100%;
      }

      .deleteIcon {
        padding-right: 10px;
      }
    }
  }
}
