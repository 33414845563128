.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  min-height: 100px;
  min-width: 100px;
  max-height: 100px;

  span {
    margin-bottom: 5px;
  }

  .plusIcon {
    font-size: 20px !important;
    color: black !important;
  }

  .pictureIcon {
    font-size: 30px;
    color: #ff4d4f;
  }

  .mask {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 80%;
    width: 80%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    .actionButtons {
      display: flex;
      gap: 8px;
    }
  }

  .errorFileName {
    color: #ff4d4f;
    margin-top: 10px;
    text-align: center;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dragger {
  :global .ant-upload.ant-upload-btn {
    padding: 0;
  }
  &.error {
    border: solid 1px #ff4d4f;
  }
  &.error:hover {
    border-color: #ff4d4f !important;
  }
  &:hover {
    .mask {
      display: flex;
    }
  }
}

.imageSelector {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 10px;

  .imageLibraryPicker {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;

    a {
      padding-left: 16px;
    }
  }
}

.imagePreviewMask {
  .actionButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20%;
  }
}
