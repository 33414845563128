@font-face {
  font-family: "MazdaType-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "MazdaType-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "MazdaType-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "MazdaType-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "MazdaType-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "MazdaType-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Mazda/MazdaType-Regular.woff")
    format("woff");
}
