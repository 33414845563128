.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.65em;
  padding: 0 min(9.125em, 3.5vw);
  background: #f5f9fc;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: min(5em, 5.5vw);
  margin-top: auto;
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.linksContainer {
  display: flex;
  gap: 1em;
  font-size: min(1em, 1.5vw);
}

.linkText {
  display: flex;
  align-items: center;
  gap: 0.65em;
  cursor: pointer;
}

.copyrightContainer {
  display: flex;
  align-items: center;
  gap: 0.65em;
  font-size: min(1em, 1.5vw);
}

.aflacLogo {
  max-width: 4.5em;
  width: 100%;
  height: auto;
}
