.Row {
  display: flex;
  flex-direction: row;
  gap: 3em;
  justify-content: space-between;

  width: 100%;
  height: auto;

  background: #fafafa;

  box-sizing: border-box;
  padding: 1em 0.7em;

  border-bottom: 1px solid #f1f1f1;

  &:hover {
    cursor: default;
  }

  &.Header {
    background: white;
    border: 0;
    height: 7em;
    padding-top: 0;
    padding-bottom: 1em;
  }

  > .CellWrapper {
    flex: 1;
    height: 100%;
  }

  > .RepeatableFieldButtonWrapper {
    flex: 1;
  }
}
