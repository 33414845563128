.host {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #f0f2f5;
  padding: 15px 0;
}
