.formItem {
  flex: 1;
}

.spinnerContainer {
  flex: 0 0 2em;
  position: relative;
  top: 1px;
}

.spinner {
  > :nth-child(1) {
    display: block;
  }
}
