.search {
  width: 100%;
  height: auto;
  display: flex;
  gap: 1em;
  padding: 12px 12px 0;

  > .input {
    flex: 1;
    display: flex;
  }
}
