@use "../../mixins";

.host {
  position: relative;

  > :nth-child(3) {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 12px 16px 16px 16px;
    height: 3em;
    background-color: white;
  }

  > :last-child {
    display: flex;
    position: relative;
    background-color: white;
    transition: height 0.2s ease-in-out, bottom 0.2s, opacity 0.2s;
    bottom: 24px;
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &[data-visible="true"] {
    > :last-child {
      transition: height 0.2s ease-in-out, bottom 0.23s ease-in-out,
        opacity 0.2s;
      bottom: 0;
      height: 2.5em;
      opacity: 1;
    }
  }
}

.name {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-height: 1.2;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
  padding: 1em;

  &[data-selectable="false"] {
    pointer-events: none;
    opacity: 0.15;
    filter: grayscale(1);
  }

  > img {
    max-width: 100%;
    max-height: 100%;
    @include mixins.imgBackground;
  }
}
