@font-face {
  font-family: "LandRover-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "LandRover-Demi";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Demi.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Demi.woff")
    format("woff");
}
@font-face {
  font-family: "LandRover-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Light.woff")
    format("woff");
}
@font-face {
  font-family: "LandRover-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/LandRover-Medium.woff")
    format("woff");
}

@font-face {
  font-family: "AvenirNextLTPro-Medium";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/AvenirNextLTPro-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextLTPro-It";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/AvenirNextLTPro-It.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/AvenirNextLTPro-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Land Rover/AvenirNextLTPro-Regular.woff")
    format("woff");
}
