.image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;

  .button {
    border: none;
  }
}
