.OfferListSection {
  .OfferListSectionTitle {
    font-size: 0.9rem;
    font-weight: bold;

    margin: 0.5em 0;

    display: flex;
    gap: 0.7em;
  }

  .Spin {
    width: 100%;
  }
}
