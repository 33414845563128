.audioSliderContainer {
  border-bottom: 1px solid #e7e7e7;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.audioSliderContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.audioSliderName {
  width: 160px;
  min-width: 160px;
  padding-bottom: 16px;
}

.slider {
  width: 89%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}
