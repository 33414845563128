.drawer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: "stretch";
  height: 100%;

  .title {
    .h3 {
      margin-bottom: 0;
    }
  }
}

.historyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33em;
  border-right: 1px solid #e8e8e8;
  height: 100%;
  padding: 24px 20px;
}

.table {
  width: 100%;
}

.detailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 24px;

  .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.historyRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 0.5rem;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}

.historyRowActive {
  background-color: #e6f7ff;
}

.footer {
  display: flex;
  height: 48px;
  padding: 10px 16px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
