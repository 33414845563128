@font-face {
  font-family: "AvenirNextRoundedPro-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-BoldIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-BoldIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-BoldIt.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-Demi";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Demi.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Demi.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-DemiIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-DemiIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-DemiIt.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-Italic";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Italic.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-Med";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Med.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Med.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-MedIt";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-MedIt.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-MedIt.woff")
    format("woff");
}
@font-face {
  font-family: "AvenirNextRoundedPro-Reg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Reg.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Honda/AvenirNextRoundedPro-Reg.woff")
    format("woff");
}
