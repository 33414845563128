.addNewWebIntegration {
  width: 100%;
  height: 7em;
  font-size: 1.5rem;
  margin: 1em 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  display: flex;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin: 0.5em;
    border: 1px solid lightgrey;
    user-select: none;
  }
}

.webIntegrationUploader {
  position: absolute;
  bottom: 27px;
}
