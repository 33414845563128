.dimensionList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .infoText {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: -4px;
  }

  .dimensionListItem {
    display: flex;
    padding: none;
    gap: 8px;
  }
}

.customDimensionContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: -20px;

  .customDimensionInputRow {
    display: flex;
    gap: 8px;

    .customDimensionInput {
      display: flex;
      flex: 1 1 50%;
      align-items: center;
      gap: 8px;
    }

    .deleteButton {
      border: none;
    }
  }

  .addCustomDimension {
    text-align: left;
    padding-left: 8px;
  }
}
