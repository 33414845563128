.container {
  background-color: #f5f5f5;
  min-width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 20px 0;
  position: relative;
  .preview {
    border: 1px solid rgba(24, 144, 255, 0);
    height: 100%;
    width: 850px;
    padding: 10px 0;
    background-color: white;
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    background-color: black;
    position: absolute;
    .icon,
    .title {
      color: white;
      opacity: 1;
    }
  }
}
