.previewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .templateNav {
    flex: initial;
  }
  .preview {
    flex: 1;
    height: 100%;
    padding: 0 1em 0 1em;

    > .canvasContainer {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.spin {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.canvas {
  position: fixed;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  visibility: visible;
}

.canvasHidden {
  opacity: 0;
  visibility: hidden;
}

.reloadTemplateLayer {
  position: absolute;
  z-index: 1000;
}

.reloadBackgroundLayer {
  background-color: white;
  opacity: 0.7;
}

.reloadContentLayer {
  display: flex;
  left: 0;
  top: 0;
  position: absolute;
  margin: auto;
}

.reloadContentContainer {
  top: 40%;
  margin: auto;
  text-align: center;
}

.reloadIcon {
  color: #1890ff;
  font-size: 48px;
}

.loadText {
  font-size: 18px;
  font-weight: bold;
}
