.body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2em;

  header {
    margin-bottom: 1em;

    h3 {
      font-weight: bold;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}
