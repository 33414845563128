.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  justify-content: space-between;
  grid-gap: 20px;
  padding: 0 24px;

  &.empty {
    display: flex;
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
}
