.itemCountToolbar {
  display: flex;
  align-items: center;

  margin: 10px 0;

  height: 25px;

  > * {
    height: 100%;
  }

  > .filterContainer {
    display: flex;
    gap: 10px;
  }
}
