.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navigationMessage {
  margin: 0 0.5em;
  color: grey;
}
.divider {
  margin: 0;
}
.actionButtons {
  display: flex;
  gap: 0.5em;
}
