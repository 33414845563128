$focused-button-background: #37aadc;

.collapse-container {
  &.offerTypes-variables-filter-section {
    div.filter-section-fields-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.filter-by {
  .ant-input {
    background-color: white;
  }
}
