.updateAdsContainer {
  display: flex;

  .selectAdsContainer,
  .destinationContainer {
    flex: 1;
  }

  .divider {
    height: 100%;
  }

  .destinationContainer {
    & :global {
      .ant-collapse-content-box {
        background-color: white;
      }
    }

    .panelGroup {
      margin-top: 1rem;
      overflow: auto;
      max-height: 68vh;
    }
  }
}
