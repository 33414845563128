.addButton {
  padding-left: 0;
}

.formItem {
  margin-right: 0!important;
}

.upload {
  display: flex;
  flex-direction: column-reverse;

  :global {
    .ant-upload-list-item-card-actions
      .ant-btn.ant-upload-list-item-card-actions-btn {
      opacity: 1;
      background: white;
      width: auto;

      svg {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background-color: white;
    }

    .ant-upload-list-item-info {
      padding: 0;
    }
  }
}
