.input {
  width: 100%;
}

.fixedValue {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.fixedValueHeader {
  font-weight: bold;
}
