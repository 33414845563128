.sourceFeedClickable {
  color: #1890ff;
  font-weight: 500;
  cursor: pointer;
}

.disabled {
  color: #bfbfbf;
}

.feedTable {
  width: 2415px !important;
  max-width: 100%;
}
