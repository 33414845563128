.drawer {
  z-index: 1003;
}

.row {
  height: 100%;

  :global {
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
}

.footer {
  text-align: right;
}

.body {
  padding: 0;
}

.summaryCol {
  background-color: #f5f5f5;
  min-height: 100%;

  .summary {
    padding-top: 24px;
    padding-right: 24px;
  }
}
