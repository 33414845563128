.body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  min-height: 100%;
}

.header {
  padding: 16px 24px;
}

.title {
  outline: none;
  transition: all 0.3s;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}
