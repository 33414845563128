@font-face {
  font-family: "Novecentosanswide-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Novecentosanswide-DemiBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-DemiBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-DemiBold.woff")
    format("woff");
}
@font-face {
  font-family: "Novecentosanswide-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Light.woff")
    format("woff");
}
@font-face {
  font-family: "Novecentosanswide-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Novecentosanswide-Normal";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Normal.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Novecentosanswide/Novecentosanswide-Normal.woff")
    format("woff");
}
