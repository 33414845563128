.inputContainer {
  .input {
    &.error {
      border-color: red;

      &:focus {
        box-shadow: none;
      }
    }
  }
  .select {
    width: 100%;

    &.error {
      & > *:first-child {
        border-color: red;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .regexInputContainer {
    display: flex;
    flex-direction: row;

    > * {
      flex: 1;
    }

    > *:last-child {
      margin-left: 8px;
    }
  }
  .errorMessage {
    font-size: 0.8rem;
    color: red;
  }
}
