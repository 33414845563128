@font-face {
  font-family: "MINISerif-Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MINISerif-Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MINISerif-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "MINISerif-Regular";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MINISerif-Regular.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MINISerif-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "MiniRg";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MiniRg.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/MINI/MiniRg.woff")
    format("woff");
}
