$border: 1px solid #d9d9d9;

.wrapper {
  width: 400px;
  font-family: inherit;
  * {
    font-family: inherit;
  }
}

.toolbar {
  border-bottom: $border;
  margin: 0;
  font-family: ProximaNova, sans-serif;
}

.toolbarButtons > div {
  height: 100%;
  width: 30px;
  margin: 0 3px;
}

.editor {
  padding: 12px;
  * {
    margin: 0;
  }
}

.whiteEditor .editor {
  background-color: white;
}

.blackEditor .editor {
  background-color: black;
}

.error {
  color: #ff4d4f;
  position: absolute;
}
