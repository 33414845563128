.container {
  text-align: right;
}

.button {
  margin-right: 0.5rem;
}

.message {
  margin-bottom: 0.625rem;
}

.adminLink {
  padding-left: 0.3rem;
}
