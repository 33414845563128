@font-face {
  font-family: "DIN Alternate Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DIN+Alternate+Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DIN+Alternate+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "DIN Condensed Bold";
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DIN+Condensed+Bold.ttf")
    format("truetype");
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DIN+Condensed+Bold.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Black.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-BlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-BlackItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Cond";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Cond.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Cond.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondBlack";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBlack.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBlack.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondBlackIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBlackIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBlackIta.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondBold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBold.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondBoldIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBoldIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondBoldIta.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondIta.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondLight";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondLight.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondLight.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondLightIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondLightIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondLightIta.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondMediIta";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondMediIta.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondMediIta.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-CondMedium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondMedium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-CondMedium.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Light.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-Medium";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Medium.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT-MediumItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-MediumItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT-MediumItalic.woff")
    format("woff");
}
@font-face {
  font-family: "DINOT";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/DIN_OT/DINOT.woff")
    format("woff");
}
