.url {
  width: 100%;
  height: auto;

  background-color: white;
  padding: 15px;

  border-radius: 4px;

  .content {
    background-color: #f5f5f5;
    padding: 10px 15px;
    color: #1890ff;

    width: 100%;
    display: flex;

    .icon {
      margin-top: 5px;
      margin-right: 5px;
    }

    .text {
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}

.preview {
  width: 100%;
  min-height: 400px;
  background-color: #f5f5f5;

  > img {
    width: 100%;
    height: auto;

    object-fit: contain;
  }
}
