.collapse-container {
  > .filter-section-container-panel.build-steps {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;

      > .ant-collapse-header {
        background: #a9abad;
      }
    }
  }
}

$border-color: #d7d1d1;

.selection-container {
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;

  border: 1px solid $border-color;

  box-sizing: border-box;

  margin: 1em 0;

  &[class*="empty-"] {
    border: 0;
  }

  > .title {
    flex: initial;
    width: 100%;
    height: 2.5em;
    line-height: 2.5em;
    font-weight: bold;

    border-bottom: 1px solid $border-color;

    padding-left: 1em;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > .content {
    flex: 1;
    width: 100%;

    &:not(.template-selection),
    &:not(.image-selection-content) {
      padding: 2.5em 1.25em;
    }

    &.template-selection-content,
    &.image-selection-content {
      padding: 1em;
    }

    ul.size-selection-ul,
    ul.template-list,
    ul.offer-type-selection,
    ul.image-selection-list {
      min-height: 3em;
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 35em;
      overflow-y: auto;
      padding-right: 0.5em;
    }
    > .loading-spinner {
      display: flex;
      justify-content: center;
    }
  }
}
