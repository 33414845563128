.container {
  width: 100%;
  height: 100%;

  :global(.ant-tabs-content) {
    height: 100%;
  }
}

.previewContainer {
  width: 100%;
  height: 100%;
  padding: 0 15%;
  background-color: #f5f5f5;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
}

.pdfTip {
  opacity: 0.45;

  display: flex;
  flex-direction: column;
  gap: 1em;
}
