.aiImageGenContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .leftPanel {
    display: flex;
    flex-grow: 1;
    max-width: 564px;
    background-color: white;

    .inputSectionContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px;
      min-width: 320px;

      .inputTextArea {
        min-height: 84px;
      }
    }
  }

  .resultSectionContainer {
    display: flex;
    justify-content: center;
    min-width: 872px;
    min-height: 100%;
    padding: 24px;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #f5f5f5;

    .buttonsRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
      width: 100%;
    }

    .gridContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 30px;
      max-width: 830px;
      padding: 24px 0;
    }

    .skeleton {
      height: 400px;
      width: 400px;
    }

    .emptyState {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
}
