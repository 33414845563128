.adWizard {
  :global {
    .ant-row {
      .ant-form-item-label {
        padding: 0;
      }
    }
    .ant-form-item {
      margin: 0 0 10px 0;
    }
    .ant-form {
      display: flex;
      justify-content: space-between;

      .ant-divider {
        height: 89vh;
      }
      .form-column {
        width: 32%;

        .column-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1em;
        }
      }
    }
    .ant-drawer-footer {
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      gap: 1em;
    }
  }
}
