$flex-vertical-gap: 0.5em;
$bottom-font-size: 12px;

.CollapsableHeader {
  height: auto;
  width: 100%;
  min-width: 70em;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;

  padding: 0 1em;
  background: #fafafa;

  > * {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $flex-vertical-gap;
  }

  > .Left {
    width: 55em;

    flex: initial;

    > .Top {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: row;
      gap: 2em;

      > .Title {
        font-weight: bold;
        max-width: 210px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > .Sold {
          padding-right: 5px;
        }

        > .Flagged {
          color: red;
          margin-right: 0.5em;
        }

        > .Warning {
          color: #faad3a;
          margin-right: 0.5em;
        }
      }
    }

    > .Bottom {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1em;

      font-size: $bottom-font-size;

      > .OfferKeyValue {
        > .Key {
          font-weight: bold;
        }
      }
    }
  }

  > .Right {
    flex: initial;
    width: 15em;
    min-width: 15em;

    > .Top {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      > .Tools {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.4em;

        margin-left: auto;

        > * {
          &.Disabled:hover {
            cursor: not-allowed;
          }

          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }
      }
    }

    > .Bottom {
      width: 100%;
      height: auto;

      font-size: $bottom-font-size;

      > .LastEditRevert {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1em;

        > .RevertSession {
          color: #1990ff;

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.OfferTypeTags {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  .OfferTypeTag {
    padding: 0.3em 0.7em;
    font-size: 0.95em;
    margin: 0;
    color: #fff;
    background-color: #1890ff;

    &.Disabled {
      color: #000;
      background-color: #fff;
    }
  }
}
