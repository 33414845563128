.loadingContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
