.alertContainer {
  margin-bottom: 24px;

  .alertContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .message {
    padding: 0 7px;
  }
}
