.title {
  margin-bottom: 0 !important;
  margin-right: 1em;
  margin-left: 1em;
}

.tabs {
  height: 100%;
  > *:nth-child(1) {
    margin: 0;
  }

  > *:nth-child(2) {
    > *:nth-child(1) {
      height: 100%;
    }
  }
}
