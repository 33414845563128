.flexCenter {
  display: flex;
  align-items: center;
  overflow: hidden;

  .img {
    height: 30px;
    width: 30px;
  }

  .text {
    margin: 0 1em;
  }

  .clickable {
    color: var(--primary-color, #1890ff);
  }

  > * {
    min-width: 20px;
  }
}
