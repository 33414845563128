@font-face {
  font-family: "TradeGothicLTStd-Bd2Obl";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Bd2Obl.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Bd2Obl.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-BoldExt";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-BoldExt.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-BoldExt.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-BoldObl";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-BoldObl.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-BoldObl.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Cn18";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Cn18.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Cn18.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Cn18Obl";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Cn18Obl.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Cn18Obl.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Extended";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Extended.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Extended.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Light.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd-Obl";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Obl.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd-Obl.woff")
    format("woff");
}
@font-face {
  font-family: "TradeGothicLTStd";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/TradeGothicLTStd/TradeGothicLTStd.woff")
    format("woff");
}
