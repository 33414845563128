$colWidth: 237px;
$colGutter: 15px;
$dsButtonColor: #32c5ff !important;

@function cols($num) {
  @return $num * $colWidth + ($num - 1) * $colGutter;
}

.offer-form {
  width: cols(5) + 2 * $colGutter;
  .ant-collapse-borderless .ant-collapse-content .ant-collapse-content-box {
    padding: 0;
  }
  .offer-detail-list {
    .ant-collapse-item .ant-collapse-header {
      background-color: #ffffff !important;
      padding: 0 30px;
    }

    .ant-collapse-borderless .ant-collapse-content {
      border-top: 1px solid #97979788;
      padding: 15px 0 0 30px;
    }
    .section-title {
      font-size: 1em;
      font-weight: bold;
      padding-top: 15px;

      .ant-checkbox-wrapper {
        margin-right: 15px;
      }
    }
  }
}

.ant-collapse-content-box {
  svg[data-icon="right"] {
    fill: rgba(0, 0, 0, 0.85);
  }
}

.offer-heading {
  position: relative;
  color: #000;

  > .offer-heading-ymmt {
    vertical-align: text-top;

    > .offer-heading-in-transit {
      color: #f68f45;
      padding-left: 15px;
    }
  }

  .warning-icon {
    color: orange;
    margin: 0 0.25em;
  }

  > .tag-container {
    width: auto;
    height: 100%;
    font-weight: bold;
    margin: 0 0.5em;
    display: inline-block;

    > .tag {
      display: inline-block;
      height: 80%;
      color: #8c8c8c;
      padding: 3px 8px;
      font-size: 0.8rem;
      margin: 0 0.4em;
    }
    > .tag-active {
      display: inline-block;
      height: 80%;
      color: #1890ff;
      height: 80%;
      padding: 3px 8px;
      font-size: 0.8rem;
      margin: 0 0.4em;
    }
    > .tag-select-all {
      margin-left: 1em;
      height: 5px;
    }
  }

  > .sort-by-container {
    display: flex;
    flex-direction: row;

    > .sort-by {
      margin: 0 1em;
      font-size: 0.7rem;
    }
  }

  .actions-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    > .edit-button {
      color: #000;
      padding-left: 0.25em;
      padding-right: 0.25em;
    }

    > .reset-to-feed-btn {
      padding-left: 0.25em;
      padding-right: 0.25em;
    }

    > .duplicate-button {
      color: #000;
      padding-left: 0.25em;
      padding-right: 0.25em;
    }

    > .last-modified-by {
      flex: 1;

      font-size: 0.8rem;
    }

    > .anticon {
      width: 3em;
    }

    > .last-modified-date {
      margin-right: 1.75em;
      font-size: 0.7rem;
      font-weight: bold;

      > .last-modified-date-header {
        margin-right: 0.5em;
      }
    }

    > .invalid-offer {
      display: flex;
      align-items: center;
      font-size: 0.8em;
      font-weight: bold;
      margin-right: 2em;
      color: orange;
      > .invalid-offer-icon {
        color: orange;
        font-size: 16px;
        margin-right: 0.5em;
      }
    }
  }
}

.offer-data-row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 85%; // temporarily reduced size to squeeze in transactability score

  > .ant-col {
    > .ant-form-item {
      display: inline;

      > .ant-col {
        > label {
          font-weight: bold;
        }
      }
    }
  }
  .disclosure-data {
    width: 100% !important;
    height: 100% !important;
    max-height: 100px;
    overflow: hidden;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    .ant-legacy-form-item-control-wrapper {
      .ant-legacy-form-item-control {
        padding-right: 1em;
      }
      overflow-y: scroll;
      height: 100px;
      display: block;
      box-sizing: content-box;
    }
  }

  &.body {
    padding: 15px 30px;
  }

  .offer-image-container {
    box-sizing: border-box;
    width: cols(1);
    margin-right: $colGutter;
    display: flex;
    justify-content: center;
    flex-shrink: 0;

    img {
      max-width: 100%;
      max-height: 125px;
    }
  }

  .ant-legacy-form-item,
  .ant-legacy-form-item.ant-legacy-form-item-with-help {
    position: relative;
    width: cols(1);
    flex-shrink: 0;

    &.colspan-2 {
      width: cols(2);
    }

    .ant-input-number {
      width: 100%;
    }

    margin-right: $colGutter;

    &:last-child {
      margin-right: 0;
    }

    label,
    .ant-legacy-form-explain {
      font-size: 0.8em;
    }

    .ant-input,
    .ant-picker-input,
    .ant-picker {
      width: 100%;
    }

    .ant-legacy-form-item-control-wrapper,
    .ant-legacy-form-item-control,
    .ant-legacy-form-item-children {
      min-height: 100%;
    }

    .ant-btn.rebate-button {
      position: absolute;
      top: 4px;
      width: 20px;
      height: 20px;
      min-width: 20px;
      display: block;
      font-size: 0.75em;
      line-height: 20px;
    }

    .paymentengine-button {
      width: 25%;
      float: right;
      right: 4px;
      border: 1px solid #d9d9d9;
      border-left: none;
      border-radius: 0 5px 5px 0;
      background-color: $dsButtonColor;
      color: #ffffff !important;
      font-size: 20px;
      .anticon {
        line-height: 0px;
        transform: rotate(90deg) translate(-2px, 0px);
      }
    }
  }
}

.ant-modal.payment-engine-modal {
  width: 70% !important;
  .ant-modal-body {
    height: 50em;
    overflow: scroll;
    padding: 6px;
  }
  .ant-table-header-column {
    .ant-table-column-title {
      .ant-table-selection {
        opacity: 0;
        height: 0;
        pointer-events: none;
      }
    }
  }
  .ant-table-selection-column {
    .static-field-toggle,
    .single-select-toggle.ant-checkbox-wrapper {
      opacity: 0;
      pointer-events: none;
    }
  }
  .ant-table-row-selected {
    .ant-input-group-addon {
      background-color: $dsButtonColor;
      width: 25%;
      .anticon {
        color: #ffffff;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }

  .ant-descriptions.ant-descriptions-bordered {
    .ant-descriptions-view {
      height: 100%;
    }
  }
}

// shifts collapse arrows up so they don't float next to inputs
.select-form-collapse {
  .offer-detail-list {
    .ant-collapse-item {
      .ant-collapse-header {
        .anticon.anticon-right.ant-collapse-arrow {
          top: 2.5em;
        }
      }
    }
  }
}
