$navi-background: #31465e;
.CanvasContainer {
  overflow-x: auto;

  > .Header {
    width: 100%;
    height: 3.5em;
    line-height: 3.5em;
    padding-left: 1em;
    background-color: $navi-background;

    > .Title {
      color: white;
      font-size: 1rem;
    }
  }

  > .Wrapper {
    > .ToolbarWrapper {
      height: 4em;
      padding: 0 0.8em;

      position: relative;

      > .SizeLabel {
        position: absolute;
        right: 0.7em;
        top: calc(100% + 0.7em);
        background: #272626;
        border-radius: 5px;
        color: white;

        font-size: 0.7rem;
        width: 7.5em;
        text-align: center;
        height: 2em;
        line-height: 2em;
      }
    }

    .CanvasWrapper {
      background-color: gray;

      overflow: hidden;

      > .CanvasPlaceholder {
        min-height: 50em;
        background: gray;
      }
    }
  }
}

.publishedMessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    padding: 0 8px;
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
  }
}
