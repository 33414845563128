.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.closeButton {
  background-color: #ffffff;
  padding: 4px 15px;
}

.audioVisualizer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.compositionContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.playerContainer {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
}

.audioFilePreviewContainer {
  width: 100%;
}
