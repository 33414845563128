.drawer {
  :global {
    .ant-form-item {
      margin: 0 0 10px 0;
    }
    .ant-form {
      display: flex;
      justify-content: space-between;

      .ant-divider {
        height: 89vh;
      }
    }
  }
}
