.copy {
  color: #000000;
  padding: 0 10px;
  margin: 8px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;

  position: relative;

  .messageBox {
    max-height: 54px;
    overflow: hidden;

    margin-left: 5px;
    line-height: 18px;
    word-wrap: break-word;
    white-space: normal;

    .left {
      float: right;
      width: 0;
    }

    .seeMore {
      margin: 0 5px;
      color: #576b95;
      float: right;
      clear: right;
    }

    .message {
      white-space: pre-line;
    }
  }
}
