.modalContentDiv {
  display: flex;
  flex-direction: column;

  .shareLinkInput {
    max-width: 472px;
  }

  .form {
    margin-top: 0.5rem;

    .formItem {
      margin-bottom: 0.5rem;
    }
  }
}
