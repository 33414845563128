.container {
  width: 100%;
  min-width: 500px;

  height: 400px;
  background-color: #f5f9fc;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #e0e0e0;
  border-radius: 5px;
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h3 {
      font-weight: bold;
    }

    > .action {
      > * {
        text-decoration: underline;
      }
    }
  }
}
