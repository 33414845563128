.flowIE {
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;

  z-index: 1;

  > *:not(:nth-child(1)) {
    border-bottom: 1px solid white;
  }

  > .body {
    border: 2px solid transparent;
  }

  &.selected {
    > .body {
      border-color: #6305dc;
    }
  }
}

.target {
  visibility: hidden;

  &.connecting-target {
    visibility: visible;
    width: 100%;
    height: 100%;
    background-color: lightgray;
    z-index: 10;
    border: 0;
    border-radius: 0;
    opacity: 0.6;
    left: 0;
  }
}
