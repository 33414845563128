@font-face {
  font-family: "Lincoln-ProximaNova-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "Lincoln-ProximaNova-BoldIt";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-BoldIt.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-BoldIt.woff")
    format("woff");
}
@font-face {
  font-family: "Lincoln-ProximaNova-Reg";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-Reg.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-Reg.woff")
    format("woff");
}
@font-face {
  font-family: "Lincoln-ProximaNova-RegIt";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-RegIt.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/Lincoln-ProximaNova-RegIt.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Black.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-BlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-BlackItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Light.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Roman";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Roman.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Roman.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-Semibold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Semibold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerB-SemiboldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-SemiboldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerB-SemiboldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Black";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Black.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Black.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-BlackItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-BlackItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-BlackItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Light";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Light.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Light.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-LightItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-LightItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-LightItalic.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Roman";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Roman.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Roman.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-Semibold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Semibold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-Semibold.woff")
    format("woff");
}
@font-face {
  font-family: "LincolnMillerBCond-SemiboldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-SemiboldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Lincoln/LincolnMillerBCond-SemiboldItalic.woff")
    format("woff");
}
