.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.previewContainer {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
