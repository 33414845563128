$active-color: #40a9ff;

$inactive-opacity: 0.25;
$inactive-bg-color: #f5f5f5;
$inactive-border-color: rgba(0, 0, 0, $inactive-opacity);

.activePreviewBtn {
  color: $active-color;
  border-color: $active-color;
}

.inactivePreviewBtn {
  background-color: $inactive-bg-color;
  border-color: $inactive-border-color;
}

.inactivePreivewIcon {
  opacity: $inactive-opacity;
}
