$border-color: #1890ff;

.card-label {
  position: absolute;
  border: 1px solid $border-color;
  pointer-events: none; // To allow user event pass through the div
  box-sizing: content-box; // for the content to stay within the box

  > .card-label-content {
    position: relative;
    width: 100%;
    height: 100%;

    > .card-label-title {
      position: absolute;
      width: auto;
      height: auto;
      transform: translateY(-100%);
      background: $border-color;
      color: white;
      font-size: 0.8rem;
      font-weight: bold;
      padding: 0 10px;
    }
  }
}
