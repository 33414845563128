.authentication {
  display: flex;
}

.username {
  width: 50%;
  padding-right: 1em;
}

.password {
  width: 50%;
}

.validCreds {
  color: #33cc33;
}

.invalidCreds {
  color: #f44336;
}

.generalError {
  color: #f44336;
  margin-bottom: 1em;
}
