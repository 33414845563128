.Cell {
  display: flex;
  flex-direction: column;
  gap: 0.2em;

  &.Error {
    .Input {
      > span,
      textarea {
        border: 1px solid red;
        border-radius: 2px;
      }
    }
  }

  > * {
    flex: 1;
  }

  width: 100%;
  height: 100%;

  .Input {
    display: flex;
    align-items: center;

    > .PaymentEngineButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  > .Title {
    font-weight: 600;

    display: flex;
    flex-direction: row;
    gap: 0.7em;

    > .TitleTextWrapper {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .Modified {
    color: #1990ff;
    > input {
      color: inherit;
    }

    .RevertIcon {
      color: black;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
        color: #1990ff;
      }
    }
  }

  .Disabled {
    border-color: transparent;
  }

  .Content {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.3em;

    > * {
      flex: 1;
    }

    .ScoreContainer {
      display: flex;
      flex-direction: row;
    }

    .WarningMessage,
    .SavingMessage {
      flex: initial;
      min-height: 2em;
    }

    .WarningMessage {
      font-size: 0.7rem;
    }

    .SavingMessage {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5em;

      .SpinnerContainer {
        display: flex;

        .LoadingSpinner {
          font-size: 0.7rem;
        }
      }

      > .Message {
        font-size: 0.8rem;

        .SavedCheck {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5em;
        }

        .Error {
          color: red;
        }
      }
    }
  }
}

.DatePicker {
  width: 100%;
}
