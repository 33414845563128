.adInformations {
  list-style-type: none;
  padding: 0;

  ul li {
    list-style-type: none;
  }

  ul li > li::before {
    content: "\2022";
    color: black;
    display: inline-block;
    width: 0.7em;
    margin-left: -0.8em;
  }
}
