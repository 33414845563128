.OfferFieldList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > .Collapse {
    width: 100%;
    height: auto;

    background: white;

    border: 0;

    > .CollapsePanel {
      > div {
        background: white;
      }
    }

    // .ant-collapse-item
    > div {
      border: 0;
      // .ant-collapse-content
      > div:nth-child(2) {
        border: 0;

        > div {
          // .ant-collapse-content-box
          padding-top: 0;
          padding-bottom: 0;
        }
        padding-left: 1.5em;
      }
    }
  }
}

.CollapsePanelHeader {
  font-weight: bold;
}
