.editParamsDrawer :global {
  .ant-drawer-header {
    background-color: #fff;
    color: #000;

    .form-drawer-header-text {
      color: #000 !important;
    }

    .ant-drawer-close {
      color: #000 !important;
    }
  }

  .form-drawer-button-ctn .drawer-submit-button {
    background-color: #1890ff !important;
  }
}
