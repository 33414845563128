.uploadButton {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.formItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
}
