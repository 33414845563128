.layout {
  height: calc(100vh - 48px); // header is 48px in height, footer is 54px

  position: relative;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }

  .panel {
    flex: initial;

    position: relative;
    width: 300px;
    height: 100%;
    background: white;

    border-right: 3px solid transparent;
    user-select: none;

    &.left {
      max-width: 300px;
      width: auto;
      height: 100%;

      left: 0;
      top: 0;
      bottom: 0;

      > .trigger {
        position: absolute;
        top: 0;
        right: -1em;
        bottom: 0;
        width: 2em;
        height: auto;
      }

      &.resizable {
        border-right: 3px solid skyblue;
        cursor: col-resize;
      }
    }

    &.right {
      width: 250px;
    }
  }

  .canvas_area {
    flex: 1;
  }
}
