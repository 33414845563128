.ManageLayersContainer {
  width: 100%;
  height: auto;

  .EmptyWrapper {
    width: 100%;
    height: 100%;
    color: white;
  }
}
