@font-face {
  font-family: "PorscheNext-Bold";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Bold.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Bold.woff")
    format("woff");
}
@font-face {
  font-family: "PorscheNext-BoldItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-BoldItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-BoldItalic.woff")
    format("woff");
}
@font-face {
  font-family: "PorscheNext-Italic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Italic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Italic.woff")
    format("woff");
}
@font-face {
  font-family: "PorscheNext-Regular";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Regular.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "PorscheNext-Thin";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Thin.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-Thin.woff")
    format("woff");
}
@font-face {
  font-family: "PorscheNext-ThinItalic";
  /*src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-ThinItalic.otf")
    format("opentype");*/
  src: url("https://alexia-assets-prd.constellationagency.com/fonts/Porsche/PorscheNext-ThinItalic.woff")
    format("woff");
}
