.buttonContainer {
  display: flex;
  gap: 3px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
  background: linear-gradient(
    to right,
    rgba(251, 250, 250, 0.7) 0%,
    rgba(251, 250, 250, 1) 15%
  );
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.selectedButtonContainer {
  background: linear-gradient(
    to right,
    rgba(225, 243, 254, 0.7) 0%,
    rgba(225, 243, 254, 1) 15%
  );
}
