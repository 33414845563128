.footer {
  display: flex;
  justify-content: space-between;
}

.nextBackButtonsContainer {
  display: flex;
  gap: 8px;
}

.divider {
  margin-top: 0;
  margin-bottom: 10px;
}

.radioGroup {
  margin-left: 12px;
}

.exportSettingsWrapper {
  width: 100%;
  display: flex;
  gap: 130px;
}

.exportSettingsColumn {
  width: 30%;
  min-width: 120px;
}

.spinWrapper {
  height: 100%;
}

.checkboxContainer {
  margin-left: 12px;
}
