.toolbar :global {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 4px;

  .ant-tag.ant-tag-checkable-checked {
    background-color: #1890ff;
  }
}

.divider {
  margin: 0;
}

.content {
  margin: 0 8px;
}

.placeholder {
  font-weight: bold;
  margin-left: 12px;
}

.selectAll {
  cursor: pointer;
}
