.connected {
  background-color: #b7eb8f;
  color: #52c41a;
}

.disconnected {
  margin-left: 1em;
}

.feedNameTitle {
  color: gray;
}
