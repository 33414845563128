.Collapse {
  // get collapse header and override global style
  & > div > div {
    background-color: #fafafa;
    padding-bottom: 2px !important;
  }
  // get content body of collapse
  & > div > div:nth-child(2) > div {
    padding: 0;
  }
}
