.dropdown {
  margin-bottom: 12px;
  background-color: #1890ff;
  color: #ffffff;
  border-radius: 15px;
  padding: 5px 16px;
}

.tooltip {
  width: fit-content;
}
