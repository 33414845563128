.host {
  position: relative;

  > div {
    margin-bottom: 0;
  }
}

.published {
  position: absolute;
  right: 0;
  bottom: 0;
}
