.logoSelectDrawer {
  :global {
    .toolbar-table {
      padding: 0;
    }
  }

  .thumbnailImg {
    background-color: #f5f5f5;
    cursor: pointer;
    object-fit: cover;
  }

  .nameColumn {
    display: flex;
  }
}
