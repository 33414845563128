.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  > * {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 55px;
    color: #b8b8b8;
  }

  .title {
    font-size: 20px;
    color: #b8b8b8;
  }

  .pages {
    flex: initial;
    width: 150px;

    padding-right: 15px;

    .page {
      width: 100%;
      height: 200px;

      margin-bottom: 10px;

      background-color: white;

      &.selected {
        border: 2px solid #1990ff;
      }
    }
  }

  .canvasContainer {
    flex: 2;
    height: 100%;
    overflow: hidden;

    > * {
      width: 80%;
      height: 600px;
      background-color: white;
      margin: 0 auto;
      margin-bottom: 50px;
    }

    > .iframe {
      width: 100%;
      height: 100%;
      border: 0;
      margin: 0;
    }

    > .error {
      background: inherit;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
