.commentListSpace {
  width: 100%;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b1b0b0;
  }
}

.titleDiv {
  width: 100%;
  cursor: move;
}

.draggableModalWrap {
  pointer-events: none;
}
