.brandsClickable {
  display: flex;
  column-gap: 8px;
  color: #1890ff;
  font-weight: 500;
  cursor: pointer;
}

.accountName {
  padding-left: 27px;
}

.logoImage {
  width: 22px;
  height: 22px;
}

.placeholderImage {
  color: grey;
  font-size: 20px;
}

// Antd override
.table :global {
  th.ant-table-cell.ant-table-selection-column {
    text-align: right;
    padding: 0 5px;
  }

  tbody td.ant-table-cell.ant-table-selection-column {
    position: relative;
    text-align: right;
  }

  .ant-table-row-level-1,
  .ant-table-row-level-1 td {
    background-color: #fafafa;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .ant-table-row-level-1:hover {
    background-color: #f5f5f5;
  }
}

.brandsTable {
  :global {
    // This is a hacky way to override the antd table's height until the virtualizedtableforantd4 package is updated and supports better the expandable rows
    .ant-table-body {
      min-height: 80vh;
    }
  }
}

.accountsTable {
  :global {
    // This is a hacky way to override the antd table's height until the virtualizedtableforantd4 package is updated and supports better the expandable rows
    .ant-table-body {
      max-height: 72vh!important;
    }
  }
}

.expandIcon {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
