.container {
  padding: 8px;

  .input {
    margin-bottom: 8px;
    display: block;
  }

  .button {
    width: 90px;
  }
}
