.title {
  margin: 2px 0 0;
  display: flex;
}

.title > span {
  color: #ff4d4f;
  margin-right: 4px;
}

.buttonsContainer {
  display: flex;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:first-child {
  margin-left: 0.5em;
}

.stickyCollapse {
  & :global {
    .ant-collapse-item:not(.ant-collapse-item-active) {
      position: sticky;
      bottom: -24px;
      background-color: #f5f5f5;
    }
  }
}
