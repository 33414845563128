.statusDescription {
  display: flex;
  justify-content: flex-end;
}
.downloadBtn {
  color: #1890ff;
  cursor: pointer;
}

.notificationIcon {
  :global {
    color: #1890ff;
  }
}
