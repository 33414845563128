.flexCenter {
  display: flex;
  align-items: center;

  .text {
    margin: 0 1em;
  }
  .clickable {
    color: #1890ff;
  }
}
