.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5em;

  padding: 1em;

  > * {
    width: 100%;
  }

  > .title {
    flex: initial;
    font-weight: bold;
  }

  > .content {
    flex: initial;

    .autocomplete {
      width: 100%;
    }
  }

  > .footer {
    flex: initial;
    margin-top: auto;
    margin-bottom: 8px;

    > * {
      width: 100%;
    }
  }
}
