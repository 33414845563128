.toolbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
}

.toolbarButtons {
  display: flex;
  align-items: center;
  gap: 1em;
}
